<template>
  <div class="outer-dialog">
    <div class="dialog">
      <!-- {{subDialog}} -->
      <div class="toolbar">
        <div class="title">
          <span v-if="subDialog.type === 'contact' || subDialog.type === 'contact_successful'">Contact Form</span>
          <span v-if="subDialog.type === 'sms' || subDialog.type === 'sms_successful'">Send SMS</span>
        </div>
        <button class="close d-btn" @mouseup="$emit('closeDialog')">
          <img :src="close" alt />
        </button>
      </div>
      <section class="body">
        <div class="inner-wrap">
          <!-- SMS -->
          <form v-if="subDialog.type === 'sms' || subDialog.type === 'sms_successful'" action class="sms"
            @submit="smsSubmit">
            <div class="success-text sms-success"
              :style="`display: ${subDialog.type === 'sms_successful' ? 'block' : 'none'}`">
              <p>Thank you!</p>
              <p>The message was sent successfully</p>
            </div>
            <div :style="`opacity: ${subDialog.type === 'sms_successful' ? 0 : 1}`">
              <div class="message-wrap">
                <label for="message">Message</label>
                <textarea dir="auto" id="message" name="message" :style="setHebrewStyle(subDialog.data)"
                  v-model="subDialog.data"></textarea>
              </div>
              <div class="input-row">
                <label for="cell">
                  <span class="star">*</span>My Cell Phone
                </label>
                <div style="display: flex; align-items: center">
                  <span style="position: absolute; margin-left: 10px">(+972-)</span>
                  <input class="my-cell" type="tel" name="cell" id='cellNumber' required
                    v-on:keyup="getFirstCellNumber" />
                </div>
              </div>
              <input class="to-mail" type='toMail' name='toMail' v-model="subDialog.sms">
              <button class="send-btn d-btn">Send</button>
            </div>
          </form>
          <!-- Contact -->
          <form v-if="subDialog.type === 'contact' || subDialog.type === 'contact_successful'" action class="contact"
            @submit="contactSubmit">
            <div class="success-text contact-success"
              :style="`display: ${subDialog.type === 'contact_successful' ? 'block' : 'none'}; `">
              <p>Thank you!</p>
              <p>The message was sent successfully</p>
            </div>
            <div :style="`opacity: ${subDialog.type === 'contact_successful' ? 0 : 1}`">
              <div class="input-row">
                <label for="name"> <span class="star">*</span>Full Name </label>
                <input type="text" name="name" id class="dir-auto"
                  :value="`${this.accessData ? this.accessData.full_name : ''}`" required />
              </div>
              <div class="input-row">
                <label for="cell">Company</label>
                <input type="text" name="cell" id :value="`${this.accessData ? this.accessData.company : ''}`" />
              </div>
              <div class="input-row">
                <label for="name"> <span class="star">*</span>Cell Phone </label>
                <input type="text" name="name" id :value="`${this.accessData ? this.accessData.phone : ''}`" required />
              </div>
              <div class="input-row">
                <label for="email"> <span class="star">*</span>Email </label>
                <input type="text" name="email" id :value="`${this.accessData ? this.accessData.email : ''}`" required />
              </div>
              <div class="message-wrap">
                <label for="message">Message</label>
                <textarea id="message" name="message" dir="auto" :style="setHebrewStyle(subDialog.data)"
                  v-model="subDialog.data" pattern="[a-z]{1, 15}"></textarea>
              </div>
              <button class="send-btn d-btn">Send</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.body {
  position: relative;
  background-color: #ffffff;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  .inner-wrap {
    max-width: 350px;
    padding: 30px 22px 18px;

    @media only screen and (max-width: 900px) and (min-width: 450px) {
      padding: 20px 20px 10px;
      height: 50vh;
      overflow-y: auto;
    }

    .success-text {
      width: 50%;
      position: absolute;
      text-align: center;
      font-size: 20px;
      right: 0%;
      left: 0%;
      margin: auto;
    }

    .sms-success {
      top: 22%;

      @media only screen and (max-width: 900px) {
        top: 19%;
      }
    }

    .contact-success {
      top: 30%;

      @media only screen and (max-width: 900px) {
        top: 25%;
      }
    }

    .input-row {
      width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;

      @media only screen and (max-width: 900px) {
        width: 100%;
        margin-bottom: 5px;
      }

      label {
        font-size: 18px;

        @media only screen and (max-width: 900px) {
          font-size: 13px;
        }

        .star {
          color: #ff0303;
        }
      }

      input {
        width: 229px;
        height: 43px;
        border: 1px solid #707070;
        font-size: 15px;
        padding-left: 10px;

        @media only screen and (max-width: 900px) {
          height: 25px;
          width: 176px;
        }

        &.my-cell {
          width: 150px;
          padding-left: 60px;

          @media only screen and (max-width: 900px) {
            width: 100px;
          }
        }
      }
    }

    .message-wrap {
      margin-bottom: 18px;

      @media only screen and (max-width: 900px) and (min-width: 450px) {
        margin-top: -5px;
        margin-bottom: 0;
      }

      label {
        font-size: 20px;
        line-height: 30px;

        @media only screen and (max-width: 900px) {
          font-size: 14px;
          line-height: 23px;
        }
      }

      textarea {
        width: calc(100% - 32px);
        height: 92px;
        padding: 7px 12px;
        border: 1px solid #707070;
        font-size: 18px;
        resize: none;

        @media only screen and (max-width: 900px) {
          height: 50px;
          width: calc(100% - 27px);
          font-size: 12px;
        }
      }
    }
  }
}

.outer-dialog {
  background-color: #04040460;
  height: 97vh;
  width: 100vw;
  z-index: 1;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 900px) {
    height: 90vh;
  }

  .dialog {
    min-width: 300px;
    margin: 62px;
    border-radius: 5px 5px 0 0;

    @media only screen and (max-width: 900px) {
      max-width: 79vw;
    }

    .toolbar {
      min-height: 43px;
      color: #ffffff;
      text-align: center;
      letter-spacing: 0px;
      background-color: #535353;
      position: relative;
      border-radius: 5px 5px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;

      @media only screen and (max-width: 900px) {
        font-size: 18px;
        max-height: 36px;
      }

      .close {
        width: 13px;
        height: 13px;
        position: absolute;
        right: 16px;
        top: 15px;

        img {
          @media only screen and (max-width: 900px) {
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
    }
  }

  .d-btn {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.send-btn {
  width: 100%;
  height: 45px;
  background-color: #054e78;
  color: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 28px;

  @media only screen and (max-width: 900px) {
    font-size: 18px;
    height: 35px;
  }
}

button {
  outline: none !important;
}

.to-mail {
  display: none;
}
</style>

<script>
/*eslint-disable */

export default {
  name: "subDialog",
  components: {},
  props: [
    "subDialog",
    "exhibitionId",
    "accessData"
  ],
  watch: {},
  data: function () {
    return {
      subDialog: this.subDialog,
      rtlLetters: [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
      ],
      loremHeb:
        "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק.",
    };
  },
  created: function () { },
  mounted: function () {
    console.log('SubDialog infomation is ', this.subDialog)
    console.log('subDialog exhibition id ', this.exhibitionId)
    console.log('accessData', this.accessData)
  },
  methods: {
    setHebrewStyle: function (text) {
      if (text == '' || text == undefined) return;
      if (this.rtlLetters.includes(text.charAt(0))) {
        return "text-align: right; direction: rtl"
      }

      return "text-align: left; direction: ltr"
    },
    onSubDialogStatistic: function (_type, _from, _description) {
      let type;
      let description = _description
      if (_from.includes('counter')) {
        type = `Send ${_type} exhibition - ${_from} + attendant popup`
      } else if (_from === 'booth') {
        type = `${_type.replace(/^\w/, c => c.toUpperCase())} booth`
      } else if (_from === 'booth_exhibition') {
        type = `Send ${_type.replace(/^\w/, c => c.toUpperCase())} exhibit`
        description = { name: this.subDialog.data }
        description = { ...description, ..._description }
        // description = `${this.subDialog.data}, ${JSON.stringify(_description)}`
      } else if (_from.includes('companyInfo')) {
        type = `Send ${_type.replace(/^\w/, c => c.toUpperCase())} booth - counter + attendant popup`
      } else if (_from === 'subCategory') {
        type = `Send ${_type.replace(/^\w/, c => c.toUpperCase())} Sub-product`
        // description = `${this.subDialog.data}, ${JSON.stringify(_description)}`
        description = { name: this.subDialog.data }
        description = { ...description, ..._description }
      } else if (_from === 'schedule') {
        type = `Conference schedule Send ${_type.replace(/^\w/, c => c.toUpperCase())}`
      }

      this.$emit("onSubDialogStatistic", { type: type, boothNum: this.subDialog.booth_id, description: description })
    },
    smsSubmit: function (e) {
      e.preventDefault();
      let booth_id = this.exhibitionId;
      if (this.subDialog.from) {
        if (this.subDialog.from.includes('booth') || this.subDialog.from.includes('subCategory')) {
          booth_id = this.subDialog.booth_id
        }
      }

      let phoneNumber = '972' + e.target[1].value
      let data = {
        phone: phoneNumber,
        message: `${e.target[0].value}, phone number: ${phoneNumber}`,
        booth_id: booth_id
      }

      let description = {
        phone: phoneNumber,
        message: e.target[0].value,
        booth_id: booth_id
      }

      if (this.subDialog.from) {
        this.onSubDialogStatistic("SMS", this.subDialog.from, description)
      }

      console.log('sms sending data', data);

      axios.post('https://admin.exponeto.com/api/send/sms', data)
        .then(response => {
          console.log('sms sucess', response);
          if (response.data) {
            this.subDialog.type = "sms_successful"
          }
        })
        .catch(error => {
          console.log('sms error', error)
        })
    },
    contactSubmit: function (e) {
      e.preventDefault();
      let data = {
        full_name: e.target[0].value,
        company: e.target[1].value,
        phone: e.target[2].value,
        email: e.target[3].value,
        message: e.target[4].value,
        booth_id: this.subDialog.from && this.subDialog.from.includes('booth') ? this.subDialog.booth_id : this.exhibitionId,
      }

      if (this.subDialog.from) {
        this.onSubDialogStatistic("contact form", this.subDialog.from, data)
      }

      console.log('email sending data', data)

      axios.post('https://admin.exponeto.com/api/send/email', data)
        .then(response => {
          console.log('email sucess', response)
          if (response.data) {
            this.subDialog.type = "contact_successful"
          }
        })
        .catch(error => {
          console.log('email error ', error)
        })
    },
    getFirstCellNumber: function (e) {
      e.preventDefault();
      if (e.target.value.charAt(0) === '5') {
        e.target.pattern = "[0-9]{1}[0-9]{8}"
      } else {
        e.target.pattern = "[0-9]{1}[0-9]{7}"
      }
    }
  },
  computed: {
    close() {
      return require("../assets/booths/close.svg");
    },
  },
};

import axios from "axios"

</script>


