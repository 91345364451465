import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DetailBooth from '../views/DetailBooth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/exhibition/:id',
    name: 'exhibition',
    component: Home
  },
  {
    path: '/about/:user',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:'/booth/:boothType?',
    name: 'booth',
    component: DetailBooth
  }
]

const router = new VueRouter({
  routes,
})

export default router
