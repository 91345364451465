<template>
  <div class="outer-dialog">
    <div :class="`dialog ${dialog.data.data.type === 'brochure' || dialog.data.data.type === 'category'
        ? 'brochure'
        : 'image'
      } ${dialog.data.data.type === 'wallVideo' ? 'wallVideo' : ''}`">
      <!-- <div :class="'dialog ' + dialog.data.data.type === 'brochure' ? '969px;' : '810px'"> -->
      <div class="toolbar">
        <div class="side" v-if="dialog.data.data.type !== 'wall_video'">
          <button :title="`Call: ${phoneNumber}`" class="phone-a side-btn d-btn"
            @mouseup="openPhone(dialog.data.boothInfo, dialog.data.data.type)">
            <img :src="phone" alt />
          </button>

          <button title="Contact form" class="side-btn d-btn" @mouseup="openSubDialog('contact', dialog.data.data.type)">
            <img :src="contact" alt />
          </button>
          <button title="SMS" class="side-btn d-btn" @mouseup="openSubDialog('sms', dialog.data.data.type)">
            <img :src="sms" alt />
          </button>
          <button title="WhatsApp" class="side-btn d-btn" target="_blank"
            @mouseup="whatsappLink(dialog.data.boothInfo, dialog.data.data.type)">
            <img :src="whatsapp" alt />
          </button>
          <button title="Share" class="side-btn d-btn"
            @mouseup="openSocial(dialog.data.boothInfo, dialog.data.data.type)">
            <img :src="share" alt />
          </button>
        </div>

        <button v-if="backBtn" class="back" @mouseup="(dialog.data.data.type = 'category'), (backBtn = false)">
          <img :src="arrow" alt />
        </button>
        <div class="title">
          <span v-if="dialog.data.data.type === 'brochure'">Brochures</span>
          <span v-else-if="dialog.data.data.type === 'schedule'">Schedule</span>
          <span v-else-if="dialog.data.data.type === 'wall_video'">Video</span>
          <span v-else-if="dialog.data.data.type === 'category'">{{
            dialog.data.data.name
          }}</span>
          <span v-else-if="dialog.data.data.type === 'subCategory'">{{
            dialog.data.data.subTitle
          }}</span>
          <span v-else-if="dialog.data.data.type.includes('companyInfo')">{{
            dialog.data.boothInfo.name
          }}</span>
          <span v-else-if="dialog.data.data.type.includes('exhibitionInfo')">{{
            dialog.data.boothInfo.exhibition_name
          }}</span>
          <span v-else>{{ dialog.data.data.name }}</span>
        </div>
        <button class="close d-btn" @mouseup="
          $emit('closeDialog'), backBtn ? (dialog.data.data.type = 'category') : ''">
          <img :src="close" alt />
        </button>
      </div>
      <section class="body" :style="`min-height: ${dialog.data.data.type === 'video' ? 0 : '45vh'}`">
        <span v-if="dialog.data.data.type === 'schedule'">
          <div class="collection-wrap" style="display: block">
            <div class="text" :style="setHebrewStyle(dialog.data.data.description)">
              {{ dialog.data.data.description ? dialog.data.data.description : loremHeb }}
            </div>
          </div>
        </span>
        <span v-else-if="dialog.data.data.type === 'brochure'">
          <div class="collection-wrap">
            <span v-for="(value, index) in dialog.data.boothInfo.brochures" :key="index">
              <div class="collect-item-link"
                @click="$emit('brochuresLink', { booth_id: boothID, description: dialog.data.boothInfo.brochures[index].name })">
                <a :href="dialog.data.boothInfo.brochures[index].file" target="_blank">
                  <img style="height: 268px" :src="dialog.data.boothInfo.brochures[index].image" alt />
                  <p class="link-text" :style="setHebrewStyle(dialog.data.boothInfo.brochures[index].name)">
                    {{ dialog.data.boothInfo.brochures[index].name }}
                  </p>
                </a>
              </div>
            </span>
          </div>
        </span>

        <span v-else>
          <span v-if="dialog.data.data.type === 'category'">
            <div class="smart-wrap">
              <div class="top-wrap">
                <p class="main-txt-smart" dir="auto">{{ !!dialog.data.data.description ? dialog.data.data.description :
                  loremHeb }}</p>
                <img :src="!!dialog.data.data.image ? dialog.data.data.image : ''" alt class="main-img-smart" />
              </div>
              <div class="collection-wrap">
                <div v-if="!!dialog.data.data.products" class="collection-items">
                  <span v-for="(value, index) in dialog.data.data.products.length" :key="index">
                    <div class="collect-item-link">
                      <button @mouseup="linkTextClick('subData', dialog.data.data.products[index])">
                        <img :src="dialog.data.data.products[index].image" alt />
                        <p class="link-text" dir="auto">
                          {{ !!dialog.data.data.products[index].name ? dialog.data.data.products[index].name : '' }}
                        </p>
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </span>
          <span v-if="dialog.data.data.type === 'video' || dialog.data.data.type.includes('video')"
            style="display: block; height: 100%; background: black">
            <iframe id="ytplayer" type="text/html"
              :src="`${dialog.data.data.url.replace('watch?v=', 'embed/')}?autoplay=1&mute=0&rel=0`" frameborder="0"
              allowfullscreen controls="1"></iframe>
            <div v-if="dialog.data.data.type === 'video' && dialog.data.data.description" class="text"
              :style="setHebrewStyle(dialog.data.data.description)">
              {{ dialog.data.data.description ? dialog.data.data.description : '' }}
            </div>
          </span>

          <span v-if="dialog.data.data.type === 'ordinary' ||
            dialog.data.data.type === 'logo' ||
            dialog.data.data.type.includes('singleImage') ||
            dialog.data.data.type === 'product' ||
            dialog.data.data.type === 'wallImg'">
            <div class="display-img">
              <img class="main-img" :src="dialog.data.data.image" alt />
            </div>
            <div class="text" :style="setHebrewStyle(dialog.data.data.description)">
              {{ dialog.data.data.description ? dialog.data.data.description : "" }}
            </div>
          </span>
          <span class="companyInfo_section" v-else-if="dialog.data.data.type.includes('companyInfo')">
            <div class="display-img">
              <img
                :class="`main-img ${dialog.data.data.companyInfo ? 'companyInfo' : ''} ${dialog.data.data.frontDesk ? 'front-desk' : ''}`"
                :src="dialog.data.data.image" alt />
            </div>
            <div class="text" :style="setHebrewStyle(dialog.data.boothInfo.description)">
              {{ dialog.data.boothInfo.description ? dialog.data.boothInfo.description : "" }}
            </div>
          </span>
          <span class="companyInfo_section exhibitionInfo" v-else-if="dialog.data.data.type.includes('exhibitionInfo')">
            <div class="display-img">
              <img :id="dialog.data.data.image"
                :class="`main-img ${dialog.data.data.companyInfo ? 'companyInfo' : ''} ${dialog.data.data.frontDesk ? 'front-desk' : ''}`"
                :src="dialog.data.data.image" alt />
            </div>
            <div class="text" :style="setHebrewStyle(dialog.data.boothInfo.about_the_exhibition)">
              {{ dialog.data.boothInfo.about_the_exhibition ? dialog.data.boothInfo.about_the_exhibition : "" }}
            </div>
          </span>

          <span class="subCategory_section" v-else-if="dialog.data.data.type === 'subCategory'">
            <div class="sub_product_section" v-if="dialog.data.data.subType === 'sub_product'">
              <div class="display-img">
                <img
                  :class="`main-img ${dialog.data.data.companyInfo ? 'companyInfo' : ''} ${dialog.data.data.frontDesk ? 'front-desk' : ''}`"
                  :src="dialog.data.data.subSrc" alt />
              </div>
              <div class="text" :style="setHebrewStyle(dialog.data.data.subDescription)">
                {{ dialog.data.data.subDescription ? dialog.data.data.subDescription : '' }}
              </div>
            </div>
            <span v-else-if="dialog.data.data.subType === 'sub_video'"
              style="display: block; height: 100%; background: black">
              <iframe id="ytplayer" type="text/html"
                :src="`${dialog.data.data.subLink.replace('watch?v=', 'embed/')}?autoplay=1&mute=0&rel=0`" frameborder="0"
                allowfullscreen controls="1"></iframe>
              <div v-if="dialog.data.data.subType === 'sub_video' && dialog.data.data.subDescription" class="text"
                :style="setHebrewStyle(dialog.data.data.subDescription)">
                {{ dialog.data.data.subDescription ? dialog.data.data.subDescription : '' }}
              </div>
            </span>
          </span>
        </span>
      </section>
      <footer>
        <button v-if="dialog.data.data.companyInfo
          || dialog.data.data.exhibitionInfo
          || dialog.data.data.frontDesk
          || dialog.data.data.type === 'brochure'
          || dialog.data.data.type === 'schedule'
          || dialog.data.data.type === 'video'" class="more-btn d-btn blank_more"> </button>
        <button v-else-if="dialog.data.data.type === 'subCategory'" class="more-btn d-btn subCategroy_more"
          :style="`display:${dialog.data.data.subLink == '' ? 'none' : 'flex'}`"
          @mouseup="$emit('moreLink', dialog.data.data.subLink)">
          <img :src="more" alt />
          <span class="btn-text">More</span>
        </button>
        <button v-else class="more-btn d-btn" :style="`display:${dialog.data.data.url == '' ? 'none' : 'flex'}`"
          @mouseup="$emit('moreLink', dialog.data.data.url)">
          <img :src="more" alt />
          <span class="btn-text">More</span>
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
/*eslint-disable */

export default {
  name: "dialog",
  components: {},
  props: [
    "dialog",
    "about",
    "about",
    "jsonData"
  ],
  watch: {},
  data: function () {
    return {
      backBtn: false,
      isMobile: false,
      phoneNumber: '',
      rtlLetters: [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
      ],
      loremHeb:
        "משמשות להרמה, הורדה ושיכוך של תנועה כאשר ניתן לעצור את התנועה בכל נקודה ונקודה. הבוכנה משמשת במגוון מוצרים כגון: שולחנות עבודה, מיטות בתי חולים, עגלות מחשב, ציוד רפואי, זרועות עבודה, עמדות טיפול ברכב, בתעופה, ועוד...",
    };
  },

  created: function () {
    // console.log(this.dialog);
  },
  mounted: function () {
    this.isMobile = AFRAME.utils.device.isMobile();
    // Load the IFrame Player API code asynchronously.
    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Replace the 'ytplayer' element with an <iframe> and
    // YouTube player after the API code downloads.
    var player;
    function onYouTubePlayerAPIReady() {
      player = new YT.Player("ytplayer", {
        height: "360",
        width: "100%",
        videoId: "tlTKTTt47WE",
      });
    }
    if (this.about) {
      this.loremHeb = this.about
    }

    this.boothID = '';
    if (this.dialog.data.boothInfo) {
      if (this.dialog.data.data.type.includes('exhibitionInfo') || this.dialog.data.data.type == 'schedule' || this.dialog.data.data.type == 'wall_video') {
        this.phoneNumber = this.dialog.data.boothInfo.exhibition_contact.phone
      } else {
        this.phoneNumber = this.dialog.data.boothInfo.contact.phone;
      }
      this.boothID = this.dialog.data.boothInfo.booth_id;
    }
  },
  methods: {
    setHebrewStyle: function (text) {
      if (text == '' || text == undefined) return;
      if (this.rtlLetters.includes(text.charAt(0))) {
        return "text-align: right; direction: rtl"
      }

      return "text-align: left"
    },
    linkTextClick: function (type, data) {
      if (data.type == 'sub_external') {
        window.open(data.url, '_blank');
      } else {
        this.dialog.data.data.type = "subCategory";
        if (type === 'subData') {
          this.dialog.data.data.subType = data.type;
          this.dialog.data.data.subSrc = data.image;
          this.dialog.data.data.subDescription = data.description;
          this.dialog.data.data.subLink = data.url;
          this.dialog.data.data.subTitle = data.name;
          this.$emit('subProductLink', { booth_id: this.boothID, description: `${this.dialog.data.data.name}, ${data.name}` })
        }
        this.backBtn = true;
      }
      document.querySelector(".body").scrollTop = 0;
    },
    openPhone: function (boothInfo, type) {
      window.open(`tel:${this.phoneNumber}`)
      this.$emit('phoneLink', { data: boothInfo, booth_id: this.boothID, type: type, description: this.dialog.data.data.name })
    },
    openSubDialog: function (subDiaologType, type) {
      let info;
      let from = type;
      if (type === 'brochure') {
        info = this.dialog.data.boothInfo.name;
      } else if (type === 'subCategory') {
        info = this.dialog.data.data.subTitle;
      } else if (type.includes('companyInfo')) {
        info = this.dialog.data.boothInfo.name;
      } else if (type.includes('exhibitionInfo')) {
        info = this.dialog.data.boothInfo.exhibition_name;
        from = type.split("_")[1];
      } else if (type === 'schedule') {
        info = this.dialog.data.boothInfo.exhibition_name;
      } else {
        info = this.dialog.data.data.name;
        from = "booth_exhibition"
      }
      console.log('type', from)
      this.$emit('openSubDialog', { type: subDiaologType, info: info, from: from, booth_id: this.boothID })
    },

    whatsappLink: function (boothInfo, type) {
      this.$emit('whatsappLink', { data: boothInfo, booth_id: this.boothID, type: type, description: this.dialog.data.data.name })
    },
    openSocial: function (boothInfo, type) {
      this.$emit('openSocial', { data: boothInfo, booth_id: this.boothID, type: type, description: this.dialog.data.data.name })
    }
  },
  computed: {
    arrow() {
      return require("../assets/arrow.svg");
    },
    phone() {
      return require("../assets/phone-icon.svg");
    },
    contact() {
      return require("../assets/contact-form-icon.svg");
    },
    sms() {
      return require("../assets/SMS-icon.svg");
    },
    whatsapp() {
      return require("../assets/whatsapp-icon.svg");
    },
    share() {
      return require("../assets/share-icon.svg");
    },
    more() {
      return require("../assets/more-icon.svg");
    },
    close() {
      return require("../assets/booths/close.svg");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.smart-wrap {
  padding-top: 37px;

  .top-wrap {
    display: flex;
    justify-content: flex-end;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 50px;

    @media only screen and (max-width: 900px) {
      margin-bottom: 20px;
      flex-direction: column-reverse;
      align-items: center;
    }

    .main-txt-smart {
      margin: 0;
      white-space: pre-wrap;
    }

    .main-img-smart {
      width: 215px;
      margin-left: 25px;
      height: min-content;

      @media only screen and (max-width: 900px) {
        margin-bottom: 15px;
      }
    }
  }
}

.collection-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 12px;

  .collection-items {
    display: contents;
  }

  .collect-item-link {
    width: 210px;
    margin: 21px;
    display: flex;
    // flex-direction: column;
    font-size: 24px;

    a {
      color: inherit;
      text-decoration: none;
      /* no underline */
    }

    img {
      cursor: pointer !important;
      width: 215px;
      height: 160px;
    }

    .link-text {
      cursor: pointer !important;
      padding: 0;
      margin: 0;
      font-size: 22px;
      position: relative;
      bottom: 5px;
    }
  }
}

.outer-dialog {
  background-color: #04040460;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    max-width: 900px;
    min-width: 800px;
    margin: 62px;
    height: 83vh;

    @media only screen and (max-width: 900px) {
      min-width: 77vw !important;
      position: relative;
      bottom: 10px;
    }

    &.brochure {
      max-width: 969px;
    }

    &.image {
      max-width: 810px;

      @media only screen and (max-width: 900px) and (min-width: 480px) {
        max-width: 35% !important;
        min-width: 35% !important;
        // min-width: unset !important;
      }
    }

    &.wallVideo {
      height: 50vh;
      bottom: 50px;
      position: relative;
    }

    .collect-item-link {
      // width: 80%;
    }

    .toolbar {
      color: #ffffff;
      text-align: center;
      letter-spacing: 0px;
      background-color: #040404;
      position: relative;
      border-radius: 5px 5px 0px 0px;
      min-height: 43px;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 900px) {
        font-size: 16px;
        min-height: 37px;
      }

      .back {
        cursor: pointer;
        color: #ff5003;
        font-size: 20px;
        position: absolute;
        left: 16px;
        top: 10px;

        @media only screen and (max-width: 900px) {
          top: 6px;
        }
      }

      .side {
        position: absolute;
        left: -42px;
        top: 42px;

        @media only screen and (max-width: 900px) {
          top: 40px;
        }

        background-color: #040404;
        width: 42px;
        min-height: 190px;
        border-radius: 10px 0px 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 7px 0;
      }

      .title {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .close {
        width: 13px;
        height: 13px;
        position: absolute;
        right: 16px;
        top: 14px;

        @media only screen and (max-width: 900px) {
          top: 11px;
        }
      }
    }

    .body {
      position: relative;
      background-color: #ffffff;
      max-height: 78vh;
      overflow-y: auto;

      @media only screen and (max-width: 900px) {
        height: auto;
        max-height: 55vh;
        min-height: 45vh;
      }

      .display-img {
        display: flex;
        justify-content: center;
      }

      .main-img,
      #ytplayer {
        width: 100%;

        @media only screen and (max-width: 900px) {
          height: unset;
          width: 100%;
        }
      }

      #ytplayer {
        height: 608px;

        @media only screen and (max-width: 900px) {
          height: 200px;
        }
      }

      .front-desk {
        height: 30%;
      }

      .companyInfo {
        width: 50%;
        max-width: 140px;
        height: 30%;
      }

      .text {
        padding: 16px 25px 30px;
        white-space: pre-wrap;

        @media only screen and (max-width: 900px) {
          padding: 6px 17px;
          font-size: 14px;
        }
      }
    }

    footer {
      height: 41px;
      background-color: #262626;
      border-radius: 0px 0px 5px 5px;
      display: flex;
      align-items: center;
      padding-left: 10px;

      .more-btn {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;

        img {
          margin-right: 12px;
        }

        .btn-text {
          color: #ff5003;
          font-size: 18px;

          @media only screen and (max-width: 900px) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .d-btn {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  @media (min-width: 481px) {
    button.phone-a {
      position: relative;
      display: inline-block;
      opacity: 1 !important;
      z-index: 3000;
    }

    button.phone-a[title]:hover:after {
      content: attr(title);
      position: absolute;
      top: -43px;
      left: 50px;
      font-size: 24px;
      background: #b2d5de;
      color: #000;
      text-align: left;
      padding-left: 20px;
      border-radius: 10px;
      white-space: nowrap;
      padding-right: 20px;
    }
  }
}

.onscreen {
  transform: scale(0.5);

  .inner-wrap {
    width: 500px;
    display: flex !important;
    padding: 0 20px 17px 20px !important;

    .social-icon {
      width: 55px;
      height: 52px;
    }
  }
}

.on-screen-mobile {
  .social {

    // min-width: 211px;
    @media only screen and (max-width: 900px) {
      max-width: unset;
      // max-width: 74vw;
    }

    .inner-wrap {
      width: 363px;
      padding-right: 35px;
      padding-left: 35px;
      padding-bottom: 25px;

      .social-icon {
        width: 28px;
      }
    }
  }
}

.social {
  background-color: white;
  border: 1px solid #707070;
  border-radius: 5px;
  position: relative;
  bottom: 55px;

  @media only screen and (max-width: 900px) {
    max-width: 74vw;
  }

  .toolbar {
    text-align: center;

    font-size: 28px;
    font-weight: 800;
    position: relative;

    .title {
      color: #707070;
    }

    .close {
      color: #707070;
      cursor: pointer;
      position: absolute;
      top: 0px !important;
      right: 5px;
      font-size: 25px;
    }
  }

  .inner-wrap {
    padding: 35px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.social-icon {
  cursor: pointer;
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa {
  height: 29px;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: white;
}

.fa-whatsapp {
  background: #25d366;
  color: white;
}
</style>
