<template>
  <a-entity class="name" position="0 0.1 0">
    <canvas :id="`canvas-text-${index}`" crossorigin="anonymous"></canvas>
    <a-image
      :position="`0 0.52 0.1`"
      scale="1.2 1 1.2"
      :material="`shader: flat; src:#canvas-text-${index}; alphaTest: 0.07`"
      geometry=" width: 1; height: 0.3"
      transparent="true"
      class="users"
    ></a-image>
  </a-entity>
</template>
<script>
/*eslint-disable */

export default {
  name: "scene",
  components: {},
  watch: {},
  data: function() {
    return {
      font: 140
      // txt: '这是产品名称'
    };
  },
  props: ["name", "index"],
  created: function() {},
  mounted: function() {
    let canvas = document.getElementById(`canvas-text-${this.index}`);
    let ctx = canvas.getContext("2d");
    canvas.width = 1080;

    ctx.textBaseline = "center";
    ctx.textAlign = "center";

    ctx.font = `${this.font}px Assistant`;
    ctx.shadowColor = "black";
    ctx.shadowBlur = 30;
    ctx.lineWidth = 20;
    ctx.strokeStyle = "black";
    ctx.strokeText(this.name, canvas.width / 2, 120);
    ctx.shadowBlur = 0;
    ctx.fillStyle = "white";
    ctx.fillText(this.name, canvas.width / 2, 120);
  },
  methods: {
    tapUser: function() {}
  },
  computed: {
    fontSize: function() {
      return 70;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
