<template>
  <div :class="`outer-dialog ${type}`">
    <div :class="`social `">
      <div class="toolbar">
        <div class="title">Share</div>
        <button class="close d-btn" @mouseup="$emit('closeDialog')">
          <img :src="closeGray" alt />
        </button>
      </div>
      <div class="inner-wrap">
        <ShareNetwork network="facebook" :url="getCurrentURL" title="facebook" :quote="getDiscription" :description="getDiscription">
          <img title="Share on Facebook" :src="facebook" class="social-icon fa fa-facebook" @click="shareNetwork('facebook')" alt />
        </ShareNetwork>
        <ShareNetwork network="twitter" :url="getCurrentURL" title="twitter" :description="getDiscription">
          <img title="Share on Twitter" :src="twitter" class="social-icon fa fa-twitter" @click="shareNetwork('twitter')" alt />
        </ShareNetwork>
        <ShareNetwork network="linkedin" :url="getCurrentURL" title="linkedin" :description="getDiscription">
          <img title="Share on Linkedin" :src="linkedin" class="social-icon fa fa-linkedin" @click="shareNetwork('linkedin')" alt />
        </ShareNetwork>
        <ShareNetwork network="pinterest" :url="getCurrentURL" title="pintrest" :description="getDiscription">
          <img title="Share on Pinterest" :src="pinterest" class="social-icon fa fa-pinterest" @click="shareNetwork('pinterest')" alt />
        </ShareNetwork>
        <ShareNetwork network="whatsapp" :url="getCurrentURL" title="whatsapp" :description="getDiscription">
          <img title="Share on WhatsApp" :src="whatsapp" class="social-icon fa fa-whatsapp" @click="shareNetwork('whatsapp')" alt />
        </ShareNetwork>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
// facebook
// twitter
// instagram
// linkedin
// whatsapp
// pintrest
export default {
  name: "social",
  components: {},
  props: ["dialog", "type", "boothDataForSocial"],
  watch: {},
  data: function () {
    return {
      rtlLetters: [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
      ],
    };
  },
  created: function () { 
    this.jsonData = this.boothDataForSocial.data;
  },
  mounted: function () {
  },
  methods: {
    shareNetwork: function(_type) {
      let description = this.boothDataForSocial.description ? `${this.boothDataForSocial.description}, ${_type}` : `${_type}`
      this.$emit('linkSocial', {data: this.jsonData, booth_id: this.boothDataForSocial.booth_id, type: this.boothDataForSocial.type, description: description})
    }
  },
  computed: {
    facebook() {
      return require("../assets/facebook.svg");
    },
    twitter() {
      return require("../assets/twitter.svg");
    },
    linkedin() {
      return require("../assets/linkedin.svg");
    },
    pinterest() {
      return require("../assets/pinterest.svg");
    },
    whatsapp() {
      return require("../assets/whatsapp.svg");
    },
    closeGray() {
      return require("../assets/close-gray.svg");
    },
    getCurrentURL() {
      let currentURL = this.$route.fullPath;
      return 'https://play.detherverse.com/#/exhibition/1';
    },
    getDiscription() {
      let name = this.jsonData.name ? this.jsonData.name : this.jsonData.exhibition_name;
      let booth = this.jsonData.name ? 'booth' : 'exhibition';
      return "Meet us in " + name + " " + booth;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.onscreen {
  transform: scale(0.5);
  .inner-wrap {
    width: 500px;
    display: flex !important;
    padding: 0 20px 17px 20px !important;
    .social-icon {
      width: 55px;
      height: 52px;
    }
  }
}
.on-screen-mobile {
  .social {
    @media only screen and (max-width: 900px) {
      max-width: unset;
    }
    .inner-wrap {
      width: 363px;
      padding-right: 35px;
      padding-left: 35px;
      padding-bottom: 25px;
      .social-icon {
        width: 28px;
      }
    }
  }
}
.social {
  background-color: white;
  border: 1px solid #707070;
  border-radius: 5px;
  position: relative;
  bottom: 55px;
  @media only screen and (max-width: 900px) {
    max-width: 74vw;
  }
  .toolbar {
    text-align: center;

    font-size: 28px;
    font-weight: 800;
    position: relative;
    .title {
      color: #707070;
    }
    .close {
      color: #707070;
      cursor: pointer;
      position: absolute;
      top: -5px;
      right: 2px;
      font-size: 25px;
    }
  }
  .inner-wrap {
    padding: 35px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.social-icon {
  cursor: pointer;
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}
.fa {
  height: 29px;
}
.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: white;
}
.fa-whatsapp {
  background: #25d366;
  color: white;
}
</style>
