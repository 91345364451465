import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);
// import socketio from 'socket.io';
// import VueSocketIO from 'vue-socket.io';
// import io from 'socket.io-client';

Vue.config.ignoredElements = [
  'a-scene',
  'a-asset-item',
  'a-image',
  'a-curvedimage',
  'a-assets',
  'a-text',
  'a-plane',
  'a-rounded',
  'a-light',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-gltf-model'
]

// const options = { path: '/my-app/' }; //Options object to pass into SocketIO

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: io('http://localhost:8080', options), //options object is Optional
//   vuex: {
//     store,
//     actionPrefix: "SOCKET_",
//     mutationPrefix: "SOCKET_"
//   }
// })
// )
// require('aframe');


// require('aframe-extras');

// require('vue-socket.io') 
// require('https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.slim.js') 
// require('networked-aframe') 
// require('aframe-extras') 
// require('aframe-look-at-component')
// require('../compos-aframe/canvas.component.js')
// require('../compos-aframe/custom-controls.component.js')

// import "./compos-aframe/canvas.component.js";
// import "./compos-aframe/custom-controls.component.js";


// // require('networked-aframe');
// // const io = require('socket.io')();

// require('aframe-look-at-component');
// // require('aframe-physics-system');
// require('aframe-super-hot-loader');

// require('../cst-scripts');


// export const SocketInstance = socketio('http://localhost:8080');



Vue.config.productionTip = false

// Vue.component('a-scene', { /* ... */ })
// Vue.component('a-entity', { /* ... */ })
// Vue.component('a-image', { /* ... */ })


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


