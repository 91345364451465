

/*eslint-disable */


/*
* This is a free-look camera controller, designed for phone/tablet usage. It only supports touch gestures.
*
* One finger: pitch and yaw camera
* Two finger drag: dolly camera left/right (orthogonal to current view) or up/down
* Pinch: zoom

* A bounding box and bounded pitch angles prevent the user from getting totally lost
*/



AFRAME.registerComponent("model-opacity", {
  schema: { default: 1.0 },
  init: function() {
    this.el.addEventListener("model-loaded", this.update.bind(this));
  },
  update: function() {
    var mesh = this.el.getObject3D("mesh");
    var data = this.data;
    if (!mesh) {
      return;
    }
    mesh.traverse(function(node) {
      if (node.isMesh) {
        node.material.opacity = data;
        node.material.transparent = data < 1.0;
        node.material.needsUpdate = true;
      }
    });
  }
});