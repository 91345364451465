<template>
  <div class="loading" v-if="!instructPressed">
    <div class="loading-card">
      <div v-if="sceneLoading">
        <div v-if="exhibitionOpened === 'On'" class="loadingBar">
          <span v-if="validStatus">Loading exhibition in few seconds</span>
          <div v-if="validStatus" class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
        </div>
        <div v-else-if="exhibitionOpened === 'Off'" class="loadingBar">
          <span v-if="validStatus">Dear visitor, Exhibition is closed</span>
          <p v-if="validStatus">Please check for exhibition openning schedule</p>
        </div>
        <div v-else class="loadingBar">
          <span v-if="validStatus">Loading exhibition in few seconds</span>
          <div v-if="validStatus" class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
        </div>
      </div>

      <div class="instructions" v-if="!sceneLoading && exhibitionOpened === 'On'" @mouseup="$emit('onInstruction')">
        <img v-if="isMobile" class="card" :src="instructMobile" alt />
        <img v-else class="card" :src="instructDesktop" alt />
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
export default {
  name: "LoadingAnim",
  props: [
    "sceneLoading",
    "exhibitionOpened",
    "isMobile",
    "instructPressed",
    "validStatus",
  ],
  components: {},
  watch: {},
  data: function () {
    return {
      // instructPressed: false,
    };
  },
  created: function () { },
  mounted: function () {
    const pressedTimeout = (e) => {
      // if (!this.instructPressed) {
      //   setTimeout(
      //     function () {
      //       this.instructPressed = true;
      //     }.bind(this),
      //     2000
      //   );
      // }
    };
    window.addEventListener("keydown", pressedTimeout, { passive: true });
    window.addEventListener("wheel", pressedTimeout, { passive: true });
    window.addEventListener("mousedown", pressedTimeout, { passive: true });
  },
  methods: {
    displayCountdown: () => {
      console.log("sdhfjshdf");
      // setTimeout(
      //   function () {
      //     this.instructPressed = true;
      //   }.bind(this),
      //   2000
      // );
      // setTimeout(this.instructPressed = true, 1000)
    },
  },
  computed: {
    instructDesktop: function () {
      return require("../assets/instructions-desktop.png");
    },
    instructMobile: function () {
      return require("../assets/instructions-mobile.png");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  // background: #adc3e6;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.closed {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 500px;
  position: absolute;
  top: 20%;
  cursor: pointer;
  text-align: center;
  background: #fff;

  @media only screen and (max-width: 700px) {
    transform: scale(0.7);
    top: 0;
  }
}

.closed-title-1 {
  font-size: 3rem;
}

.closed-title-1 {
  font-size: 2rem;
}

.instructions {
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: center;
  top: 20%;
  cursor: pointer;

  @media only screen and (max-width: 700px) {
    transform: scale(0.7);
    top: 0;
  }
}

.loading-card {
  width: 100%;
  height: 100vh;
  position: relative;

  @media only screen and (max-width: 700px) {
    // transform: scale(0.7);
  }

  .loader {
    position: absolute;
    left: 48%;
    top: 110px;
    transform: scale(0.9);
  }
}

.animation-svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 200px;
}

.loadingBar {
  display: flex;
  width: 100%;
  height: 100vh;
  border-radius: 7px;
  justify-content: center;
  background: #18648f;
}

.loadingBar span {
  position: absolute;
  top: calc(50% - 50px);
  font-family: "Assistant", sans-serif !important;
  width: 75%;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.loadingBar p {
  position: absolute;
  top: calc(50% - 30px);
  font-family: "Assistant", sans-serif !important;
  width: 75%;
  font-size: 20px;
  text-align: center;
  color: white;
}

.spinner {
  width: 100px;
  height: 30px;
  position: absolute;
  text-align: center;
  font-size: 10px;
  top: calc(50% + 30px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner>div {
  background-color: white;
  height: 100%;
  width: 6px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(0.8);
  }
}

@keyframes stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(0.8);
    -webkit-transform: scaleY(0.8);
  }
}

.comment-label {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
  text-align: center;
}

@media (max-width: 576px) {
  .loadingBar {
    display: flex;
    border-radius: 5px;
    justify-content: center;
  }

  .loadingBar span {
    font-family: "Assistant", sans-serif !important;
    width: 75%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: white;
  }

  .loadingBar p {
    font-family: "Assistant", sans-serif !important;
    width: 75%;
    font-size: 14px;
    text-align: center;
    color: white;
  }
}
</style>
