
<template>
  <div class="scene">
    <LoadingAnim :sceneLoading="sceneLoading" :isMobile="isMobile" :instructPressed="instructPressed"
      :exhibitionOpened="exhibitionOpened" @onInstruction="onInstruction()" />

    <div class="dialog">
      <Dialog v-if="dialog.open" :jsonData="!!jsonData ? jsonData : null" :dialog="dialog"
        :about="!!jsonData ? jsonData.About : jsonData" @closeDialog="dialog.open = false"
        @moreLink="openMoreLink($event)" @openSubDialog="openSubDialog($event)" @openSocial="openSocial($event)"
        @whatsappLink="whatsappLink($event)" />
      <SubDialog v-if="subDialog.open" :subDialog="subDialog" :exhibitionId="boothId"
        @closeDialog="subDialog.open = false" />
      <Social v-if="social.open" :boothDataForSocial="boothDataForSocial" @closeDialog="social.open = false" />
      <p style="font-family: 'Assistant'; z-index: 99999; font-size: 2rem;">אתם מוזמנים לסייר בתערוכה בעזרת העכבר,
        להיכנס לביתנים,</p>
    </div>

    <a-scene background="color: #323848" physics="debug: false" vr-mode-ui="enabled: false"
      loading-screen="enabled: false" device-orientation-permission-ui="enabled: false" class="scene">
      <!-- stats -->
      <!-- id="stick2"
      screen-controls-->
      <a-assets timeout="1000" @loaded="assetsLoaded()">
        <a-asset-item id="booth" src="/dist/render-expo/glb_files/boothbaked.glb"></a-asset-item>
        <a-asset-item id="redGradient" src="/dist/render-expo/glb_files/redGradient.glb"></a-asset-item>
        <a-asset-item id="blueGradient" src="/dist/render-expo/glb_files/blueGradient.glb"></a-asset-item>
        <a-asset-item id="chair" src="/dist/render-expo/glb_files/stool.glb"></a-asset-item>
        <a-asset-item id="tableTop" src="/dist/render-expo/glb_files/table-low-poly-top.glb"></a-asset-item>
        <a-asset-item id="tableBottom" src="/dist/render-expo/glb_files/table-low-poly-bottom.glb"></a-asset-item>
        <img id="orange" src="/dist/render-expo/img/pin.png" crossorigin="anonymous" />
        <img id="blue" src="/dist/render-expo/img/pin-blue.png" crossorigin="anonymous" />

        <canvas id="my_canvas"></canvas>
        <canvas id="canvas" crossorigin="anonymous"></canvas>
        <canvas id="canvas-brochure" crossorigin="anonymous"></canvas>
        <canvas id="brochure-text" crossorigin="anonymous"></canvas>

        <img id="imgGround" src="/dist/render-expo/img/parket-floor.jpg" crossorigin="anonymous" />
        <img id="playBtn" src="/dist/render-expo/icons/play-btn.svg" crossorigin="anonymous" />

        <img id="parket" src="/dist/render-expo/assets/scene/parket-part.jpg" />
        <span v-for="booth in booths" :key="booth.id">
          <span v-for="item in booth.wallItems" :key="item.id" log="Hello, spanatios!">
            <canvas crossorigin="anonymous" :id="`canvas_wall_item_${item.id}`"></canvas>
            <img crossorigin="anonymous" width="4" height="3" :id="`img_wall_item_${item.id}`"
              :src="item.data ? item.data.image : ''" alt />
          </span>
        </span>
        <img id="booth-logo" src="/dist/render-expo/assets/booth/oricon.png" />
        <img id="booth-secretary" src="/dist/render-expo/assets/booth/secretary.png" />
        <img id="booth-brochure" src="/dist/render-expo/assets/booth/brochure.jpg" />
        <img id="backWall" src="/dist/render-expo/assets/booth/stand-alone-booth-bg.jpg" alt="" />
      </a-assets>

      <a-entity class="collider-walls">
        <a-box rotation="0 0 0" position="-5 0 -29.5" width="40" height="10" static-body color="yellow" visible="false"
          depth="4"></a-box>
        <a-box rotation="0 0 0" position="-5 0 -8.5" width="40" height="10" static-body color="yellow" visible="false"
          depth="4"></a-box>
        <a-box rotation="0 90 0" position="-15.5 0 -19" width="40" height="10" static-body color="yellow"
          visible="false" depth="4"></a-box>
        <a-box rotation="0 90 0" position="7 0 -19" width="40" height="10" static-body color="yellow" visible="false"
          depth="4"></a-box>
      </a-entity>

      <a-entity class="player" ref="player" id="rig" position="-11.5 0 -19.2" rotation="0 0 0" kinematic-body>
        <a-entity position="0 2.6 0"
          :custom-look-controls="`enabled:${!isLookControls}; reverseTouchDrag: true; type: singleBooth`"
          :extended-wasd-controls="`flyEnabled: false; turnEnabled: ${isLookControls}; maxLookAngle: 30;`"
          shape__main="shape: cylinder; " class="a-camera" id="camera" ref="camera" :camera="`fov:${fov}; near: 1; far: ${sceneLoading ? 0 : 250
          }; active: ${sceneLoading ? 'true' : 'false'}`" cursor="rayOrigin: mouse" raycaster="objects: .rayClick;">
        </a-entity>
        <!-- look-controls="enabled: false" -->
      </a-entity>

      <a-entity light="type: ambient; color: #BBB; intensity: 1.45"></a-entity>

      <a-plane class="floor" rotation="-90 0 0" position="0 -0.2 -20" visible="true" width="120" height="120"
        material="src:#imgGround; repeat: 40 40" static-body @loaded="loadingOrder.floor = true"></a-plane>

      <a-plane class="back-wall" rotation="0 -90 0" position="16 5 -19" visible="true" width="115" height="20"
        material="src:#backWall;" static-body @loaded="loadingOrder.floor = true"></a-plane>

      <!-- <a-sky 
        src="#backWall"
        radius = "30"
        position="0 0 -18" ></a-sky> -->

      <a-entity class="booths">
        <a-entity v-for="(booth, index) in booths" :key="booth.id">
          <a-entity :class="`booth-wrap`" v-show="sceneLoading">
            <BoothA class="booth" :id="booth.id" :rotation="booth.rotation" :position="booth.position"
              :wallTemplate="booth.itemAmount" :farFromCam="false" :items="booth.wallItems" :boothType="'aloneBooth'"
              :gradientColor="booth.gradientColor" :stripColor="booth.stripColor"
              :jsonData="!!jsonData ? jsonData : null" @itemLoad="itemLoad" @imageDown="imageDown"
              @imageClick="imageClick" @openSubDialog="openSubDialog($event)" @openSocial="openSocial($event)"
              @whatsappLink="whatsappLink($event)" @brochureClick="brochureClick($event)"
              @infoCompany="infoCompany($event)" v-if="booth.type === 'a' && booth.itemAmount === wallTemplate"
              :cullBooth="booth.cullBooth" :tableScale="tableScale" :chairScale="chairScale" :boothNumber="index"
              :sceneLoading="sceneLoading" />
          </a-entity>
        </a-entity>
      </a-entity>
    </a-scene>

    <OnScreen v-if="loadingOrder.booths" ref="onScreenRef" @helpInstruction="instructPressed = false" />
  </div>
</template>
<script>
/*eslint-disable */

export default {
  name: "detailBoothScene",
  components: {
    BoothA,
    PinName,
    Chat,
    // Controls,
    LoadingAnim,
    Dialog,
    SubDialog,
    Social,
    Colliders,
    OnScreen,
  },
  methods: {
    onInstruction: function () {
      this.instructPressed = true;

      this.isMobile = AFRAME.utils.device.isMobile();
      if (
        this.isMobile &&
        window.matchMedia("(orientation: landscape)").matches
      ) {
        this.$refs.onScreenRef.toggleFullScreen();
      }
    },
    openSubDialog: function (event) {
      let dialog = this.subDialog;
      dialog.open = true;
      dialog.type = event.type;
      dialog.data = event.info;
    },
    openSocial: function (event) {
      let dialog = this.social;
      dialog.open = true;
      this.boothDataForSocial = event;
    },
    whatsappLink: function (event) {
      if (event.data.contact) {
        window.open(
          `https://wa.me/${event.data.contact.whatsapp}?text=${event.data.name}`,
          "_blank"
        );
      } else {
        // window.open(`https://wa.me/972544722612?text=${this.jsonData.name}`, '_blank');
      }
    },
    openMoreLink: function (res) {
      console.log("openmorelink", res);
      if (res) window.open(res, "_blank");
    },
    assetsLoaded: function (event) {
      this.isAssetsLoaded = true;
    },
    imageDown: function () {
      this.isImageClick = true;
      setTimeout(() => {
        this.isImageClick = false;
      }, 500);
    },
    imageClick: function (res) {
      if (this.isImageClick) {
        if (res.data && res.data.type === "external") {
          this.openMoreLink(res.data.url);
        } else {
          let dialog = this.dialog;
          dialog.open = true;
          if (res.brochure) {
            dialog.brochure = true;
          } else {
            dialog.brochure = false;
          }
          dialog.data = res;
        }
        this.isImageClick = false;
      }
    },
    infoCompany: function (res) {
      let imageSRC;
      if (res.data) {
        if (res.type == "topLogo") {
          imageSRC = res.data.style.logo;
        } else if (res.type == "frontLogo") {
          imageSRC = res.data.style.frontdesk;
        } else if (res.type == "presentor") {
          imageSRC = res.data.style.logo;
        }
      } else {
        imageSRC = "/dist/render-expo/assets/booth/oricon.png";
      }
      this.imageClick({
        data: {
          type: "companyInfo",
          image: imageSRC,
          companyInfo: true,
        },
        boothInfo: res.data,
      });
    },
    brochureClick: function (res) {
      this.imageClick({
        data: {
          type: "brochure",
          brochure: true,
        },
        boothInfo: res.data,
      });
    },

    loaded: function (res) {
      console.log(res);
      this.objectLoading = true;
    },
    itemLoad: function (pos) {
      if (this.objectAmountForLoad > this.objectsLoaded + 2) {
        this.objectsLoaded++;
      } else {
        document.querySelector(".a-camera").components[
          "custom-look-controls"
        ].yawObject.rotation.y = -Math.PI * 0.5;

        console.log(localStorage.getItem("boothfirstloading"));

        this.loadingOrder.booths = true;
        
        
        let _width = document.body.clientWidth;
        if (_width > 480) {
          this.fov = 30;
        } else {
          this.fov = 70;
        }
        this.sceneLoading = false;
        console.log(_width);
        if (_width > 480) {
          document.querySelector(".a-camera").setAttribute("camera", "fov", 30);
          document
            .querySelector(".back-wall")
            .setAttribute("position", "16 5 -19");
          document.querySelector(".back-wall").setAttribute("height", "20");
        } else {
          document.querySelector(".a-camera").setAttribute("camera", "fov", 70);
          document
            .querySelector(".back-wall")
            .setAttribute("position", "16 9 -19");
          document.querySelector(".back-wall").setAttribute("height", "40");
        }

        let self = this;

        setTimeout(function () {
          if (localStorage.getItem("boothfirstloading") === null) {
            // window.location.reload();
            localStorage.setItem("boothfirstloading", true);
          } else {
            // try {
            //   self.$el.querySelector(".loadingBar").style.display = "none";
            // } catch(e) {

            // }
            // self.exhibitionOpened = "On";
          }

          try {
            self.$el.querySelector(".loadingBar").style.display = "none";
          } catch (e) {

          }
          self.exhibitionOpened = "On";
        }, 1000);
      }
    },
    playerPosition: function (pos) {
      let x = pos.x;
      let z = pos.z;
      this.playerPos = `x:${x} z:${z}`;
    },
    orientation: function (index) { },

    getBooth: async function (params) {
      console.log("booth id is", params);
      return new Promise((resolve, reject) => {
        axios
          .get(`https://admin.exponeto.com/api/booth/${params}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      });
    },
  },
  data: function () {
    return {
      isLookControls: false,
      fov: 30,
      chairScale: { x: 0.13, y: 0.15, z: 0.13 },
      tableScale: { x: 0.005, y: 0.0065, z: 0.005 },
      isAssetsLoaded: false,
      // chatBoxOpen: true,
      dialog: { open: false, brochure: false, data: {} },
      subDialog: { open: false, type: "", data: {} },
      social: { open: false, type: "", data: {} },
      objectsLoaded: 0,
      sceneLoading: true,
      instructPressed: false,
      loadingOrder: {
        floor: false,
        booths: false,
      },
      isMounted: false,
      isMobile: false,
      isImageClick: false,
      wallTemplate: 8,
      jsonData: null,
      boothId: null,
      exhibitionOpened: "Off",
    };
  },

  created: function () { },
  mounted: async function () {
    let scene = document.querySelector("a-scene");
    let cam = document.querySelector(".a-camera");

    this.cullingIterval = setInterval(
      function () {
        if (!this.sceneLoading) {
          let _width = document.body.clientWidth;
          console.log(_width);
          if (_width > 480) {
            cam.setAttribute("camera", "fov", 30);
            document
              .querySelector(".back-wall")
              .setAttribute("position", "16 5 -19");
            document.querySelector(".back-wall").setAttribute("height", "22");
          } else {
            cam.setAttribute("camera", "fov", 70);
            document
              .querySelector(".back-wall")
              .setAttribute("position", "16 9 -19");
            document.querySelector(".back-wall").setAttribute("height", "42");
          }
        }
      }.bind(this),
      1000
    );
    this.isMounted = true;
    this.isMobile = AFRAME.utils.device.isMobile();

    this.boothId = this.$route.params.boothType;
    // let params = {id: 14}
    this.jsonData = await this.getBooth(this.boothId);

    console.log("json data", this.jsonData);
  },

  computed: {
    booths: function () {
      let booths = [];
      if (this.jsonData) {
        let A8 = [
          {
            id: 0,
            cullBooth: false,
            data: this.jsonData.exhibits[0] ? this.jsonData.exhibits[0] : "",
            boothInfo: this.jsonData,
            size: "s1",
            pos: "0.95 3.15 -2.25",
            rot: "0 -24.75 0",
            imageScale: "3.66 2.69 1",
            imagePos: "0.97 3.21 -2.24",
            videoScale: "0.22 0.27 0.2",
            geo: { w: 4.2, h: 3.5 },
          },

          {
            id: 1,
            cullBooth: false,
            data: this.jsonData.exhibits[1] ? this.jsonData.exhibits[1] : "",
            boothInfo: this.jsonData,
            size: "s",
            pos: "3.6 3.807 0.2",
            rot: "0 -90 0",
            imageScale: "1.66 1.11 1.0",
            imagePos: "3.59 3.96 0.215",
            videoScale: "0.18 0.27 0.2",
            geo: { w: 1.9, h: 1.65 },
          },
          {
            id: 2,
            cullBooth: false,
            data: this.jsonData.exhibits[2] ? this.jsonData.exhibits[2] : "",
            boothInfo: this.jsonData,
            size: "s",
            pos: "3.6 3.807 2.1",
            rot: "0 -90 0",
            imageScale: "1.66 1.11 1.0",
            imagePos: "3.59 3.96 2.115",
            videoScale: "0.18 0.27 0.2",
            geo: { w: 1.9, h: 1.65 },
          },
          {
            id: 3,
            cullBooth: false,
            data: this.jsonData.exhibits[3] ? this.jsonData.exhibits[3] : "",
            boothInfo: this.jsonData,
            size: "s",
            pos: "3.6 3.807 4",
            rot: "0 -90 0",
            imageScale: "1.66 1.11 1.0",
            imagePos: "3.59 3.96 4.015",
            videoScale: "0.18 0.27 0.2",
            geo: { w: 1.9, h: 1.65 },
          },
          {
            id: 4,
            cullBooth: false,
            data: this.jsonData.exhibits[4] ? this.jsonData.exhibits[4] : "",
            boothInfo: this.jsonData,
            size: "s",
            pos: "3.6 2.185 0.2",
            rot: "0 -90 0",
            imageScale: "1.66 1.11 1.0",
            imagePos: "3.59 2.34 0.215",
            videoScale: "0.18 0.27 0.2",
            geo: { w: 1.9, h: 1.65 },
          },
          {
            id: 5,
            cullBooth: false,
            data: this.jsonData.exhibits[5] ? this.jsonData.exhibits[5] : "",
            boothInfo: this.jsonData,
            size: "s",
            pos: "3.6 2.185 2.1",
            rot: "0 -90 0",
            imageScale: "1.66 1.11 1.0",
            imagePos: "3.59 2.34 2.115",
            videoScale: "0.18 0.27 0.2",
            geo: { w: 1.9, h: 1.65 },
          },
          {
            id: 6,
            cullBooth: false,
            data: this.jsonData.exhibits[6] ? this.jsonData.exhibits[6] : "",
            boothInfo: this.jsonData,
            size: "s",
            pos: "3.6 2.185 4",
            rot: "0 -90 0",
            imageScale: "1.66 1.11 1.0",
            imagePos: "3.59 2.34 4.015",
            videoScale: "0.18 0.27 0.2",
            geo: { w: 1.9, h: 1.65 },
          },
          {
            id: 7,
            cullBooth: false,
            data: this.jsonData.exhibits[7] ? this.jsonData.exhibits[7] : "",
            boothInfo: this.jsonData,
            size: "x",
            pos: "3.6 3.07 7",
            rot: "0 -90 0",
            imageScale: "3.65 2.71 1.0",
            imagePos: "3.59 3.145 7.03",
            videoScale: "0.19 0.27 0.2",
            geo: { w: 4.2, h: 3.5 },
          },
        ];
        let A2 = [
          {
            id: 10,
            cullBooth: false,
            data: this.jsonData.exhibits[0] ? this.jsonData.exhibits[0] : "",
            boothInfo: this.jsonData,
            size: "m",
            pos: "1.05 3.18 -2.37",
            rot: "0 -24.75 0",
            imageScale: "3.65, 2.81 1.0",
            imagePos: "1.07 3.266 -2.36",
            videoScale: "0.22 0.27 0.2",
            geo: { w: 4.2, h: 3.7 },
          },
          {
            id: 9,
            cullBooth: false,
            data: this.jsonData.exhibits[1] ? this.jsonData.exhibits[1] : "",
            boothInfo: this.jsonData,
            size: "xl",
            pos: "3.6 3.1 4.1",
            rot: "0 -90 0",
            imageScale: "9.39 2.9 1.0",
            imagePos: "3.59 3.305 4.12",
            videoScale: "0.08 0.27 0.2",
            geo: { w: 9.63, h: 3.5 },
          },
        ];
        let A1 = [
          {
            id: 11,
            cullBooth: false,
            data: this.jsonData.exhibits[0] ? this.jsonData.exhibits[0] : "",
            boothInfo: this.jsonData,
            type: "singleImage",
            size: "xl",
            pos: "3.5 3.1 3.5",
            rot: "0 -90 0",
            geo: { w: 11, h: 3.5 },
          },
          {
            id: 12,
            cullBooth: false,
            data: this.jsonData.exhibits[0] ? this.jsonData.exhibits[0] : "",
            boothInfo: this.jsonData,
            type: "singleImage",
            size: "xl",
            pos: "1.11 3.1 -2.17",
            rot: "0 -24.75 0",
            geo: { w: 4.8, h: 3.5 },
          },
        ];

        let A4 = [
          {
            id: 13,
            cullBooth: false,
            data: this.jsonData.exhibits[0] ? this.jsonData.exhibits[0] : "",
            boothInfo: this.jsonData,
            size: "sl",
            pos: "1.01 3.15 -2.39",
            rot: "0 -24.75 0",
            imageScale: "3.48 2.42 1",
            imagePos: "1.03 3.2 -2.375",
            videoScale: "0.22 0.27 0.2",
            geo: { w: 4, h: 3.16 },
          },
          {
            id: 14,
            cullBooth: false,
            data: this.jsonData.exhibits[1] ? this.jsonData.exhibits[1] : "",
            boothInfo: this.jsonData,
            size: "sl",
            pos: "3.6 3.15 0.92",
            rot: "0 -90 0",
            imageScale: "2.88 2.405 1.0",
            imagePos: "3.59 3.2 0.95",
            videoScale: "0.22 0.27 0.2",
            geo: { w: 3.3, h: 3.15 },
          },
          {
            id: 15,
            cullBooth: false,
            data: this.jsonData.exhibits[2] ? this.jsonData.exhibits[2] : "",
            boothInfo: this.jsonData,
            size: "sl",
            pos: "3.6 3.15 4",
            rot: "0 -90 0",
            imageScale: "2.88 2.405 1.0",
            imagePos: "3.59 3.2 4.023",
            videoScale: "0.22 0.27 0.2",
            geo: { w: 3.3, h: 3.15 },
          },
          {
            id: 16,
            cullBooth: false,
            data: this.jsonData.exhibits[3] ? this.jsonData.exhibits[3] : "",
            boothInfo: this.jsonData,
            size: "sl",
            pos: "3.6 3.15 7.08",
            rot: "0 -90 0",
            imageScale: "2.88 2.405 1.0",
            imagePos: "3.59 3.2 7.1",
            videoScale: "0.22 0.27 0.2",
            geo: { w: 3.3, h: 3.15 },
          },
        ];

        let boothType;
        if (this.jsonData.style.type === "booth-a-8") {
          boothType = A8;
        } else if (this.jsonData.style.type === "booth-a-2") {
          boothType = A2;
        } else if (this.jsonData.style.type === "booth-a-4") {
          boothType = A4;
        } else if (this.jsonData.style.type === "booth-a-1") {
          boothType = A1;
        }
        booths.push({
          id: "aloneBooth",
          type: "a",
          gradientColor: Math.random() > 0.5 ? "red" : "blue",
          itemAmount: 8,
          farFromCam: false,
          boothsLoadedAmount: 0,
          wallItems: boothType,
          position: "6 0 -22",
          rotation: "0 0 0",
          stripColor: this.jsonData.style ? this.jsonData.style.color : "",
        });
        console.log(booths);
      }
      return booths;
    },

    objectAmountForLoad: function () {
      return document.querySelectorAll(".loadCount").length;
    },

    msgIcon: function () {
      return "https://cdn.glitch.com/3b2d1cee-c633-4b05-bbf2-07b88fcbad84%2Fchat-black-18dp.svg?v=14.885500516280";
    },
    picFrame() {
      return require("../assets/booths/pic-frame-sm.png");
    },
    pic() {
      return require("../assets/booths/tv.jpg");
    },
  },
};

// import "../compos-aframe/aframe102.js";
import "aframe";
import "three";
import "aframe-extras";
// import "aframe-canvas";
// import "aframe-svg-extruder";
import "networked-aframe";
import "aframe-rounded";
import "aframe-event-set-component";
import "aframe-look-at-component";
import "aframe-lerp-component";
import "../compos-aframe/physics.js";
import "../compos-aframe/auto-letter-direction.js";
import "../compos-aframe/canvas-pic-shadow.component.js";
import "../compos-aframe/custom-look-controls.component.js";
import "../compos-aframe/model-opacity.component.js";
import "../compos-aframe/booth-custom-component.js";
import "../compos-aframe/extended-wasd-controls.js";

import "aframe-aabb-collider-component";

import Dialog from "@/components/Dialog";
import SubDialog from "@/components/SubDialog";
import Social from "@/components/Social";
import LoadingAnim from "@/components/LoadingAnim";
import BoothA from "@/components/booths/booth_a/BoothA";
import Chat from "@/components/Chat";
import PinName from "@/components/PinName";
import Colliders from "@/components/Colliders";
import OnScreen from "@/components/OnScreenBoothDetails";

import axios from "axios";
</script>

