<template>
  <div class="home">
    <DetailBoothScene />
  </div>
</template>

<script>
// @ is an alias to /src
import DetailBoothScene from "@/components/DetailBoothScene.vue";

export default {
  name: "detailBooth",
  components: {
    DetailBoothScene
  }
};
</script>
