<template>
  <a-entity class="strip-text" position="-1 0.13 0.4">
    <!-- Phone Number-->
    <a-entity
      :text="`font: https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/heebo/Heebo-Bold.json; value: ${phoneNumber}; shader: msdf;`"
      scale="5.5 5.5 5.5"
      position="3.03 5.33 0.02"
      rotation="0 -24.840 0"
    >
      <a-image id="icon" :src="phoneIcon" scale="0.06 0.06 0.06" position="-0.54 0.03 0"></a-image>
      <a-entity
          geometry="primitive: plane; width: 0.6; height: 0.25;"
          material="color: white; opacity: 1; shader: sdf"
          rotation="0 0 0"
          position="-0.47 0.02 -0.01"
          class="rayClick"
          @mousedown="imageClicked()"
          @mouseup="openPhone(phoneNumber)"
        ></a-entity>
    </a-entity>

    <a-entity id="text-lg-wall" position="2.8 5.5 2.2">
      <!-- Contact Form -->
      <a-entity
        :text="`font: https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/heebo/Heebo-Bold.json; value: Contact Form; shader: msdf;`"
        scale="4.5 4.5 4.5"
        rotation="0 -90 0"
        position="0 -0.14 0.85"
      >
        <a-entity
          geometry="primitive: plane; width: 0.5; height: 0.25;"
          material="color: white; opacity: 1; shader: sdf"
          rotation="0 0 0"
          position="-0.43 0.05 -0.01"
          class="rayClick"
          @mousedown="imageClicked()"
          @mouseup="onSubdialog('contact')"
        ></a-entity>
        <a-image id="icon" :src="contactForm" scale="0.06 0.06 0.06" position="-0.535 0.03 0"></a-image>
      </a-entity>

      <!-- SMS -->
      <a-entity
        :text="`font: https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/heebo/Heebo-Bold.json; value: SMS; shader: msdf;`"
        scale="4.5 4.5 4.5"
        rotation="0 -90 0"
        position="0 -0.14 2.8"
      >
        <a-entity
          geometry="primitive: plane; width: 0.3; height: 0.25;"
          material="color: white; opacity: 1; shader: sdf"
          rotation="0 0 0"
          position="-0.5 0.05 -0.01"
          class="rayClick"
          @mousedown="imageClicked()"
          @mouseup="onSubdialog('sms')"
        ></a-entity>
        <a-image id="icon" :src="smsIcon" scale="0.06 0.06 0.06" position="-0.535 0.03 0"></a-image>
      </a-entity>

      <!-- Whatsapp -->
      <a-entity
        :text="`font: https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/heebo/Heebo-Bold.json; value: Whatsapp; shader: msdf;`"
        scale="4.5 4.5 4.5"
        rotation="0 -90 0"
        position="0 -0.14 3.85"
        
      >
        <a-entity
          geometry="primitive: plane; width: 0.3; height: 0.25;"
           material="color: white; opacity: 1; shader: sdf"
          rotation="0 0 0"
          position="-0.5 0.05 -0.01"
          class="rayClick"
          @mousedown="imageClicked()"
          @mouseup="onShareLink('whatsappLink')"
        ></a-entity>
        <a-image
          id="icon"
          :src="whatsappIcon"
          scale="0.0655 0.056 0.065"
          position="-0.535 0.03 0"
        ></a-image>
      </a-entity>

      <!-- Share -->
      <a-entity
        :text="`font: https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/heebo/Heebo-Bold.json; value: Share; shader: msdf;`"
        scale="4.5 4.5 4.5"
        rotation="0 -90 0"
        position="0 -0.14 5.4"
      >
        <a-entity
          geometry="primitive: plane; width: 0.3; height: 0.25;"
           material="color: white; opacity: 1; shader: sdf"
          rotation="0 0 0"
          position="-0.45 0.05 -0.01"
          class="rayClick"
          @mousedown="imageClicked()"
          @mouseup="onShareLink('openSocial')"
        ></a-entity>
        <a-image id="icon" :src="shareIcon" scale="0.0655 0.056 0.065" position="-0.535 0.03 0"></a-image>
      </a-entity>
    </a-entity>
  </a-entity>
</template>

<script>
/*eslint-disable */
import fontJson from "../assets/fonts/Assistant-Regular-msdf.json";
import fontArialJson from "../assets/fonts/arial-bold/FontsFree-Net-arial-bold-msdf.json";

export default {
  name: "StripText",
  data: function() {
    return {
      font: fontJson,
      fontArial: fontArialJson,
      lightIntensity: "intensity: 0.16",
      phoneNumber: '',
      item: {
        id: 'expo_1'
      },
      isImageClick: false,
    };
  },
  props: [
    "jsonInfo"
  ],
  methods: {
    imageClicked: function() {
      this.isImageClick = true;
      setTimeout(() => {
        this.isImageClick = false
      }, 200);
    },
    openPhone: function(phoneNumber) {
      if(this.isImageClick){
        this.$emit('phoneLink', this.jsonInfo)
        window.open(`tel:${phoneNumber}`)
        this.isImageClick = false
        console.log(this.fontArial)
      }
    },
    onSubdialog: function(type) {
      let booth_id = this.jsonInfo ? this.jsonInfo.booth_id : '';
      if(this.isImageClick) {
        this.$emit('subDialog', {type: type, info: this.jsonInfo.name, from: 'booth', booth_id: booth_id});
        this.isImageClick = false;
      }
    },
    onShareLink: function(type) {
      if(this.isImageClick) {
        this.$emit(type, this.jsonInfo);
        this.isImageClick = false;
      }
    }
  },
  components: {},
  computed: {
    phoneIcon() {
      return require("../assets/booths/phone-icon.png");
    },
    contactForm() {
      return require("../assets/booths/contact-form-icon.png");
    },
    whatsappIcon() {
      return require("../assets/booths/whatsapp-icon.png");
    },
    smsIcon() {
      return require("../assets/booths/sms-icon.png");
    },
    shareIcon() {
      return require("../assets/booths/share-icon.png");
    },
    gradient() {
      return require("../assets/booths/gradient-black-red.jpg");
    },
    fontImage0() {
      return require("../assets/fonts/Assistant-Regular.0.png");
    },
    fontImage1() {
      return require("../assets/fonts/Assistant-Regular.1.png");
    },
    fontArialImage() {
      return require("../assets/fonts/arial-bold/FontsFree-Net-arial-bold.png");
    },
    message() {
      return "I am interested about the " + this.item.id
    }
  },
  mounted: function() {
    if (this.jsonInfo) {
      this.phoneNumber = this.jsonInfo.contact.phone;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.outer-dialog-wrap {
  background-color: #04040460;
  height: 100vh;
  width: 100vw;
}
</style>
