<template>
  <div :class="`on-screen ${isMobile ? 'on-screen-mobile' : ''}`">
    <div class="top-strip">
      <!-- <div class="left-pane">
        <div class="logo">
          <img src="/dist/render-expo/img/test-logo.jpg" alt />
        </div>
      </div> -->
      <div class="right-pane">
        <a
          href="https://play.detherverse.com/"
          target="_blank"
          class="expo drk-btn"
        >
          <img src="/dist/render-expo/img/exponeto-logo.png" alt />
        </a>
        
        <button
          class="fullscreen drk-btn icon"
          @mouseup="toggleFullScreen()"
        >
          <div class="tooltip">
            <img
              :src="`/dist/render-expo/icons/${
                !fulllscreen ? 'fullscreen' : 'miniscreen'
              }.svg`"
              alt
            />
            <span v-if="!isMobile" class="tooltiptext"> {{!fulllscreen? "Fullscreen" : "Exit Fullscreen"}}</span>
          </div>
        </button>
        
        <button class="help drk-btn icon"  @mouseup="$emit('helpInstruction')">
          <div class="tooltip">
            <img src="/dist/render-expo/icons/help.svg" alt />
            <span v-if="!isMobile" class="tooltiptext">Instructions</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import { log } from "three";

export default {
  name: "onScreenBoothDetails",
  watch: {},
  data: function () {
    return {
      currBooth: 0,
      users: false,
      fulllscreen: false,
      visitorList: true,
      isMobile: false,
      toggleShare: false,
      searchOpen: false,
    };
  },
  props: [],
  created: function () {},
  mounted: function () {
    this.isMobile = AFRAME.utils.device.isMobile();
    if (this.isMobile) this.visitorList = false;
  },
  methods: {
    toggleFullScreen: function () {
      let elem = document.body;
      this.fulllscreen = !this.fulllscreen;
      // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        // this.isFullScreen = !this.isFullScreen;
      }
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button {
  cursor: pointer;
}

.on-screen {
  font-family: "Assistant", sans-serif !important;
  background-color: #18648f !important;
  .top-strip {
    .left-pane {
      position: absolute;
      top: 20px;
      left: 27px;
    }
    .right-pane {
      position: absolute;
      right: 27px;
      top: 20px;
      display: flex;
    }
  }
}

.logo {
  max-width: 193px;
  max-height: 96px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  padding: 2px;
  // background-image: url('/dist/render-expo/img/test-logo.jpg');
  // background-repeat: no-repeat;
  // // background-attachment: fixed;
  // background-position: center;
  // box-shadow: 0px 0px 10px #0000004d;
  border-radius: 5px;
  opacity: 1;
  img {
    width: 100%;
  }
}

.menu {
  position: relative;
  font-weight: 600;
  color: #2e2e2e;
  z-index: 2;
  .menu-top {
    padding-left: 19px;
    padding-right: 27px;
    background: #ffdd00;
    align-items: center;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    height: 45px;
    width: 350px;
    &.menu-top-mobile {
      height: 100%;
      width: 100%;
      max-height: 38px;
      max-width: 38px;
      padding: 7px 10px;
      display: flex;
      justify-content: center;
      .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          background: black;
          width: 24px;
          height: 3px;
          &:not(:last-of-type) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .menu-bottom {
    position: absolute;
    width: 350px;
    background-color: white;

    border-radius: 5px;
    margin-top: 5px;
    padding: 9px 0;
    max-height: 80vh;
    @media only screen and (max-width: 800px) {
      max-height: calc(50vh + 48px);
      // padding-bottom: 45px;
    }
    .inner-wrap {
      max-height: 80vh;
      @media only screen and (max-width: 800px) {
        max-height: 50vh;
      }
      overflow-y: auto;
    }
    .company {
      padding: 0 11px;
      margin-bottom: 4px;
      .category {
        color: #2e2e2e;
        font-size: 22px;
        @media only screen and (max-width: 800px) {
          font-size: 16px;
        }
        padding-top: 5px;
        padding-left: 15px;
        padding-bottom: 10px;
        // width: 100%;
        background-color: #e8e8e8;
        border-radius: 5px;
        cursor: pointer;
      }
      .sub {
        color: #2e2e2e;
        padding-top: 8px;
        padding-bottom: 13px;
        padding-left: 44px;
        font-size: 20px;
        @media only screen and (max-width: 800px) {
          font-size: 14px;
        }
        border-bottom: 1px solid #dbd8d8;
        &:last-of-type {
          margin-bottom: -4px;
          border-bottom: unset;
        }
      }
    }
  }
}

.drk-btn {
  background: #2e2e2e;
  border-radius: 5px;
  min-width: 45px;
  max-height: 45px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  padding:10px;
}
.expo {
  // padding-left: 5px !important;
  // padding-right: 5px !important;
  // min-width: 163px;
  // width: 100%;
  width: 153px;
  position: relative;
  img {
    position: absolute;
  }
}
.on-screen-mobile {
  .top-strip {
    .left-pane {
      top: 6px;
      left: 8px;
    }
    .right-pane {
      top: 6px;
      right: 8px;
    }
  }
  .drk-btn.icon {
    min-width: 38px;
    max-width: 38px;
    height: 38px;
  }
  .bar {
    left: -135px;
    // width: 100% !important;
    max-width: 220px;
    input {
      // width: 100%;
      max-width: 174px;
      height: 31px;
    }
    .grey-icon {
      top: 16px;
      right: 17px;
    }
  }
  .logo {
    top: 5px;
    left: 8px;
    max-width: 106px;
    max-height: 52px;
  }
  .menu {
    width: 38px;
    .menu-bottom {
      width: 227px;
      right: 0;
    }
  }
}
.tooltip {
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 35px;
  left: 33px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.expo, .fullscreen{
  @media only screen and (max-width: 480px) {
    display: none;
  }
}
</style>
