<template>
  <div class="chat">
    <span>
      <header class="chat-header">
        <span class="inner-wrap">
          <span class="name" v-if="!myUserDetails.chat">Chat</span>
          <span class="name" v-else>Chat with {{requestUer.full_name}}</span>
          <button class="exit" @mouseup="socket.emit('cancelChat')">x</button>
        </span>
      </header>

      <div
        :class="`chatbox ${myUserDetails.chat.requestTo || myUserDetails.chat.requestFrom ? 'no-border-top' : ''} ${myUserDetails.chat.inChat && !myUserDetails.chat.otherCancled ? 'no-borders' : ''}`"
        @resize="chatboxResize"
      >
        <div v-if="selectedUser && selectedUser.userInfo">
          <p class="company-role">{{requestUer.company}} - {{requestUer.position}}</p>
        </div> 
        <div class="request-to-chat" v-if="myUserDetails.chat.requestChat">
          <div class="confirm-btns">
            <button
              class="chat-btn start"
              @mouseup="socket.emit('confirmChat', myUserDetails.chat.requestTo)"
            >Start to chat</button><br/>
            <button class="chat-btn close" @mouseup="socket.emit('cancelChat')">Close</button>
          </div>
        </div>

        <div class="bubble-area">
          <span class="dm-req">
            <div class="req-sent" v-if="myUserDetails.chat.awaitConfirmChat ">
              <p class="text">Request Sent...</p>
              <button class="chat-btn cancel" @mouseup="socket.emit('cancelChat')">Cancel Request</button>
            </div>
            <div
              v-if="myUserDetails.chat.confirmChat  && !myUserDetails.chat.otherCancled"
              class="req-received"
            >
              <p class="text">
                {{requestUer.full_name}} wants to
                <br />chat with you.
              </p>
              <div class="confirm-btns">
                <button class="chat-btn ok" @mouseup="socket.emit('inChat')">OK</button>
                <button class="chat-btn no" @mouseup="socket.emit('cancelChat')">No</button>
              </div>
            </div>
          </span>
          <div class="connected" v-if="myUserDetails.chat.inChat">
            <div class="inner-wrap">
              <img :src="handShake" alt />
              <!-- <p class="message">Connected!</p> -->
            </div>
          </div>
          <!-- ${message.sender === myUser ? 'right' : 'left'} -->
          <div
            :class="`chat-bubble ${message.sender === myUserDetails.id ? 'right' : 'left'}`"
            v-for="message in myUserDetails.chat.messages"
            :key="message.id"
          >
            <textarea class="message" :value="message.message" dir="auto" readonly></textarea>
            <!-- <p
              v-if="myUserDetails.chat.messages.length > 0"
              class="message"
              :style="`white-space: pre-wrap; text-align: ${rtlLetters.includes(message.message.charAt(0)) ? 'right' : 'left'}`"
            >{{message.message}}</p>-->
            {{scrollDown()}}
          </div>
          <p class="left-chat" v-if="myUserDetails.chat.otherCancled">left the chat...</p>
        </div>
      </div>
      <div v-if="myUserDetails.chat.inChat && !myUserDetails.chat.otherCancled" class="msg-inpt">
        <div class="inner-wrap">
          <textarea
            v-model="msgInpt"
            @change.stop.prevent
            types="text"
            placeholder="Type Message"
            dir="auto"
          ></textarea>
          <!-- :style="`text-align: ${rtlLetters.includes(msgInpt.charAt(0)) ? 'right' : 'left'}`" -->
          <!-- :dir="rtlLetters.includes(msgInpt.charAt(0)) ? 'rtl' : 'ltr'" -->
          <button class="chat-btn" @mouseup="sendMsg">Send</button>
        </div>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  /*eslint-disable */
  name: "chat",
  components: {},
  watch: {
    myUserDetails: function(val) {
      if (this.myUserDetails.chat.otherCancled) {
        // setTimeout(() => this.socket.emit("cancelChat"), 4000);
      }
      // var chatBox = document.querySelector(".chatbox");
      // chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight + 400;
      // chatBox.addEventListener("resize", user => {
      //   console.log("trying to leave");
      // });
    }
  },
  props: ["socket", "myUserDetails", "selectedUser"],
  data: function() {
    return {
      msgInpt: "",
      msgLoopKey: 0,
      requestUer: '',
      rtlLetters: [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת"
      ]
    };
  },
  mounted: function() {
    if(this.myUserDetails.chat.awaitConfirmChat) {
      this.requestUer = this.myUserDetails ? this.myUserDetails.userInfo : '';
    } if (this.myUserDetails.chat.confirmChat) {
      this.requestUer = this.myUserDetails && this.myUserDetails.chat && this.myUserDetails.chat.requestFrom ? this.myUserDetails.chat.requestFrom.userInfo : '';
    } else {
      this.requestUer = this.selectedUser ? this.selectedUser.userInfo : this.myUserDetails.userInfo
    }
    console.log("comet-log: ", this.myUserDetails);

    // this.socket.on("userLeft", user => {
    //   console.log("trying to leave");

    //   // if (this.currConversation.confirmed === false) {
    //   this.socket.emit("cancelChat");
    //   this.myUserDetails.chat.messages = [];
    //   // this.$emit("closeChat");
    //   // }
    //   this.userLeftChat = true;
    //   console.log(this.userLeftChat);
    // });
  },
  methods: {
    scrollDown: function(str) {
      // if (this.rtlLetters.includes(str.charAt(0))) {
      //   return '<span>' + str + '</span>';
      // } else {
      this.$nextTick(() => {
        var chatBox = document.querySelector(".chatbox");
        chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;
      });
      // return str;
      // }
    },
    chatboxResize: function() {
      console.log("fufa[ups");
    },
    cancelChat: function() {
      // this.socket.emit("cancelChat");
      this.msgInpt = "";
    },
    sendMsg: function() {
      if (this.msgInpt !== "") {
        // this.socket.emit("messages", this.msgInpt);
        this.msgInpt = "";
      }
    }
  },
  computed: {
    handShake: function() {
      return require("../assets/hand-shake.gif");
    },
    messages: function() {
      return this.myUserDetails.chat.messages;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// Variables
$primary: #5a5eb9;
$secondary: rgba(240, 166, 202, 1);

$active: rgba(23, 190, 187, 0.8);
$busy: rgba(252, 100, 113, 0.8);
$away: rgba(255, 253, 130, 0.8);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
.chat {
  position: absolute;
  left: 5px;
  bottom: 5px;
  box-shadow: 0px 0px 10px #00000096;
  border-radius: 10px;
  .chat-header {
    margin: 0;
    padding: 0;
    background-color: #2e2e2e;
    color: #fff;
    width: 100%;
    height: 49px;
    left: 0;
    top: 0;
    line-height: 30px;
    border-radius: 10px 10px 0px 0px;
    padding: 0 20px;
    .inner-wrap {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font: 500 24px;
      .name {
        font-size: 24px;
        font-weight: 500;
      }
      .exit {
        border: none;
        background: none;
        color: white;
        font-size: 25px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.company-role {
  font-size: 18px;
  margin-bottom: -20px;
  margin-left: 20px;
}

.chatbox {
  //   background: rgba(255, 255, 255, 0.253);
  padding: 5px;
  position: relative;
  background: #f3eee8;
  width: 372px;
  height: 279px;
  border-radius: 0.2em;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  font-size: 24px;
  @media only screen and (max-width: 900px) {
    height: 190px;
  }
  // text-align: center;
  &.no-border-top {
    border-radius: 0 0 10px 10px;
  }
  &.no-borders {
    border-radius: 0;
  }
  .bubble-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      color: #fff;
      text-align: center;
    }
    .chat-bubble {
      background: #ffffff;
      // padding: 5px 15px;

      width: 270px;
      min-height: 40px;
      border: 1px solid #707070;
      border-radius: 5px;
      margin-bottom: 10px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -10.5px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #707070;
        z-index: 0;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: -8px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #c4e0eb;
        z-index: 0;
      }
      &.left {
        align-self: flex-start;
        margin-left: 10px;
        &::before {
          border-top: 10px solid #707070;
        }
        &::after {
          border-top: 10px solid #fff;
        }
        @media only screen and (max-width: 900px) {
          margin-bottom: 5px;
        }
      }
      &.right {
        align-self: flex-end;
        margin-right: 20px;
        background: #c4e0eb;
        &::before {
          left: unset !important;
          right: -10px;
        }
        &::after {
          left: unset !important;
          right: -7.2px;
        }
        @media only screen and (max-width: 900px) {
          margin-bottom: 5px;
        }
      }
      .name {
        float: left;
        text-align: left;
        margin-bottom: 1px;
        font-size: 8px;
        font-weight: 300;
        color: #fff;
        border: unset;
      }
      .message {
        height: 100%;
        width: 100%;
        // word-wrap: break-word;
        // text-align: left;
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        outline: none !important;
        resize: none;
        background: none;
        border: none;
        padding: 5px 10px;
        @media only screen and (max-width: 900px) {
          padding: 3px 10px;
        }
        // display: flex;
        // align-items: center;
      }
    }
  }
}

.no-messages {
  padding: 5px;
  background: #5865c3;
  color: white;
  font-size: 13px;
  border-radius: 20px;
}

.chat-btn {
  border: 1px solid;
  border-radius: 5px;
  width: 115px;
  height: 46px;
  padding: 3px;
  background: none;
  margin-top: 40px;
  cursor: pointer;
  font-size: 24px;
  @media only screen and (max-width: 900px) {
    margin-top: 15px;
  }
  &.start {
    border: 1px solid #047d08;
    background: #047d08;
    width: 230px;
    color: #ffffff;
    &:hover {
      background: #047d08;
    }
  }
  &.close {
    width: 274px;
    align-self: flex-end;
    width: 230px;
    margin-top: 20px;
    @media only screen and (max-width: 900px) {
      margin-top: 20px;
    }
  }
  &.ok {
    border: 1px solid #047d08;
    background: #047d08;
    color: #ffffff;
    margin-right: 12px;
    &:hover {
      background: #047d08;
    }
  }
  &.cancel {
    width: 274px;
    align-self: flex-end;
    margin-top: 100px;
    @media only screen and (max-width: 900px) {
      margin-top: 70px;
    }
  }
}

.connected {
  padding-top: 10px;
  margin-bottom: 15px;
  font-weight: 500;
  .inner-wrap {
  }
}

.dm-req {
  padding-top: 20px;
  color: black;
  font-weight: 500;
  .req-sent {
    text-align: center;
  }
  .req-received {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;

    .confirm-btns {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding-top: 10px;
      button {
        border-radius: 5px;
        padding: 10px 20px;
      }
    }
  }
}

.msg-inpt {
  height: 70px;
  background: #dcd7d1;
  border-radius: 0 0 5px 5px;
  position: relative;
  top: -4px;
  .inner-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 14px;
    textarea {
      resize: none;
      border: 1px solid #707070;
      border-radius: 5px;
      height: 40px;
      width: 283px;
      padding: 10px 20px;
      background: #ffffff;
      outline: none;
      font-weight: 300;
      margin-right: 8px;
    }
    .chat-btn {
      background: #047d08;
      height: 40px;
      width: 50px;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 15px;
      margin: 0;
      &:hover {
        opacity: 0.8;
      }
      // height: 8000px;
    }
  }
}

.request-to-chat {
  padding-top: 40px;
  text-align: center;
}

</style>
