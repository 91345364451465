<template>
  <div>
    <div class="register-body" :style="`background-image: url('${this.background_img}'); background-size: cover;`">
      <div class="main-container">
        <div class="logo-img">
          <img :src="logo" style="height: 100%;">
        </div>
        <div class="logo-text">DETHERVERSE</div>
        <div v-if="isMobile">
          <h2 class="textbig">Detherverse Exhibition Exclusive on Desktop!<br />Discover a world beyond imagination, but
            first,
            grab your desktop or laptop! The Detherverse Exhibition is here, and it's a desktop-exclusive
            experience.<br />To
            embark on this journey, ensure you're on a desktop device for the ultimate adventure.<br />Thank you for your
            understanding, and get ready for a thrilling exploration!</h2>
        </div>
        <div v-else>
          <h2 class="textbig">Welcome to the future of gaming! Immerse yourself in a mesmerizing virtual universe filled
            with creativity, exploration, and play-to-earn opportunities.</h2>
          <h3></h3>
          <div class="button-group">
            <input type="checkbox" class="checkbox" v-model="isConnected" />
            <button class="main-button" @click="connectWallet">{{ address == null ? 'CONNECT METAMASK' : address.slice(0,
              5) + "..." + address.slice(address.length - 4) }}</button>
          </div>
          <div class="button-group">
            <input type="checkbox" class="checkbox" v-model="isTweeted" />
            <ShareNetwork network="twitter" :url="getCurrentURL" title="@Detherverse Tech Exhibition is live now"
              :description="getDiscription" hashtags="metaverse,VirtualReality,decentralized,community,nft,token,NFTCommunity,PlayToEarn,Ethereum,cryptocurrency,coin">
              <button class="main-button" @click="shareInTwitter">LET'S EM KNOW</button>
            </ShareNetwork>
          </div>
          <div class="text14 red">{{ alertMessage }}</div>
          <div class="button-group">
            <button class="main-button play-button" :disabled="!isConnected || !isTweeted" @click="play">JOIN TO
              EXHIBITION</button>
          </div>
        </div>
        <div class="text14">© 2023 Detherverse. All rights reserved.</div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import { ethers, formatEther } from "ethers";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from 'firebase/firestore';

export default {
  name: "RegisterationScene",
  // components: { ethers, formatEther, initializeApp, getFirestore },
  props: ["background_img", "exhibition_status", "play", "isMobile"],
  data: function () {
    return {
      address: null,
      balance: null,
      isConnected: false,
      isTweeted: false,
      alertMessage: ""
    };
  },
  methods: {
    getSelfImage(e) {
      this.selfImage = e.target.files || e.dataTransfer.files;
      if (!this.selfImage.length) return;
    },
    connectWallet: async function () {
      if (this.isConnected) return;

      try {
        const { ethereum } = window;
        if (!ethereum) {
          this.alertMessage = "No metamask in your browser!";
          return;
        }

        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        });

        const provider = new ethers.BrowserProvider(window.ethereum);
        let balance = await provider.getBalance(accounts[0]);
        let bal = formatEther(balance);
        this.balance = bal;

        const firebaseConfig = {
          apiKey: "AIzaSyCLVBb92uT6DnAXttnmihrcY7T0nAPqVfI",
          authDomain: "play-detherverse.firebaseapp.com",
          projectId: "play-detherverse",
          storageBucket: "play-detherverse.appspot.com",
          messagingSenderId: "179392011717",
          appId: "1:179392011717:web:740e0fb8b00db086ba3fac",
          measurementId: "G-WGF0D13H2R"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const docRef = await addDoc(collection(db, "whitelist"), {
          address: accounts[0],
        });

        this.address = accounts[0];
        this.isConnected = true;
      } catch (error) {
        console.log(error);
      }
    },
    shareInTwitter: function () {
      this.isTweeted = true;
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.loaded = true;
    }, 2000);
  },
  computed: {
    logo() {
      return require("../assets/logo.png");
    },
    getCurrentURL() {
      let currentURL = this.$route.fullPath;
      return 'https://play.detherverse.com/#/exhibition/1';
    },
    getDiscription() {
      return "Meet us in Detherverse exhibition";
    }
  }
};

import axios from "axios";
import { isMobile } from "aframe";
</script>

<style scoped lang="scss">
.loading {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  background-color: #18648f;
  z-index: 100;
}

.register-body {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  font-family: "Assistant", sans-serif;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: top center;
  overflow-x: hidden;
}

.language_select {
  position: absolute;
  margin: -70px -30px 0 -30px;
  text-align: center;
  z-index: 10;
}

.logo-img {
  height: 128px;
  text-align: center;
}

.logo-text {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 800;
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 2px;
  text-shadow: 6px 6px rgba(0, 0, 0, 0.3);
}

.text-s {
  font-size: 14px;
  color: #ffffff;
}

.text {
  font-size: 16px;
  color: #000;
}

.textbig {
  font-family: arial;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1px;
}

.text14 {
  font-size: 14px;
  color: #fff;
  clear: both;
  padding-top: 24px;
  text-align: center;
}

.textbig18 {
  font-size: 18px;
  color: #000;
  text-align: center;
}

.textbig22 {
  font-size: 22px;
  color: #054175;
}

.title30 {
  font-size: 26px;
  color: #000;
}

.title20 {
  font-weight: 600;
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
}

.field {
  width: calc(97% - 10px);
  height: 36px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  padding: 0 10px;
}

.field-left {
  height: 25px;
  width: 340px;
  font-size: 16px;
}

.fieldshort {
  height: 36px;
  width: calc(95% - 10px);
  font-size: 14px;
  border: 1px solid #b9b9b9;
  padding: 0 10px;
}

.mobile_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fieldphone {
  height: 36px;
  width: calc(125% - 10px);
  border: 1px solid #b9b9b9;
  padding: 1px 10px;
}

.phonenum {
  height: 40px;
  border: 1px solid #b9b9b9;
  width: 160px;
}

.main-container {
  background-color: #425998;
  width: 480px;
  border-radius: 5px;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 20px;
  margin-top: 70px;
}

.button-group {
  display: flex;
  justify-content: center;
}

.main-button {
  padding: 0 22px;
  margin: 12px 12px;
  position: relative;
  height: 45px;
  min-width: 240px;
  line-height: 40px;
  border-radius: 4px;
  background: #4DB7FE;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  transition: all .2s ease-in-out;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
}

.main-button:hover {
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.1);
}

.main-button:disabled {
  background-color: #cccccc;
  color: #666666;
}

.play-button {
  margin-top: 30px;
  width: 309px;
  height: 60px;
}

.checkbox {
  width: 45px;
  height: 45px;
  font-size: 16px;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  accent-color: #4DB7FE;
  margin: 12px 12px;
  pointer-events: none;
}

.checkbox:checked {
  appearance: auto;
  border-radius: 4px;
  background-color: #4DB7FE;
}

.logo {
  text-align: center;
  margin-top: -10px;
}

.welcome {
  margin-left: auto;
  margin-right: auto;
}

.table1 {
  text-align: right;
  width: fit-content;
}

.table1-content {
  margin-top: 20px;
}

.if {
  vertical-align: top;
  height: 30px;
}

.mail {
  padding-top: 10px;
  vertical-align: top;
  width: 25%;
}

.mailfield {
  width: 60%;
}

.but1 {
  vertical-align: top;
  width: 15%;
}

.line {
  border-top: 1px solid #b9b9b9;
  height: 40px;
  margin-top: -10px;
}

.fields-area {
  margin-top: -40px;
  text-align: right;
  width: 594px;
}

.table2 {
  margin-top: -10px;
  width: 94%;
}

.hidden {
  display: none;
}

.h50 {
  height: 50px;
}

.approval {
  position: absolute;
  margin-right: 5px;
}

.red {
  color: #ff3333;
}

.sign-in-error {
  font-size: 14px;
  color: #ff3333;
  margin: -25px auto 25px auto;
}

@media only screen and (max-width: 900px) {
  .fieldphone {
    width: 135%;
  }
}

@media only screen and (max-width: 450px) {
  .textbig18 {
    font-size: 14px;
  }

  .field {
    width: calc(95% - 10px);
  }

  .text {
    font-size: 14px;
  }

  .title20 {
    font-size: 14px;
  }

  .main-container {
    width: 90%;
    padding: 30px 10px;
  }

  .h50 {
    max-width: 10px;
  }

  .fields-area {
    width: 100%;
  }

  .logo-pic {
    width: 300px;
  }

  .table2 {
    width: 100%;
  }

  .approval {
    width: 80%;
  }

  .phonenum {
    width: 80px;
  }

  .fieldphone {
    width: 100%;
  }

  .fieldshort {
    width: calc(90% - 10px);
  }

  .language_select {
    margin: -45px -10px 0 -10px;
  }

  .sign-in-error {
    font-size: 10px;
    margin: -25px auto 15px auto;
  }
}

.access-container {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  font-family: "Assistant", sans-serif;
  overflow-x: hidden;

  .text {
    font-size: 16px;
    color: #000;
  }

  .textbig {
    font-size: 18px;
    color: #000;
  }

  .textbig22 {
    font-size: 22px;
    color: #000;
  }

  .textbig24 {
    font-size: 24px;
    color: #000;
  }

  .title30 {
    font-size: 30px;
    color: #000;
  }

  .title20 {
    font-size: 20px;
    color: #000;
  }

  .field {
    height: 25px;
    width: 330px;
    font-size: 16px;
  }

  .fieldshort {
    height: 20px;
    width: 240px;
    font-size: 14px;
  }

  .fieldphone {
    height: 25px;
    width: 180px;
  }

  .phonenum {
    height: 30px;
    width: 156px;
  }

  .container {
    background-color: #fff;
    width: 560px;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    padding: 30px;
    margin-top: 17%;
  }

  @media only screen and (max-width: 900px) {
    .container {
      width: 90%;
      padding: 10px;
    }

    .textbig24 {
      font-size: 20px;
    }
  }
}
</style>
