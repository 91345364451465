// var registerComponent = AFRAME.registerComponent;
/*eslint-disable */

AFRAME.registerComponent("canvas-pic-shadow", {

  schema: {
    canvasSrc: { type: 'string', default: '' },
    imgSrc: { type: 'string', default: '' },
    text: { type: 'string', default: '' },
    size: { type: 'string', default: 'm' },
    type: { type: 'string' },
    boothType: {type: 'string'}
  },

  printAtWordWrap: function( context , text, x, y, lineHeight, fitWidth){ //https://stackoverflow.com/questions/5026961/html5-canvas-ctx-filltext-wont-do-line-breaks
    fitWidth = fitWidth || 0;
    if (fitWidth <= 0)
    {
        context.fillText( text, x, y );
        
        return;
    }
    var words = text.split(' ');
    var currentLine = 0;
    var idx = 1;
    while (words.length > 0 && idx <= words.length)
    {
        var str = words.slice(0,idx).join(' ');
        var w = context.measureText(str).width;
        if ( w > fitWidth )
        {
            if (idx==1)
            {
                idx=2;
            }
            context.fillText( words.slice(0,idx-1).join(' '), x, y + (lineHeight*currentLine) );
            currentLine++;
            words = words.splice(idx-1);
            idx = 1;
        }
        else
        {idx++;}
    }
    if  (idx > 0)
        context.fillText( words.join(' '), x, y + (lineHeight*currentLine) );
  },

  drawPic: async function () {
    let pic = document.getElementById(this.data.imgSrc);
    let canvas = document.getElementById(this.data.canvasSrc);
    let ctx = canvas.getContext("2d");
    let fontSize = 32.5 * 0.8;
    let fontWeight = 600;
    let textHeight = 665 * 0.7;
    let lineHeight = 33;
    let textStart = 50;
    let picHeight = 575 * 0.7;
    let picStart = 75 * 0.7;
    let shadowHeight = 750 * 0.7;
    canvas.width = 1012 * 0.7;
    canvas.height = 759 * 0.7;
    ctx.shadowColor = 'grey';

    ctx.shadowBlur = 22 * 0.7;

    if (this.data.size === 's') {
      fontSize = 62 * 0.8;
      fontWeight = 600;
      textHeight = 600 * 0.7;
      lineHeight = 55;
      picHeight = 515 * 0.7;
      shadowHeight = 652.5 * 0.7;
      picStart = 50 * 0.7;
      ctx.shadowBlur = 37.5 * 0.7;
    }
    
    if (this.data.size === 'x') {
      textHeight = 670 * 0.7;
      picHeight = 583 * 0.7;
    }
    // booth type is 4
    if (this.data.size === 'sl') {
      textHeight = 670 * 0.7;
      picHeight = 583 * 0.7;
      fontSize = 40 * 0.8;
      lineHeight = 30;
    }
    
    // booth type is 2
    if (this.data.size === 'xl') {
      fontSize = 85 * 0.8;
      fontWeight = 600;
      canvas.width = 5200 * 0.7;
      canvas.height = 2048 * 0.7;
      textHeight = 1800 * 0.7;
      lineHeight= 90;
      picHeight = 1705 * 0.7;
      shadowHeight = 1800 * 0.7;
      picStart = 50 * 0.7;
      ctx.shadowColor = 'grey';
      ctx.shadowBlur = 40 * 0.7;
    }

    //let img = await this.imageLoaded(pic)
    ctx.fillStyle = "white";
    ctx.fillRect(50, picStart, canvas.width - 90, picHeight);

    // if (this.data.type.includes('wall_item')) {
    //   ctx.drawImage(pic, 50, picStart, canvas.width - 90, picHeight);
    //   if (this.data.type.includes( 'video')) {
    //     ctx.drawImage(pic, 50, picStart, canvas.width - 90, picHeight)
    //     let play = document.getElementById('playBtn');
  
    //     let btnWidth = (canvas.width * 17) / 100;
  
    //     ctx.drawImage(play, (canvas.width / 2) - (btnWidth / 2), (canvas.height / 3) + 10, btnWidth, btnWidth);
    //   }
    // }

    // if (this.data.boothType === 'allBooth') {
    //   ctx.drawImage(pic, 50, picStart, canvas.width - 90, picHeight)
    //   if (this.data.type === 'video' || this.data.type === 'wallVideo') {
    //     ctx.drawImage(pic, 50, picStart, canvas.width - 90, picHeight)
    //     let play = document.getElementById('playBtn');
  
    //     let btnWidth = (canvas.width * 17) / 100;
  
    //     ctx.drawImage(play, (canvas.width / 2) - (btnWidth / 2), (canvas.height / 3) + 10, btnWidth, btnWidth);
    //   }
    // }

    if (this.data.type === "wallImg" || this.data.type === "wallVideo") {

    } else {
      ctx.fillStyle = "black";
      ctx.font = `${fontWeight} ${fontSize}px Assistant`;
      ctx.textBaseline = 'top';

      let rtlLetters = [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
      ]
      if (this.data.text === 'undefined') return;
      if (rtlLetters.includes(this.data.text.charAt(0))) {
        ctx.direction = "rtl" 
        ctx.textAlign = "right";
        this.printAtWordWrap(ctx, this.data.text, canvas.width - textStart/1.3, textHeight, lineHeight, canvas.width - 90)
        // ctx.fillText(this.data.text, canvas.width - 50, textHeight, canvas.width - 90);
      } else {
        // ctx.fillText(testText, 60, textHeight, canvas.width - 90);
        this.printAtWordWrap(ctx, this.data.text, textStart, textHeight, lineHeight, canvas.width-90)
      }
    }
  },

  init: function () {
    if (this.data.type.includes('singleImage')) {
      let textureSRC = document.getElementById(this.data.imgSrc);
      this.el.parentNode.parentNode.childNodes[0].addEventListener("model-loaded", () => {
        let model = this.el.parentNode.parentNode.childNodes[0].getObject3D('mesh');
        if (!model) { return; }
        model.traverse(function (node) {
          if (node.isMesh && node.name == "imageWrim") {
            node.material.depthWrite = !node.material.transparent;

            const texture = new THREE.TextureLoader().load(textureSRC.src);

            // immediately use the texture for material creation
            node.material = new THREE.MeshBasicMaterial({ map: texture });
            node.material.side = THREE.DoubleSide;
            node.material.map.flipY = false;
            // node.material.map.wrapS = node.material.map.wrapT = THREE.RepeatWrapping
            node.material.map.repeat.set(1, 2.5);
          }
        });
      });
    } else {
      this.drawPic();
    }
  },
});
