<template>
  <div>
    <div class="toolbar">
      <div class="title">Share</div>
      <button class="close d-btn" @mouseup="$emit('closeDialog')">
        <img :src="closeGray" alt />
      </button>
    </div>
    <div class="inner-wrap">
      <ShareNetwork network="facebook" :url="getCurrentURL" title="Detherverse Tech Exhibition" :quote="getDiscription"
        :description="getDiscription">
        <img title="Share on Facebook" :src="facebook" class="social-icon fa fa-facebook" alt />
      </ShareNetwork>
      <ShareNetwork network="twitter" :url="getCurrentURL" title="@Detherverse Tech Exhibition" :description="getDiscription">
        <img title="Share on Twitter" :src="twitter" class="social-icon fa fa-twitter" alt />
      </ShareNetwork>
      <ShareNetwork network="linkedin" :url="getCurrentURL" title="Detherverse Tech Exhibition" :description="getDiscription">
        <img title="Share on Linkedin" :src="linkedin" class="social-icon fa fa-linkedin" alt />
      </ShareNetwork>
      <ShareNetwork network="pinterest" :url="getCurrentURL" title="Detherverse Tech Exhibition" :description="getDiscription">
        <img title="Share on Pinterest" :src="pinterest" class="social-icon fa fa-pinterest" alt />
      </ShareNetwork>
      <ShareNetwork network="whatsapp" :url="getCurrentURL" title="Detherverse Tech Exhibition" :description="getDiscription">
        <img title="Share on WhatsApp" :src="whatsapp" class="social-icon fa fa-whatsapp" alt />
      </ShareNetwork>
      <!-- <a href="https://www.instagram.com/f__exhibition/" class="fa fa-instagram"></a> -->
    </div>
  </div>
</template>
<script>
/*eslint-disable */
// facebook
// twitter
// instagram
// linkedin
// whatsapp
// pintrest
export default {
  name: "social",
  components: {},
  props: ["dialog", "type", "exhibitionName"],
  watch: {},
  data: function () {
    return {
      rtlLetters: [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
      ],
    };
  },
  created: function () { },
  mounted: function () { },
  methods: {},
  computed: {
    facebook() {
      return require("../assets/facebook.svg");
    },
    twitter() {
      return require("../assets/twitter.svg");
    },
    linkedin() {
      return require("../assets/linkedin.svg");
    },
    pinterest() {
      return require("../assets/pinterest.svg");
    },
    whatsapp() {
      return require("../assets/whatsapp.svg");
    },
    closeGray() {
      return require("../assets/close-gray.svg");
    },
    getCurrentURL() {
      let currentURL = this.$route.fullPath;
      return 'https://play.detherverse.com/#/exhibition/1';
    },
    getDiscription() {
      return "Meet us in " + this.exhibitionName + " exhibition";
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.social {
  max-width: 316px;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 5px;
  position: relative;
  bottom: 55px;

  // @media only screen and (max-width: 900px) {
  //   max-width: 20vw;
  // }
  .toolbar {
    text-align: center;

    font-size: 28px;
    font-weight: 800;
    position: relative;

    .title {
      color: #707070;
    }

    .close {
      color: #707070;
      cursor: pointer;
      position: absolute;
      top: -5px;
      right: 2px;
      font-size: 25px;
    }
  }

  .inner-wrap {
    padding: 20px;
    padding-top: 0px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 800px) {
      padding: 0 5px;
      padding-top: 0px !important;
    }
  }
}

.social-icon {
  cursor: pointer;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  padding: 20px;
  width: 30px;

  @media only screen and (max-width: 800px) {
    padding: 10px;
    width: 27px;
  }
}

.fa {
  height: 29px;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: white;
}

.fa-whatsapp {
  background: #25d366;
  color: white;
}</style>
