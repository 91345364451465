// var registerComponent = AFRAME.registerComponent;
/*eslint-disable */

AFRAME.registerComponent("booth-strip-custom", {
    schema: { 
        gradientSrc: { type: 'string', default: 'black' },
        ceilingSrc: { type: 'string', default: '' },
        carpetSrc: { type: 'string', default: '' },
        panoramaSrc: { type: 'string', default: 'm' },
    },
    init: function () {
        this.el.addEventListener("model-loaded", this.update.bind(this));
    },
    update: function () {
        var mesh = this.el.getObject3D("mesh");
        let textureSRC = this.data;
        if (!mesh) {
            return;
        }
        mesh.traverse(function (node) {
            if (node.isMesh && node.name == "gradient_bottom") {
                node.material.depthWrite = !node.material.transparent;
                const texture = new THREE.TextureLoader().load(textureSRC.gradientSrc);
                node.material = new THREE.MeshBasicMaterial({ map: texture });
                node.material.side = THREE.DoubleSide;
                node.material.map.flipY = false;

                node.material.needsUpdate = true;
            } else if(node.isMesh && node.name == "gradient_top") {
                // node.material.color= new THREE.Color( "#323848");
                // node.material.needsUpdate = true;
            } else if(node.isMesh && node.name == "rect003") {
                node.material.depthWrite = !node.material.transparent;
                const texture = new THREE.TextureLoader().load(textureSRC.carpetSrc);
                node.material = new THREE.MeshBasicMaterial({ map: texture });
                node.material.side = THREE.DoubleSide;
                node.material.map.flipY = false;

                node.material.needsUpdate = true;
            } else if(node.isMesh && node.name == "Cielling") {
                node.material.depthWrite = !node.material.transparent;
                const texture = new THREE.TextureLoader().load(textureSRC.ceilingSrc);
                node.material = new THREE.MeshBasicMaterial({ map: texture });
                node.material.side = THREE.DoubleSide;
                node.material.map.flipY = false;

                node.material.needsUpdate = true;
            } else if(node.isMesh && node.name == "WindowwView") {
                node.material.depthWrite = !node.material.transparent;
                const texture = new THREE.TextureLoader().load(textureSRC.panoramaSrc);
                node.material = new THREE.MeshBasicMaterial({ map: texture });
                node.material.side = THREE.DoubleSide;
                node.material.map.flipY = false;

                node.material.needsUpdate = true;
            }
        });
    }
});
