

/*eslint-disable */

import { clamp, easeInOutCubic, easeInOutQuad, easeOutCubic, easeOutQuad, easeOutQuadDelay, isMobile } from "../utils"

/* global DeviceOrientationEvent  */





var THREE = AFRAME.THREE;
var utils = AFRAME.utils;
var bind = utils.bind;

// To avoid recalculation at every mouse movement tick
var PI_2 = Math.PI / 2;

/**
 * look-controls. Update entity pose, factoring mouse, touch, and WebVR API data.
 */
AFRAME.registerComponent('custom-look-controls', {
  dependencies: ['position', 'rotation'],

  schema: {
    enabled: { default: true },
    hmdEnabled: { default: true },
    pointerLockEnabled: { default: false },
    reverseMouseDrag: { default: false },
    reverseTouchDrag: { default: false },
    touchEnabled: { default: true },
    type: { defalut: "booth" },

    turnLeftKey: { type: 'string', default: "ArrowLeft" },
		turnRightKey: { type: 'string', default: "ArrowRight" },
    turnSpeed: { type: 'number', default: 30 }, // degrees/second
  },

  init: function () {
    this.deltaYaw = 0;
    this.previousHMDPosition = new THREE.Vector3();
    this.hmdQuaternion = new THREE.Quaternion();
    this.magicWindowAbsoluteEuler = new THREE.Euler();
    this.magicWindowDeltaEuler = new THREE.Euler();
    this.position = new THREE.Vector3();
    this.magicWindowObject = new THREE.Object3D();
    this.rotation = {};
    this.deltaRotation = {};
    this.savedPose = null;
    this.pointerLocked = false;
    this.setupMouseControls();
    this.bindMethods();

    this.setupMagicWindowControls();

    // To save / restore camera pose
    this.savedPose = {
      position: new THREE.Vector3(),
      rotation: new THREE.Euler()
    };

    // Call enter VR handler if the scene has entered VR before the event listeners attached.
    if (this.el.sceneEl.is('vr-mode')) { this.onEnterVR(); }

    // arrow left/right key
    this.keyPressedSet = new Set();
    this.data.turnSpeed = 30;
    this.rotatePercent = new THREE.Vector2(0, 0);
  },

  setupMagicWindowControls: function () {
    var magicWindowControls;

    // Only on mobile devices and only enabled if DeviceOrientation permission has been granted.
    // if (utils.device.isMobile()) {
    //   magicWindowControls = this.magicWindowControls = new THREE.DeviceOrientationControls(this.magicWindowObject);
    //   if (typeof DeviceOrientationEvent !== 'undefined' && DeviceOrientationEvent.requestPermission) {
    //     magicWindowControls.enabled = false;
    //     if (this.el.sceneEl.components['device-orientation-permission-ui'].permissionGranted) {
    //       magicWindowControls.enabled = true;
    //     } else {
    //       this.el.sceneEl.addEventListener('deviceorientationpermissiongranted', function () {
    //         magicWindowControls.enabled = true;
    //       });
    //     }
    //   }
    // }
  },

  update: function (oldData) {
    var data = this.data;

    // Disable grab cursor classes if no longer enabled.
    if (data.enabled !== oldData.enabled) {
      this.updateGrabCursor(data.enabled);
    }

    // Reset pitch and yaw if disabling HMD.
    if (oldData && !data.hmdEnabled && !oldData.hmdEnabled) {
      this.pitchObject.rotation.set(0, 0, 0);
      this.yawObject.rotation.set(0, 0, 0);
    }

    if (oldData && !data.pointerLockEnabled !== oldData.pointerLockEnabled) {
      this.removeEventListeners();
      this.addEventListeners();
      if (this.pointerLocked) { this.exitPointerLock(); }
    }
  },

  tick: function (t) {
    var data = this.data;
    if (!data.enabled) { return; }
    //let turnAmount = (timeDelta / 1000) * THREE.Math.degToRad(data.turnSpeed);

    let turnAmount = 20;
    if (this.isKeyPressed(this.data.turnLeftKey))
		{
			this.addYawTargetY(0.002 * turnAmount);
		} 
    
    if (this.isKeyPressed(this.data.turnRightKey))
		{
			this.addYawTargetY(-0.002 * turnAmount);
		}
    this.updateOrientation(t);
  },

  play: function () {
    this.addEventListeners();
  },

  pause: function () {
    this.removeEventListeners();
    if (this.pointerLocked) { this.exitPointerLock(); }
  },

  remove: function () {
    this.removeEventListeners();
    if (this.pointerLocked) { this.exitPointerLock(); }
  },

  bindMethods: function () {
    this.onMouseDown = bind(this.onMouseDown, this);
    this.onMouseMove = bind(this.onMouseMove, this);
    this.onMouseUp = bind(this.onMouseUp, this);
    this.onTouchStart = bind(this.onTouchStart, this);
    this.onTouchMove = bind(this.onTouchMove, this);
    this.onTouchEnd = bind(this.onTouchEnd, this);
    this.onEnterVR = bind(this.onEnterVR, this);
    this.onExitVR = bind(this.onExitVR, this);
    this.onPointerLockChange = bind(this.onPointerLockChange, this);
    this.onPointerLockError = bind(this.onPointerLockError, this);
    this.onKeyDown = bind(this.onKeyDown, this);
    this.onKeyUp = bind(this.onKeyUp, this);
  },

  /**
   * Set up states and Object3Ds needed to store rotation data.
   */
  setupMouseControls: function () {
    this.mouseDown = false;
    this.pitchObject = new THREE.Object3D();
    this.yawObject = new THREE.Object3D();
    this.yawObject.position.y = 10;
    this.yawObject.add(this.pitchObject);
  },

  /**
   * Add mouse and touch event listeners to canvas.
   */
  addEventListeners: function () {
    var sceneEl = this.el.sceneEl;
    var canvasEl = sceneEl.canvas;

    // Wait for canvas to load.
    if (!canvasEl) {
      sceneEl.addEventListener('render-target-loaded', bind(this.addEventListeners, this));
      return;
    }

    // Mouse events.
    canvasEl.addEventListener('mousedown', this.onMouseDown, false, { passive: true });
    window.addEventListener('mousemove', this.onMouseMove, false, { passive: true });
    window.addEventListener('mouseup', this.onMouseUp, false, { passive: true });

    // Touch events.
    canvasEl.addEventListener('touchstart', this.onTouchStart, { passive: true });
    window.addEventListener('touchmove', this.onTouchMove, { passive: true });
    window.addEventListener('touchend', this.onTouchEnd, { passive: true });

    // sceneEl events.
    sceneEl.addEventListener('enter-vr', this.onEnterVR);
    sceneEl.addEventListener('exit-vr', this.onExitVR);

    // Pointer Lock events.
    if (this.data.pointerLockEnabled) {
      document.addEventListener('pointerlockchange', this.onPointerLockChange, false);
      document.addEventListener('mozpointerlockchange', this.onPointerLockChange, false);
      document.addEventListener('pointerlockerror', this.onPointerLockError, false);
    }

    document.addEventListener("keydown", this.onKeyDown, false);

		document.addEventListener("keyup", this.onKeyUp, false);
  },

  /**
   * Remove mouse and touch event listeners from canvas.
   */
  removeEventListeners: function () {
    var sceneEl = this.el.sceneEl;
    var canvasEl = sceneEl && sceneEl.canvas;

    if (!canvasEl) { return; }

    // Mouse events.
    canvasEl.removeEventListener('mousedown', this.onMouseDown, { passive: true });
    window.removeEventListener('mousemove', this.onMouseMove, { passive: true });
    window.removeEventListener('mouseup', this.onMouseUp, { passive: true });

    // Touch events.
    canvasEl.removeEventListener('touchstart', this.onTouchStart, { passive: true });
    window.removeEventListener('touchmove', this.onTouchMove, { passive: true });
    window.removeEventListener('touchend', this.onTouchEnd, { passive: true });

    // sceneEl events.
    sceneEl.removeEventListener('enter-vr', this.onEnterVR);
    sceneEl.removeEventListener('exit-vr', this.onExitVR);

    // Pointer Lock events.
    document.removeEventListener('pointerlockchange', this.onPointerLockChange, false);
    document.removeEventListener('mozpointerlockchange', this.onPointerLockChange, false);
    document.removeEventListener('pointerlockerror', this.onPointerLockError, false);

    // arrow left/rigt key events
    document.removeEventListener('keyup', this.onKeyUp, false);
    document.removeEventListener('keydown', this.onKeyDown, false);
  },

  addYawTargetY: function (movex) {
    this.yawSource = this.yawObject.rotation.y;

    // this.yawTarget = (this.yawTarget === undefined ? this.yawObject.rotation.y : this.yawTarget) + movex;
    if (this.data.type == 'singleBooth') {
      this.yawTarget = THREE.Math.clamp((this.yawTarget === undefined ? this.yawObject.rotation.y : this.yawTarget) + movex*2, -Math.PI / 2 - 0.47, -Math.PI / 2 + 0.47) 
    } else {
      this.yawTarget = (this.yawTarget === undefined ? this.yawObject.rotation.y : this.yawTarget) + movex;

    }
    this.startMoveTime = Date.now() / 1000;
  },

  /**
   * Update orientation for mobile, mouse drag, and headset.
   * Mouse-drag only enabled if HMD is not active.
   */
  updateOrientation: (function () {
    var poseMatrix = new THREE.Matrix4();

    return function (t) {
      var object3D = this.el.object3D;
      var pitchObject = this.pitchObject;
      var yawObject = this.yawObject;
      var pose;
      var sceneEl = this.el.sceneEl;

      // In VR mode, THREE is in charge of updating the camera pose.
      if (sceneEl.is('vr-mode') && sceneEl.checkHeadsetConnected()) {
        // With WebXR THREE applies headset pose to the object3D matrixWorld internally.
        // Reflect values back on position, rotation, scale for getAttribute to return the expected values.
        if (sceneEl.hasWebXR) {
          pose = sceneEl.renderer.xr.getCameraPose();
          if (pose) {
            poseMatrix.elements = pose.transform.matrix;
            poseMatrix.decompose(object3D.position, object3D.rotation, object3D.scale);
          }
        }
        return;
      }

      this.updateMagicWindowOrientation();

      // if (this.yawTarget !== undefined) {
      //   yawObject.rotation.y = this.yawTarget;
      //   this.yawTarget = undefined;
      // }
      if (this.yawTarget !== undefined) {
        if (Math.abs(this.yawTarget - yawObject.rotation.y) < 0.00001) {
          this.yawTarget = undefined;
        } else {
          const t = Math.min(1, Math.max(0, Date.now() / 1000 - this.startMoveTime));
          // const r = easeOutCubic(t);
          const change = this.yawTarget - yawObject.rotation.y;
          yawObject.rotation.y += change / 20;

          // yawObject.rotation.y = this.yawSource + (1-((1-t) * (1-t))) * (this.yawTarget - this.yawSource);
          // console.log(yawObject.rotation.y, r, this.yawSource, this.yawTarget)
        }
      }

      // const currx = this.movementX || 0;
      // // yawObject.rotation.y += currx;
      // // this.movementX = 0;
      // if (currx) {
      //   yawObject.rotation.y += currx;
      //   this.movementX = 0;
      //   this.lastMoveX = currx;
      //   this.startMoveT = t / 1000;
      // } else if (this.startMoveT) {
      //   const dt = (t / 1000 - this.startMoveT) * 0.5;
      //   if (dt < 1) {
      //     yawObject.rotation.y += this.lastMoveX * (1 - easeOutQuad(dt));
      //   } else {
      //     this.startMoveT = undefined;
      //   }
      // }

      // On mobile, do camera rotation with touch events and sensors.
      object3D.rotation.x = this.magicWindowDeltaEuler.x + pitchObject.rotation.x;
      object3D.rotation.y = this.magicWindowDeltaEuler.y + yawObject.rotation.y;
      object3D.rotation.z = this.magicWindowDeltaEuler.z;

      
    };
  })(),

  updateMagicWindowOrientation: function () {
    var magicWindowAbsoluteEuler = this.magicWindowAbsoluteEuler;
    var magicWindowDeltaEuler = this.magicWindowDeltaEuler;
    // Calculate magic window HMD quaternion.
    if (this.magicWindowControls && this.magicWindowControls.enabled) {
      this.magicWindowControls.update();
      magicWindowAbsoluteEuler.setFromQuaternion(this.magicWindowObject.quaternion, 'YXZ');
      if (!this.previousMagicWindowYaw && magicWindowAbsoluteEuler.y !== 0) {
        this.previousMagicWindowYaw = magicWindowAbsoluteEuler.y;
      }
      if (this.previousMagicWindowYaw) {
        magicWindowDeltaEuler.x = magicWindowAbsoluteEuler.x;
        magicWindowDeltaEuler.y += magicWindowAbsoluteEuler.y - this.previousMagicWindowYaw;
        magicWindowDeltaEuler.z = magicWindowAbsoluteEuler.z;
        this.previousMagicWindowYaw = magicWindowAbsoluteEuler.y;
      }
    }
  },

  /**
   * Translate mouse drag into rotation.
   *
   * Dragging up and down rotates the camera around the X-axis (yaw).
   * Dragging left and right rotates the camera around the Y-axis (pitch).
   */
  onMouseMove: function (event) {
    var direction;
    var movementX;
    var movementY;
    var moveLimitX = 3; // custom
    var pitchObject = this.pitchObject;
    var previousMouseEvent = this.previousMouseEvent;
    var yawObject = this.yawObject;


    // Not dragging or not enabled.
    if (!this.data.enabled || (!this.mouseDown && !this.pointerLocked)) { return; }

    // Calculate delta.
    if (this.pointerLocked) {
      movementX = event.movementX || event.mozMovementX || 0;
      movementY = event.movementY || event.mozMovementY || 0;
    } else {
      movementX = event.screenX - previousMouseEvent.screenX;
      movementY = event.screenY - previousMouseEvent.screenY;


      // if (movementX < moveLimitX && movementX > -moveLimitX) { // custom - start movement pass certain point
      //   movementX = 0;
      // }
    }

    this.previousMouseEvent = event;


    // Calculate rotation.

    direction = this.data.reverseMouseDrag ? 1 : -1;
    // yawObject.rotation.y += movementX * 0.001 * direction;
    // this.movementX = movementX * 0.001 * direction;
    this.addYawTargetY(movementX * 0.003 * direction);
    
    // this.yawTarget = (this.yawTarget === undefined ? yawObject.rotation.y : this.yawTarget) + movex;


    // pitchObject.rotation.x += movementY * 0.002 * direction;
    // pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
  },

  /**
   * Register mouse down to detect mouse drag.
   */
  onMouseDown: function (evt) {
    var sceneEl = this.el.sceneEl;
    if (!this.data.enabled || (sceneEl.is('vr-mode') && sceneEl.checkHeadsetConnected())) { return; }
    // Handle only primary button.
    if (evt.button !== 0) { return; }

    var canvasEl = sceneEl && sceneEl.canvas;

    this.mouseDown = true;
    this.previousMouseEvent = evt;
    this.showGrabbingCursor();

    if (this.data.pointerLockEnabled && !this.pointerLocked) {
      if (canvasEl.requestPointerLock) {
        canvasEl.requestPointerLock();
      } else if (canvasEl.mozRequestPointerLock) {
        canvasEl.mozRequestPointerLock();
      }
    }
  },

  /**
   * Shows grabbing cursor on scene
   */
  showGrabbingCursor: function () {
    this.el.sceneEl.canvas.style.cursor = 'grabbing';
  },

  /**
   * Hides grabbing cursor on scene
   */
  hideGrabbingCursor: function () {
    this.el.sceneEl.canvas.style.cursor = '';
  },

  /**
   * Register mouse up to detect release of mouse drag.
   */
  onMouseUp: function () {
    this.mouseDown = false;
    this.hideGrabbingCursor();
  },

  /**
   * Register touch down to detect touch drag.
   */
  onTouchStart: function (evt) {
    if (evt.touches.length !== 1 ||
      !this.data.touchEnabled ||
      this.el.sceneEl.is('vr-mode')) { return; }
    this.touchStart = {
      x: evt.touches[0].pageX,
      y: evt.touches[0].pageY
    };
    this.touchStarted = true;
  },

  /**
   * Translate touch move to Y-axis rotation.
   */
  onTouchMove: function (evt) {
    var direction;
    var canvas = this.el.sceneEl.canvas;
    var deltaY;
    var yawObject = this.yawObject;
    var customTurnSpeed;


    if (!this.touchStarted || !this.data.touchEnabled) { return; }


    deltaY = 2 * Math.PI * (evt.touches[0].pageX - this.touchStart.x) / canvas.clientWidth;

    direction = this.data.reverseTouchDrag ? 1 : -1;
    // Limit touch orientaion to to yaw (y axis).
    // if (deltaY < 0.01 && deltaY > -0.01) { // custom -speeds up the more you turn
    //   customTurnSpeed = 0.1;
    // } else {
    //   customTurnSpeed = 0.35;
    // }
    // yawObject.rotation.y -= deltaY * customTurnSpeed * direction;
    const speed = (isMobile && (window.orientation === 90 || window.orientation === 270)) ? 0.32 : 0.16;
    // this.movementX = -deltaY * speed * direction;
    this.addYawTargetY(-deltaY * speed * direction);
    // this.yawTarget = (this.yawTarget === undefined ? yawObject.rotation.y : this.yawTarget) + movex;
    this.touchStart = {
      x: evt.touches[0].pageX,
      y: evt.touches[0].pageY
    };
  },

  /**
   * Register touch end to detect release of touch drag.
   */
  onTouchEnd: function () {
    this.touchStarted = false;
  },

  /**
   * Save pose.
   */
  onEnterVR: function () {
    var sceneEl = this.el.sceneEl;
    if (!sceneEl.checkHeadsetConnected()) { return; }
    this.saveCameraPose();
    this.el.object3D.position.set(0, 0, 0);
    this.el.object3D.rotation.set(0, 0, 0);
    if (sceneEl.hasWebXR) {
      this.el.object3D.matrixAutoUpdate = false;
      this.el.object3D.updateMatrix();
    }
  },

  /**
   * Restore the pose.
   */
  onExitVR: function () {
    if (!this.el.sceneEl.checkHeadsetConnected()) { return; }
    this.restoreCameraPose();
    this.previousHMDPosition.set(0, 0, 0);
    this.el.object3D.matrixAutoUpdate = true;
  },

  /**
   * Update Pointer Lock state.
   */
  onPointerLockChange: function () {
    this.pointerLocked = !!(document.pointerLockElement || document.mozPointerLockElement);
  },

  /**
   * Recover from Pointer Lock error.
   */
  onPointerLockError: function () {
    this.pointerLocked = false;
  },

  // Exits pointer-locked mode.
  exitPointerLock: function () {
    document.exitPointerLock();
    this.pointerLocked = false;
  },

  /**
   * Toggle the feature of showing/hiding the grab cursor.
   */
  updateGrabCursor: function (enabled) {
    var sceneEl = this.el.sceneEl;

    function enableGrabCursor() { sceneEl.canvas.classList.add('a-grab-cursor'); }
    function disableGrabCursor() { sceneEl.canvas.classList.remove('a-grab-cursor'); }

    if (!sceneEl.canvas) {
      if (enabled) {
        sceneEl.addEventListener('render-target-loaded', enableGrabCursor);
      } else {
        sceneEl.addEventListener('render-target-loaded', disableGrabCursor);
      }
      return;
    }

    if (enabled) {
      enableGrabCursor();
      return;
    }
    disableGrabCursor();
  },

  /**
   * Save camera pose before entering VR to restore later if exiting.
   */
  saveCameraPose: function () {
    var el = this.el;

    this.savedPose.position.copy(el.object3D.position);
    this.savedPose.rotation.copy(el.object3D.rotation);
    this.hasSavedPose = true;
  },

  /**
   * Reset camera pose to before entering VR.
   */
  restoreCameraPose: function () {
    var el = this.el;
    var savedPose = this.savedPose;

    if (!this.hasSavedPose) { return; }

    // Reset camera orientation.
    el.object3D.position.copy(savedPose.position);
    el.object3D.rotation.copy(savedPose.rotation);
    this.hasSavedPose = false;
  },

  /**
   * Arrow left/right key action
   */
   convertKeyName: function (keyName) {
		if (keyName == " ")
			return "Space";
		else if (keyName && keyName.length == 1)
			return keyName.toUpperCase();
		else
			return keyName;
	},

	registerKeyDown: function (keyName) {
		// avoid adding duplicates of keys
		if (!this.keyPressedSet.has(keyName))
			this.keyPressedSet.add(keyName);
	},

	registerKeyUp: function (keyName) {
		this.keyPressedSet.delete(keyName);
	},

	isKeyPressed: function (keyName) {
		return this.keyPressedSet.has(keyName);
	},

  onKeyDown(eventData) {
    this.registerKeyUp(this.convertKeyName(this.data.turnRightKey));
    this.registerKeyUp(this.convertKeyName(this.data.turnLeftKey));

    this.registerKeyDown(this.convertKeyName(eventData.key));
   
  },
  onKeyUp(eventData) {
    this.registerKeyUp(this.convertKeyName(eventData.key));
  }
});