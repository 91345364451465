export const clamp = (num, high, low = undefined) => {
    low = low === undefined ? -high : low;
    return Math.min(high, Math.max(low, num));
}

export const isMobile = function() {
    // return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true;
      }else{
        return false;
      }
};

export const easeOutQuad = (x) => {
    return 1 - (1 - x) * (1 - x);
}

export const easeOutCubic = (x) => {
    return 1 - Math.pow(1 - x, 3);
}

export const easeOutQuadDelay = (x, delay = 0.2) => {
    if (x < delay) return 0;
    return easeOutQuad(x / (1 - delay));
}

export const easeInOutQuad = (x) => {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

export const easeInOutCubic = (x) => {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}