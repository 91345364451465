<template>
    <div class="pop_up_alert" v-if="!sceneLoading && popupState && messageChanged" @mouseup="closePopup">
        <img src="../assets/booths/close_black.svg" alt />
      <span>  {{message}} </span>
    </div>
</template>
<script>
/*eslint-disable */
export default {
  name: "PopupAlert",
  props: ["sceneLoading", "exhibitionId"],
  components: {},
  watch: {},
  data: function () {
    return {
      popupState: false,
      messageChanged: false,
      message: '',
      rtlLetters: [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
      ],
    };
  },
 
  mounted: function () {
    setInterval(() => {
      axios.get(`https://admin.exponeto.com/api/checkNotification/${this.exhibitionId}`)
        .then(response => {
          this.popupState = response.data.alert;
          if (response.data.message !== '' && this.message.localeCompare(response.data.message) === -1) {
            console.log('message content is changed', response.data.message)
            this.message = response.data.message;
            this.messageChanged = true;
          }
        })
    }, 5000)
  },

  methods: {
    closePopup: function(e) {
      this.messageChanged = false;
    },
    // setHebrewStyle: function (text) {
    //   if (text =='' || text == undefined) return;
    //   if(this.rtlLetters.includes(text.charAt(0))) {
    //     return "text-align: center; direction: rtl"
    //   }

    //   return "text-align: left; direction: ltr"
    // },
  },
  computed: {
   
  },
};

import axios from "axios"
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pop_up_alert {
    display: flex;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    width: 40%;
    background: white;
    color: #E23C21;
    z-index: 9;
    justify-content: center;
    flex-direction: column;
    font-size: 28px;
    font-family: "Assistant", sans-serif !important;
    text-align: center;
    padding: 50px 40px 40px 40px;
    margin: auto;
    border-radius: 8px;
    cursor: pointer;
    @media only screen and (max-width: 800px) {
        width: 200px;
        font-size: 18px;
        padding: 30px 20px 25px 20px;
        border-radius: 5px;
    }
    span {
      text-align: center;
      font-weight: 600;
    }
    img {
      width: 15px;
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0.6;
      @media only screen and (max-width: 800px) {
        width: 12px;
        top: 7px;
        right: 7px;
    }
  }
}
</style>
