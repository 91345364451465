<template>
  <div :class="`on-screen ${isMobile ? 'on-screen-mobile' : ''}`">
    <div class="top-strip">
      <div class="left-pane">
        <div v-if="this.logo" class="logo">
          <img :src="this.logo" alt />
        </div>
      </div>
      <div class="right-pane">
        <!-- <a href="https://play.detherverse.com/" target="_blank" class="expo drk-btn" v-if="!isMobile">
          <div class="tooltip">
            <img src="/dist/render-expo/img/exponeto-logo.png" alt />
          </div>
        </a> -->
        <button class="share drk-btn icon" @click.stop.prevent="
          (toggleShare = !toggleShare),
          (searchOpen = false),
          (menuOpen = false)">
          <div class="tooltip">
            <img src="/dist/render-expo/icons/share.svg" alt />
            <span v-if="!isMobile" class="tooltiptext">Share</span>
          </div>
          <div :class="`social `" v-if="toggleShare">
            <SocialOnScreen :exhibitionName="exhibitionName" />
          </div>
        </button>
        <button :class="`pins drk-btn icon ${users}`" @mouseup="(users = !users), $emit('hideusers')">
          <div class="tooltip">
            <img :src="`/dist/render-expo/icons/${users ? 'users' : 'users-unable'
              }.svg`" alt />
            <span v-if="!isMobile" class="tooltiptext">{{ users ? "Show Visitors" : "Hide Visitors" }}</span>
          </div>
        </button>
        <button class="fullscreen drk-btn icon" @mouseup="toggleFullScreen()">
          <div class="tooltip">
            <img :src="`/dist/render-expo/icons/${!fulllscreen ? 'fullscreen' : 'miniscreen'
              }.svg`" alt />
            <span v-if="!isMobile" class="tooltiptext"> {{ !fulllscreen ? "Fullscreen" : "Exit Fullscreen" }}</span>
          </div>
        </button>
        <button class="reset-pos drk-btn icon" @mouseup="resetPos">
          <div class="tooltip">
            <img src="/dist/render-expo/icons/reset-pos.svg" alt />
            <span v-if="!isMobile" class="tooltiptext">Back to Start</span>
          </div>
        </button>
        <button id="instruction" class="help drk-btn icon" @mouseup="$emit('helpInstruction')">
          <div class="tooltip">
            <img src="/dist/render-expo/icons/help.svg" alt />
            <span v-if="!isMobile" class="tooltiptext">Instructions</span>
          </div>
        </button>
        <button id="conference" class="confr drk-btn" @mouseup="conferenceTeleport">
          Conference
        </button>
        <!-- <div class="search">
          <button
            class="drk-btn icon"
            @click.stop.prevent="
              (searchOpen = !searchOpen),
                (toggleShare = false),
                (menuOpen = false)
            "
          >
            <img
              v-if="!searchOpen"
              src="/dist/render-expo/icons/search-yellow.svg"
              alt
            />
            <img v-else src="/dist/render-expo/icons/close-search.svg" alt />
          </button>
          <div class="bar" v-if="searchOpen">
            <input type="text" placeholder="search" />
            <img
              class="grey-icon"
              src="/dist/render-expo/icons/search-grey.svg"
              alt
            />
          </div>
        </div> -->
        <div class="menu">
          <button :class="`menu-top ${isMobile ? 'menu-top-mobile' : ''}`" @click.stop.prevent="
            (toggleShare = false),
            (menuOpen = !menuOpen),
            (searchOpen = false)
            ">
            <p class="text" v-if="!isMobile">Exhibitors Menu</p>
            <div class="hamburger" v-else>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <img v-if="!isMobile" :src="`/dist/render-expo/icons/arrow-${menuOpen ? 'up' : 'down'
              }.svg`" alt />
          </button>
          <div class="menu-bottom" v-if="menuOpen">
            <div class="inner-wrap" :style="setHebrewStyle(menu[0].compName)">
              <div class="company" v-for="(item, i) in menu" :key="i">
                <div class="category" @mouseup="item.open = !item.open">
                  {{ item.compName }}
                </div>
                <span v-if="item.open">
                  <div class="sub" v-for="(sub, j) in item.sub" :key="j">
                    <div>
                      <div style="" @mouseup="gotoBooth(sub.link)" target="_blank">{{ sub.name }}</div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="mobile-conf-wrap">
              <button class="confr drk-btn" v-if="isMobile" @mouseup="conferenceTeleport">
                Conference
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom-strip">
      <div class="left-pane">
        <div class="curr-visitors">
          <div :class="`drk-btn top ${visitorList ? 'open-vis' : ''}`">
            <p class="txt">Current Visitors</p>
            <button class="toggle" @mouseup="visitorList = !visitorList">
              <img
                v-if="visitorList"
                src="/dist/render-expo/icons/minus.svg"
                alt
              />
            </button>
          </div>
          <div class="bottom" v-if="visitorList">
            <ul>
              <li
                v-for="(visitor, index) in occupants"
                :key="index"
                @mouseup="occuClick(visitor)"
                class="user-wrap"
              >
                <div v-if="(exhibitionId === parseInt(visitor.session_id)) && ((visitor.pos !== undefined) || myUser === visitor.id)">
                  <span class="user-name">{{ visitor.userInfo.full_name }}</span>
                  <span v-if="myUser === visitor.id">(me)</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right-pane">
        <button class="prev booth-page drk-btn" @mouseup="toggleBooth('prev')">
          Previous booth
        </button>
        <button class="next booth-page drk-btn" @mouseup="toggleBooth('next')">
          Next booth
        </button>
      </div>
    </div> -->
  </div>
</template>
<script>
/*eslint-disable */
import SocialOnScreen from "@/components/SocialOnScreen";

export default {
  name: "on-screen",
  components: { SocialOnScreen },
  watch: {},
  data: function () {
    return {
      currBooth: 0,
      users: false,
      fulllscreen: false,
      visitorList: true,
      isMobile: false,
      toggleShare: false,
      menuOpen: false,
      searchOpen: false,
      menu: [],
      rtlLetters: [
        "א",
        "ב",
        "ג",
        "ד",
        "ה",
        "ו",
        "ז",
        "ח",
        "ט",
        "י",
        "כ",
        "ל",
        "מ",
        "נ",
        "ס",
        "ע",
        "פ",
        "צ",
        "ק",
        "ר",
        "ש",
        "ת",
      ],
    };
  },
  props: ["myUserDetails", "telePositions", "occupants", "myUser", "menuData", "exhibitionId", "logo", "exhibitionName", "jsonData"],

  methods: {
    test: function () {
      console.log('test mode')
    },
    occuClick: function (visitor) {
      if (this.myUser !== visitor.id) {
        let player = document.querySelector(".player");
        let cam = document.querySelector(".a-camera");
        let pos = visitor.pos;
        let posZ = 0;
        console.log(visitor);
        // pos.z = pos.z + 10
        if (pos.z > -10) {
          posZ = pos.z - 10;
        } else {
          posZ = pos.z + 10;
        }
        player.setAttribute(
          "animation",
          `property: position; to: ${pos.x} 0${Math.random() / 100} ${posZ
          }; dur: 1000;`
        );
        this.$emit("togglelc");

        if (pos.z > -10) {
          cam.components["custom-look-controls"].yawObject.rotation.y = 3.14;
          cam.components["custom-look-controls"].yawTarget = 3.14;
        } else {
          cam.components["custom-look-controls"].yawObject.rotation.y = 0;
          cam.components["custom-look-controls"].yawTarget = 0;
        }

        this.$emit("togglelc");
      }
    },
    gotoBooth: function (boothNum) {
      this.$emit("gotoBooth", boothNum)
      this.$emit("onScreenStatistic", { type: 'Entrance to the booth through a menu', boothNum: this.jsonData.booths[boothNum].booth_id })
    },
    toggleBooth: function (direction) {
      let player = document.querySelector(".player");
      let playerPos = player.object3D.position;
      let cam = document.querySelector(".a-camera");
      let closestBooth;
      let boothAmount = 31;

      let distances = [];
      for (let i = 0; i < this.telePositions.length; i++) {
        const telePos = this.telePositions[i].position;
        var dx = telePos.x - playerPos.x;
        var dz = telePos.z - playerPos.z;
        distances.push(Math.sqrt(dx * dx + dz * dz));
      }

      closestBooth = distances.findIndex(
        (element) => element === Math.min(...distances)
      );

      if (direction === "next") {
        if (this.currBooth === boothAmount) {
          this.currBooth = 0;
        } else {
          // this.currBooth++;
          this.currBooth = closestBooth + 1;
        }
      }
      if (direction === "prev") {
        if (this.currBooth === 0) {
          this.currBooth = boothAmount;
        } else {
          // this.currBooth--;
          this.currBooth = closestBooth - 1;
        }
      }
      console.log('current booth', this.currBooth)

      let x;
      let z;
      if (this.telePositions[this.currBooth].rotation === "0 180 0") {
        x = this.telePositions[this.currBooth].position.x + 14;
        z = this.telePositions[this.currBooth].position.z - 2;

        cam.components["custom-look-controls"].yawTarget = 1.5;
        cam.components["custom-look-controls"].yawObject.rotation.y = 1.5;
        cam.components["extended-wasd-controls"].turnAngle = 1.5;
      } else {
        x = this.telePositions[this.currBooth].position.x - 14;
        z = this.telePositions[this.currBooth].position.z + 2;
        cam.components["custom-look-controls"].yawTarget = -1.6;
        cam.components["custom-look-controls"].yawObject.rotation.y = -1.6;
        cam.components["extended-wasd-controls"].turnAngle = -1.6;
      }

      player.setAttribute(
        "animation",
        `property: position; to: ${x} ${this.telePositions[this.currBooth].position.y
        } ${z}; dur:700;`
      );
      this.$emit("playerPosition", player.object3D.position);
      this.$emit("onScreenStatistic", { type: 'Entrance to the booth via next / previous button', boothNum: this.jsonData.booths[this.currBooth + 1].booth_id })
    },

    resetPos: function (res) {
      let player = document.querySelector(".player");
      let cam = document.querySelector(".a-camera");
      player.setAttribute("position", "7.58 0 16.55");
      cam.components["custom-look-controls"].yawTarget = 1.047
      cam.components["custom-look-controls"].yawObject.rotation.y = 1.047;
      cam.components["extended-wasd-controls"].turnAngle = 1.047;
    },
    conferenceTeleport: function (res) {
      let cam = document.querySelector(".a-camera");
      let player = document.querySelector(".player");
      player.setAttribute(
        "animation",
        "property: position; to: -45 0 -48.0" +
        `${Math.random()}`.split(".").join("") +
        "; dur: 1000;"
      );
      cam.setAttribute(
        "animation",
        `property: rotation; to: 0 ${Math.random()} 0; easing:linear;`
      );
      cam.components["custom-look-controls"].yawObject.rotation.y = 0;
      cam.components["custom-look-controls"].yawTarget = 0;
      cam.components["extended-wasd-controls"].turnAngle = 0;
    },
    toggleFullScreen: function () {
      let elem = document.body;
      this.fulllscreen = !this.fulllscreen;
      // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    getSubMenu: function (subs) {
      if (subs.length === 0) return '';
      var subArray = [];
      for (var i = 0; i < subs.length; i++) {
        subArray.push({ name: subs[i].name, link: subs[i].link })
      }
      return subArray;
    },

    setHebrewStyle: function (text) {
      if (text == '' || text == undefined) return;
      if (this.rtlLetters.includes(text.charAt(0))) {
        return "text-align: right; direction: rtl"
      }

      return "text-align: left"
    },
  },
  computed: {},
  created: function () {

  },
  mounted: function () {
    this.isMobile = AFRAME.utils.device.isMobile();
    if (this.isMobile) {
      this.visitorList = false;
    }

    for (var i = 0; i < this.menuData.length; i++) {
      this.menu.push(
        {
          open: false,
          compName: this.menuData[i].name,
          sub: this.getSubMenu(this.menuData[i].sub)
        }
      )
    }

    let _self = this
    document.querySelector('a-scene').addEventListener('click', function (evt) {
      if (_self.menuOpen) _self.menuOpen = false
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button {
  cursor: pointer;
}

.on-screen {
  font-family: "Assistant", sans-serif !important;
  background-color: #18648f !important;

  .top-strip {
    .left-pane {
      position: absolute;
      top: 20px;
      left: 27px;
    }

    .right-pane {
      position: absolute;
      right: 27px;
      top: 20px;
      display: flex;
    }
  }

  .bottom-strip {
    .left-pane {
      position: absolute;
      left: 28px;
      bottom: 18px;
    }

    .right-pane {
      position: absolute;
      right: 28px;
      bottom: 18px;
      display: flex;
    }
  }
}

.logo {
  max-width: 193px;
  max-height: 96px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  padding: 2px;
  // background-image: url('/dist/render-expo/img/test-logo.jpg');
  // background-repeat: no-repeat;
  // // background-attachment: fixed;
  // background-position: center;
  // box-shadow: 0px 0px 10px #0000004d;
  border-radius: 5px;
  opacity: 1;

  img {
    width: 100%;
  }
}

.menu {
  position: relative;
  font-weight: 600;
  color: #2e2e2e;
  z-index: 2;

  .text {
    margin: 0;
  }

  .menu-top {
    padding-left: 19px;
    padding-right: 27px;
    background: #ffdd00;
    align-items: center;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    height: 45px;
    width: 350px;

    &.menu-top-mobile {
      height: 100%;
      width: 100%;
      max-height: 38px;
      max-width: 38px;
      padding: 7px 10px;
      display: flex;
      justify-content: center;

      .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          background: black;
          width: 24px;
          height: 3px;

          &:not(:last-of-type) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .menu-bottom {
    position: absolute;
    width: 350px;
    background-color: white;
    cursor: pointer;

    border-radius: 5px;
    margin-top: 5px;
    padding: 9px 0;
    max-height: 80vh;
    z-index: 10;

    @media only screen and (max-width: 800px) and (min-width: 400px) {
      max-height: 50vh;
      // padding-bottom: 45px;
    }

    .inner-wrap {
      max-height: 80vh;

      @media only screen and (max-width: 800px) and (min-width: 400px) {
        max-height: 38vh;
        overflow-y: auto;
      }

      overflow-y: auto;
    }

    .company {
      padding: 0 11px;
      margin-bottom: 4px;

      .category {
        color: #2e2e2e;
        font-size: 22px;

        @media only screen and (max-width: 800px) {
          font-size: 16px;
        }

        padding: 5px 15px 10px 15px;
        background-color: #e8e8e8;
        border-radius: 5px;
        cursor: pointer;
      }

      .sub {
        color: #2e2e2e;
        padding: 8px 35px 13px 35px;
        font-size: 20px;

        a {
          color: black;
          text-decoration: none;
        }

        @media only screen and (max-width: 800px) {
          font-size: 14px;
        }

        border-bottom: 1px solid #dbd8d8;

        &:last-of-type {
          margin-bottom: -4px;
          border-bottom: unset;
        }
      }
    }
  }
}

.open-menu {
  border-radius: 5px;
  width: 350px;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: relative;
}

.confr {
  padding: 0 12px;
  font-size: 20px;
  max-width: 172px !important;
}

.mobile-conf-wrap {
  bottom: 10px;
  left: 11px;
  width: 207px;
  padding: 0 11px;
  margin-top: 7px;

  .confr {
    height: 34px;
    width: 100%;
    max-width: unset !important;
    min-width: unset !important;

    @media only screen and (max-width: 800px) {
      font-size: 16px;
    }

    @media only screen and (min-width: 480px) {
      display: none;
    }
  }
}

.pins {
  img {
    position: relative;
    bottom: 1px;
  }
}

.search {
  position: relative;

  .drk-btn {
    height: 100%;
    margin-bottom: 4px;
  }

  .bar {
    position: absolute;
    // max-height: 50px;
    // height: 100%;
    background: #2e2e2e;
    border-radius: 5px;

    input {
      margin: 7px;
      height: 37px;
      width: 233px;
      padding-right: 28px;
    }

    .grey-icon {
      position: absolute;
      top: 20px;
      right: 25px;
    }
  }
}

.curr-visitors {
  min-width: 266px;
  max-width: 266px;

  .top {
    // max-width: 266px;
    // width: 100%;
    height: 49px;
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    padding-right: 14px;
    padding-left: 20px;
    margin-right: 0 !important;

    &.open-vis {
      border-radius: 5px 5px 0px 0px;
    }

    .toggle {
      max-width: 24px;
      max-height: 24px;
      width: 100%;
      height: 100%;
      border: 1px solid #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .bottom {
    max-height: 172px;

    // width: 266px;
    overflow-y: scroll;
    background: #f3eee8;
    border-radius: 0 0px 5px 5px;
    padding: 8px 0;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        padding: 0px 20px;
        font-size: 16px;
        line-height: 27px;

        &:hover {
          background-color: #18638f3d;
        }
      }
    }
  }
}

.booth-page {
  width: 206px;
  height: 49px;
  border-radius: 5px;
  font-size: 22px;

  &.next {
    margin-right: 0;
  }
}

.on-screen-mobile {
  .booth-page {
    width: 98px;
    height: 32px;
    font-size: 12px;
  }
}

.drk-btn {
  background: #2e2e2e;
  border-radius: 5px;
  min-width: 45px;
  max-height: 45px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.expo {
  // padding-left: 5px !important;
  // padding-right: 5px !important;
  // min-width: 163px;
  // width: 100%;
  width: 153px;
  position: relative;
}

.on-screen-mobile {
  .top-strip {
    .left-pane {
      top: 6px;
      left: 8px;
    }

    .right-pane {
      top: 6px;
      right: 8px;
    }
  }

  .bottom-strip {
    .right-pane {
      bottom: 6px;
      right: 8px;
    }

    .left-pane {
      bottom: 6px;
      left: 8px;
    }
  }

  .drk-btn.icon {
    min-width: 38px;
    max-width: 38px;
    height: 38px;
  }

  .bar {
    left: -135px;
    // width: 100% !important;
    max-width: 220px;

    input {
      // width: 100%;
      max-width: 174px;
      height: 31px;
    }

    .grey-icon {
      top: 16px;
      right: 17px;
    }
  }

  .logo {
    top: 5px;
    left: 8px;
    max-width: 106px;
    max-height: 52px;
  }

  .menu {
    width: 38px;

    .menu-bottom {
      width: 227px;
      max-height: 62vh;
      right: 0;
      overflow-y: auto;

      .inner-wrap {
        max-height: 50vh;
        overflow-y: auto;
      }
    }
  }

  .curr-visitors {
    min-width: 129px;
    max-width: 129px;

    .top {
      padding-right: 9px;
      padding-left: 9px;
      height: 32px;

      p {
        font-size: 12px;
        margin: 0;
      }
    }

    .bottom {
      font-size: 14px;
      padding-left: 11px;

      li {
        padding: unset;
        margin-bottom: 3px;
      }
    }

    .toggle {
      max-width: 19px;
      max-height: 19px;

      img {
        max-height: 19px;
      }
    }
  }
}

// .onscreen {
//   transform: scale(0.5);
//   .inner-wrap {
//     width: 500px;
//     display: flex !important;
//     padding: 0 20px 17px 20px !important;
//     .social-icon {
//       width: 55px;
//       height: 52px;
//     }
//   }
// }
// .on-screen-mobile {
//   .social {
//     // min-width: 211px;
//     @media only screen and (max-width: 900px) {
//       max-width: unset;
//       // max-width: 74vw;
//     }
//     .inner-wrap {
//       width: 363px;
//       padding-right: 35px;
//       padding-left: 35px;
//       padding-bottom: 25px;
//       .social-icon {
//         width: 28px;
//       }
//     }
//   }
// }
.social {
  background-color: white;
  border: 1px solid #707070;
  border-radius: 5px;
  position: absolute;
  top: 55px;

  .toolbar {
    text-align: center;

    font-size: 28px;
    font-weight: 800;
    position: relative;

    .title {
      color: #707070;
    }

    .close {
      color: #707070;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 25px;
    }
  }

  .inner-wrap {
    padding: 35px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.social-icon {
  cursor: pointer;
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.user-wrap {
  display: flex;

  .user-name {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 10px;
    // text-overflow: ellipsis;
  }
}

.tooltip {
  display: flex;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: relative;
  justify-content: center;
  align-content: center;
  padding: 7px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 43px;
  left: 32px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.fullscreen,
#conference,
#instruction {
  @media only screen and (max-width: 480px) {
    display: none;
  }
}</style>
