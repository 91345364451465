<template>
  <div class="home">
    <Scene />
  </div>
</template>

<script>
// @ is an alias to /src
import Scene from "@/components/Scene.vue";

export default {
  name: "home",
  components: {
    Scene
  }
};
</script>
