<template>
  <a-entity lod>
    <a-gltf-model
      @model-loaded="loadedBooth(), (loadingOrder.booth = true)"
      scale="3 3 3"
      position="1 -0.2 3"
      class="booth-object loadCount rayClick"
      src="#booth"
      :booth-strip-custom="`gradientSrc: ${stripColor}`"
    >
      <a-image
        id="brochure"
        v-if="!!jsonData && jsonData.brochures && jsonData.brochures.length > 0"
        :src="
          !!jsonData && !!jsonData.brochures ? jsonData.brochures[0].image : ''
        "
        rotation="-8 -80 0"
        position="0.306 0.537 -0.982"
        scale="0.234 0.3 0.3"
      ></a-image>
      <a-entity
        class="brochure-box loadCount rayClick"
        v-if="!!jsonData && jsonData.brochures && jsonData.brochures.length > 0"
        :gltf-model="brochureBox"
        rotation="0 190 0"
        position="0.27 0.378 -0.973"
        scale="0.13 0.13 0.13"
        model-opacity="01"
        @model-loaded="loadingOrder.brochure = true"
        @mousedown="$emit('imageDown')"
        @mouseup="emitBrochureClick()"
      >
        <a-text
          id="font-test "
          position="0.15 0.3 0.9"
          rotation="0 90 0"
          value="Brochure"
          color="#000000"
          width="10"
          letter-spacindg="4"
        ></a-text>
      </a-entity>
      <a-entity class="colliders">
        <a-entity class="booth-walls">
          <a-box
            class="wall"
            scale="1 5 9.5"
            visible="false"
            static-body
            rotation="0 0 0"
            position="0.8 1 0.3"
          ></a-box>
          <a-box
            class="wall"
            scale="1 5 5"
            visible="false"
            static-body
            rotation="0 60 0"
            position="0 1 -1.6"
          ></a-box>
        </a-entity>
        <a-entity class="desk-colliders">
          <a-box
            class="wall"
            scale="2 4 1"
            visible="false"
            static-body
            rotation="0 0 0"
            position="-0.5 0.65 2"
          ></a-box>
          <a-box
            class="wall"
            scale="1 4 2"
            visible="false"
            rotation="0 0 0"
            static-body
            position="-0.7 0.65 1.5"
          ></a-box>
        </a-entity>
      </a-entity>
    </a-gltf-model>

    <a-image
      v-if="!!jsonData && !!jsonData.style.presentor"
      :src="jsonData.style.presentor"
      class="secretary rayClick"
      position="0 1.35 8.4"
      rotation="0 -90 0"
      scale="1.5 3.3"
      transparent="true"
      material="alphaTest: 0.5"
      look-at="[camera]"
      :visible="jsonData.style.presentor === '' ? false : false"
      @mousedown="$emit('imageDown')"
      @mouseup="
        $emit('infoCompany', { type: 'booth_attendant', data: jsonData })
      "
    ></a-image>

    <!-- <a-image
      v-else-if="boothType === 'allBooth'"
      src="#booth-secretary"
      class="secretary rayClick"
      position="0 1.35 7.7"
      rotation="0 -90 0"
      scale="1.5 3.3"
      transparent="true"
      material="alphaTest: 0.5"
      look-at="[camera]"
      @mousedown="$emit('imageDown')"
      @mouseup="$emit('infoCompany', {type: 'presentor', data: jsonData})"
    ></a-image> -->

    <a-entity position="0.5 -3.7 0" scale="1.75 1.65 1.75">
      <a-image
        class="back-back-logo rayClick"
        v-if="!!jsonData && !!jsonData.style"
        :src="!!jsonData && !!jsonData.style ? jsonData.style.logo : ''"
        position="0.375 4.7 -1.425"
        rotation="0 155.5 0"
        scale="1.3 0.7 1"
        @mousedown="$emit('imageDown')"
        @mouseup="$emit('infoCompany', { type: 'backLogo', data: jsonData })"
      >
        <a-rounded
          position="-0.58 -0.63 -0.006"
          scale="1.18 1.31 1.2"
          color="#FFFFFF"
          radius="0.1"
        ></a-rounded>
      </a-image>
    </a-entity>
    <a-image
      class="back-logo rayClick"
      v-if="!!jsonData && !!jsonData.style"
      :src="!!jsonData && !!jsonData.style ? jsonData.style.logo : ''"
      position="1.66 6.013 7.7"
      rotation="0 -90 0"
      scale="1.6 0.9 1"
      @mousedown="$emit('imageDown')"
      @mouseup="$emit('infoCompany', { type: 'topLogo', data: jsonData })"
    ></a-image>
    <a-image
      class="front-logo-front rayClick"
      v-if="!!jsonData && !!jsonData.style"
      :src="!!jsonData && !!jsonData.style ? jsonData.style.frontdesk : ''"
      position="-1.478 0.8 7.8"
      rotation="0 -90 0"
      scale="1.5 0.844 1"
      @mousedown="$emit('imageDown')"
      @mouseup="$emit('infoCompany', { type: 'frontLogo', data: jsonData })"
    ></a-image>
    <a-image
      class="front-logo-right rayClick"
      v-if="!!jsonData && !!jsonData.style"
      :src="!!jsonData && !!jsonData.style ? jsonData.style.frontdesk : ''"
      position="-0.375 0.8 9.134"
      rotation="0 -11 0"
      scale="1.5 0.844 1"
      @mousedown="$emit('imageDown')"
      @mouseup="$emit('infoCompany', { type: 'frontLogo', data: jsonData })"
    ></a-image>

    <a-entity
      class="wallItems"
      position="0 0.3 0.3"
      v-if="boothType === 'aloneBooth'"
    >
      <a-image
        v-for="item in items"
        :id="`booth-${item.id}`"
        :key="item.id"
        :canvas-pic-shadow="`canvasSrc: canvas_wall_item_${item.id}; imgSrc: img_wall_item_${item.id} ; text: ${item.data.name}; size: ${item.size};  type: ${item.type}; boothType: aloneBooth `"
        :visible="sceneLoading"
        :material="`shader: flat;src: #canvas_wall_item_${item.id};`"
        :position="item.pos"
        :rotation="item.rot"
        :geometry="`width: ${item.geo.w}; height: ${item.geo.h}`"
        class="rayClick"
        @mousedown="$emit('imageDown')"
        @mouseup="$emit('imageClick', item)"
      ></a-image>
      <a-entity v-if="items[0].type == undefined">
        <a-image
          v-for="item in items"
          :id="`booth-image-${item.id}`"
          :key="item.id"
          :src="item.data.image"
          :position="item.imagePos"
          :rotation="item.rot"
          :scale="item.imageScale"
        >
          <a-image
            v-if="item.data.type === 'video'"
            :src="item.data.image"
            position="0 0 0.005"
          >
            <a-image
              src="#playBtn"
              material="alphaTest: 0.48"
              position="0 0.04 0.01"
              :scale="item.videoScale"
            >
            </a-image>
          </a-image>
        </a-image>
      </a-entity>
    </a-entity>
    <a-entity class="wallItems" position="0 0.3 0.3" v-else>
      <a-image
        v-for="item in items"
        :id="`booth-${item.id}`"
        :key="item.id"
        :canvas-pic-shadow="`canvasSrc: canvas_wall_item_${
          item.id
        }; imgSrc: img_wall_item_${item.id} ; text: ${item.data.name}; size: ${
          item.size
        };  type: ${item.type ? item.type : 'booth'} ; boothType: allBooth`"
        :visible="sceneLoading"
        :material="`shader: flat;src: #canvas_wall_item_${item.id};`"
        :position="item.pos"
        :rotation="item.rot"
        :geometry="`width: ${item.geo.w}; height: ${item.geo.h}`"
        class="rayClick"
        @mousedown="$emit('imageDown')"
        @mouseup="$emit('imageClick', item)"
      ></a-image>
      <a-entity v-if="items[0] && items[0].type == undefined">
        <a-image
          v-for="item in items"
          :id="`booth-image-${item.id}`"
          :key="item.id"
          :src="item.data.image"
          :position="item.imagePos"
          :rotation="item.rot"
          :scale="item.imageScale"
        >
          <a-image
            v-if="item.data.type === 'video'"
            :src="item.data.image"
            position="0 0 0.005"
          >
            <a-image
              src="#playBtn"
              material="alphaTest: 0.48"
              position="0 0.04 0.01"
              :scale="item.videoScale"
            >
            </a-image>
          </a-image>
        </a-image>
      </a-entity>
    </a-entity>

    <StripText
      class="stripText"
      v-if="!!jsonData"
      @openSocial="emitSocial($event)"
      @subDialog="subDialog($event)"
      @phoneLink="emitPhoneLink($event)"
      @whatsappLink="emitwhatsappLink($event)"
      :jsonInfo="!!jsonData ? jsonData : ''"
    />
  </a-entity>
</template>

<script>
import Dialog from "@/components/Dialog";
import StripText from "@/components/StripText";
import PinName from "@/components/PinName";

export default {
  name: "BoothA",
  mounted: function () {
    /*eslint-disable */
  },
  components: {
    StripText,
    PinName,
    Dialog,
  },
  props: [
    "wallTemplate",
    "gradientColor",
    "items",
    "boothType",
    "pos",
    "farFromCam",
    "cullBooth",
    "chairScale",
    "tableScale",
    "boothNumber",
    "sceneLoading",
    "stripColor",
    "jsonData",
  ],
  data: function () {
    return {
      loadingOrder: {
        chair: false,
        table: false,
        brochure: false,
        booth: false,
        gradient: false,
      },
      smWallWidth: 5,
      lgWallWidth: 14,
      lgWallZ: 6,
      lgWallY: 2.75,
      boothsLoaded: 0,
    };
  },
  methods: {
    log: () => {
      console.log("this.booth");
      console.log(this.booth);
    },
    emitPhoneLink: function (res) {
      let booth_id = this.jsonData ? this.jsonData.booth_id : "";
      this.$emit("phoneLink", { data: res, booth_id: booth_id, type: "booth" });
    },
    emitSocial: function (res) {
      // this.$emit('openSocial', event)
      let booth_id = this.jsonData ? this.jsonData.booth_id : "";
      this.$emit("openSocial", {
        data: res,
        booth_id: booth_id,
        type: "booth",
      });
    },
    subDialog: function (res) {
      this.$emit("openSubDialog", res);
    },
    emitwhatsappLink: function (res) {
      let booth_id = this.jsonData ? this.jsonData.booth_id : "";
      this.$emit("whatsappLink", {
        data: res,
        booth_id: booth_id,
        type: "booth",
      });
    },
    emitBrochureClick: function (res) {
      this.$emit("brochureClick", { data: this.jsonData });
    },
    imageClick: (imgInfo) => {
      console.log("booth info", imgInfo);
    },
    loadedBooth: function (pos) {
      this.$emit("itemLoad");
    },
    // getScreen: function (url) {
    //   if(url === null){
    //   return "";
    //   }
    //   var vid;
    //   var results;
    //   results = url.match("[\\?&]v=([^&#]*)");

    //   vid = ( results === null ) ? url : results[1];

    //   return "https://img.youtube.com/vi/"+vid+"/0.jpg";
    // }
  },
  computed: {
    brochureBox() {
      return "https://cdn.glitch.com/3b2d1cee-c633-4b05-bbf2-07b88fcbad84%2Fbrochure_stand.glb?v=1587737364143";
    },
    assistant() {
      return "../../../assets/fonts/Assistant-Regular-msdf.json";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
