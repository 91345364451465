/*eslint-disable */

import Hammer from "hammerjs";
import { clamp, isMobile } from "../utils"

AFRAME.registerComponent('extended-wasd-controls', {

	schema:
	{
		/*
			Default key assignments: WASDQERFTG.
			(Pronounced: "wahz-dee-kerf-tig")
			WASD: standard forward/left/backward/right movement
			Mnemonics:
			QE: turn left/right (positioned above move left/right keys)
			RF: move up/down ("R"ise / "F"all)
			TG: look up/down (look at "T"ower / "G"round.
		*/
		moveForwardKey: { type: 'string', default: "W" },
		moveBackwardKey: { type: 'string', default: "S" },
		moveLeftKey: { type: 'string', default: "A" },
		moveRightKey: { type: 'string', default: "D" },
		moveForwardArrow: { type: 'string', default: "ArrowUp" },
		moveBackwardArrow: { type: 'string', default: "ArrowDown" },
		moveLeftArrow: { type: 'string', default: "Q" },
		moveRightArrow: { type: 'string', default: "E" },
		moveUpKey: { type: 'string', default: "R" },
		moveDownKey: { type: 'string', default: "F" },
		turnLeftKey: { type: 'string', default: "ArrowLeft" },
		turnRightKey: { type: 'string', default: "ArrowRight" },
		panLeft: { type: 'string', default: "panleft" },
		panRight: { type: 'string', default: "panright" },
		panUp: { type: 'string', default: "panup" },
		panDown: { type: 'string', default: "pandown" },
		lookUpKey: { type: 'string', default: "T" },
		lookDownKey: { type: 'string', default: "G" },

		flyEnabled: { type: 'boolean', default: false },
		turnEnabled: { type: 'boolean', default: true },
		lookEnabled: { type: 'boolean', default: false },

		maxLookEnabled: { type: 'boolean', default: true },
		maxLookAngle: { type: 'number', default: 60 },

		moveSpeed: { type: 'number', default: 20 },  // A-Frame units/second
		turnSpeed: { type: 'number', default: 30 }, // degrees/second
		lookSpeed: { type: 'number', default: 30 },  // degrees/second

		// use keyboard or other (e.g. joystick) to activate these controls
		inputType: { type: 'string', default: "keyboard" },

		moveStarted: { type: 'boolean', default: false}
	},

	convertKeyName: function (keyName) {
		if (keyName == " ")
			return "Space";
		else if (keyName && keyName.length == 1)
			return keyName.toUpperCase();
		else
			return keyName;
	},

	registerKeyDown: function (keyName) {
		// avoid adding duplicates of keys
		if (!this.keyPressedSet.has(keyName))
			this.keyPressedSet.add(keyName);
	},

	registerKeyUp: function (keyName) {
		this.keyPressedSet.delete(keyName);
	},

	isKeyPressed: function (keyName) {
		return this.keyPressedSet.has(keyName);
	},

	init: function () {
		// register key down/up events
		//  and keep track of all keys currently pressed

		let sceneEl = document.querySelector("a-scene");

		// sceneEl.dispatchEvent("");
		var hammertime = new Hammer(sceneEl);
		let moveDirection;
		let rotateDirection;
		let turnStartVelocity = 0.02;
		// console.log(this.el.object3D.parent);
		hammertime.get('pan').set({ threshold: 3 });

		hammertime.on('panleft', function (ev) {
			// console.log(ev.velocityX * 100);
			self.registerKeyUp(rotateDirection); //de-register previus pan

			rotateDirection = ev.type; // change direction to current pan
			self.data.turnSpeed = 20;//ev.velocityX * -100
			// console.log(self.data.turnSpeed);
			if (ev.velocityX < -turnStartVelocity) {
				self.registerKeyDown(rotateDirection);
			}

		});
		hammertime.on('panright', function (ev) {
			// console.log(ev.velocityX * 100);

			self.registerKeyUp(rotateDirection);//de-register previus pan
			rotateDirection = ev.type; // change direction to current pan
			self.data.turnSpeed = 20; //ev.velocityX * 100
			if (ev.velocityX > turnStartVelocity) {
				// console.log(self.data.turnSpeed);

				self.registerKeyDown(rotateDirection);
			}
			// console.log(this.data.lookSpeed);

		});
		hammertime.on('panup', function (ev) {

			self.registerKeyUp(moveDirection); //de-register previus pan
			moveDirection = ev.type; // change direction to current pan
			self.registerKeyDown(moveDirection);
		});
		hammertime.on('pandown', function (ev) {
			self.registerKeyUp(self.convertKeyName(self.data.moveForwardArrow));
			self.registerKeyUp(self.convertKeyName(self.data.moveBackwardArrow));
			self.registerKeyUp(moveDirection);//de-register previus pan

			moveDirection = ev.type; // change direction to current pan
			self.registerKeyDown(moveDirection);
		});
		hammertime.on('panend', function (ev) {
			self.registerKeyUp(self.convertKeyName(self.data.moveForwardArrow));
			self.registerKeyUp(self.convertKeyName(self.data.moveBackwardArrow));
			self.registerKeyUp(moveDirection);

			self.registerKeyUp(rotateDirection);
			moveDirection = '';
			rotateDirection = '';
		});


		this.keyPressedSet = new Set();

		let self = this;

		sceneEl.addEventListener(
			"wheel",
			(ev) => {
				self.registerKeyUp(self.convertKeyName(self.data.moveForwardArrow));
				self.registerKeyUp(self.convertKeyName(self.data.moveBackwardArrow));
				self.registerKeyUp(moveDirection);
				if (ev.deltaY < 0) {
					self.data.moveSpeed = 40;
					moveDirection = "panUp"; // change direction to current pan

					self.registerKeyDown(self.convertKeyName(self.data.moveForwardArrow));
				} if (ev.deltaY > 0) {
					self.data.moveSpeed = 40;
					moveDirection = "panDown"; // change direction to current pan

					self.registerKeyDown(self.convertKeyName(self.data.moveBackwardArrow));
				}
				setTimeout(function(){
					self.registerKeyUp(self.convertKeyName(self.data.moveForwardArrow));
					self.registerKeyUp(self.convertKeyName(self.data.moveBackwardArrow));
					self.data.moveSpeed = 20;
				},200);

			},
		);

		document.addEventListener("keydown",
			function (eventData) {
				self.registerKeyUp(self.convertKeyName(self.data.moveForwardArrow));
				self.registerKeyUp(self.convertKeyName(self.data.moveBackwardArrow));

				self.registerKeyUp(self.convertKeyName(self.data.turnRightKey));
				self.registerKeyUp(self.convertKeyName(self.data.turnLeftKey));

				self.registerKeyUp(moveDirection);//de-register previus pan

				// console.log(self.convertKeyName(eventData.key));
				self.registerKeyDown(self.convertKeyName(eventData.key));
			}
		);

		document.addEventListener("keyup",
			function (eventData) {
				self.registerKeyUp(self.convertKeyName(eventData.key));
			}
		);

		// movement-related data

		this.moveVector = new THREE.Vector3(0, 0, 0);
		this.movePercent = new THREE.Vector3(0, 0, 0);
		this.lastMovePercent = new THREE.Vector3(0, 0, 0);
		// z = forward/backward
		// x = left/right
		// y = up/down

		this.rotateVector = new THREE.Vector2(0, 0);
		this.rotatePercent = new THREE.Vector2(0, 0);
		// y = turn angle
		// x = look angle

		// used as reference vector when turning
		this.upVector = new THREE.Vector3(0, 1, 0);

		// current rotation amounts
		this.turnAngle = 1.047; // around global Y axis
		this.lookAngle = 0; // around local X axis

		// will = null or an object
		this.lookControls = this.el.components["look-controls"];

		// allows easy extraction of turn angle
		this.el.object3D.rotation.order = 'YXZ';

		this.moveStarted = false;
		this.isMobile = AFRAME.utils.device.isMobile();

		this.data.turnSpeed = 20;
	},


	tick: function (time, timeDelta) {
		let moveAmount = (timeDelta / 1000) * this.data.moveSpeed;
		// need to convert angle measures from degrees to radians
		let turnAmount = (timeDelta / 1000) * THREE.Math.degToRad(this.data.turnSpeed);
		let lookAmount = (timeDelta / 1000) * THREE.Math.degToRad(this.data.lookSpeed);
		let maxLookAngle = THREE.Math.degToRad(this.data.maxLookAngle);
		// rotations

		// reset values
		let totalTurnAngle = 0;
		let totalLookAngle = 0;
		// console.log(self.Hammer.Pan);

		// look-controls and extended-wasd-controls are compatible
		//   with desktop/mouse combo but not for tablet/gyroscope combo ("magic window" effect)
		//   (at least not with this code)
		// thus, look/turn automatically disabled when look-controls present

		// console.log("rev. 6");

		if (this.lookControls) // take into account look-controls, if they exist
		{
			// this code is only useful when trying to combine
			//   look-controls with extended-wasd rotation
			// totalTurnAngle += this.lookControls.yawObject.rotation.y;
			// totalLookAngle += this.lookControls.pitchObject.rotation.x;
		}
		else {
			if (this.data.inputType == "keyboard") {
				// need to reset rotatePercent values
				//   when querying which keys are currently pressed
				this.rotatePercent.set(0, 0);

				if (this.isKeyPressed(this.data.lookUpKey))
					this.rotatePercent.x += 1;
				if (this.isKeyPressed(this.data.lookDownKey))
					this.rotatePercent.x -= 1;

				if (this.isKeyPressed(this.data.panLeft) || this.isKeyPressed(this.data.turnLeftKey))
					{
						this.rotatePercent.y += 1;
						this.moveStarted = true;
					}
					
				if (this.isKeyPressed(this.data.panRight) || this.isKeyPressed(this.data.turnRightKey))
					{
						this.rotatePercent.y -= 1;
						this.moveStarted = true;
					}

				// center on horizon
				if (this.isKeyPressed(this.data.lookUpKey) && this.isKeyPressed(this.data.lookDownKey))
					this.lookAngle *= 0.90;

			}
			else // other, e.g. "joystick"
			{
				// assume this.rotatePercent values have been set/reset elsewhere (outside of this function)
			}

			if (this.data.lookEnabled) {
				this.lookAngle += this.rotatePercent.x * lookAmount;
				this.el.object3D.rotation.x = this.lookAngle;
			}

			if (this.data.turnEnabled && this.moveStarted && !this.isMobile) {
				this.turnAngle += this.rotatePercent.y * turnAmount;
				this.el.object3D.rotation.y = this.turnAngle;

				//this.moveStarted = false;
			}

			// enforce bounds on look angle (avoid upside-down perspective)
			if (this.data.maxLookEnabled) {
				if (this.lookAngle > maxLookAngle)
					this.lookAngle = maxLookAngle;
				if (this.lookAngle < -maxLookAngle)
					this.lookAngle = -maxLookAngle;
			}
		}

		// translations

		// this only works when rotation order = "YXZ"
		let finalTurnAngle = this.el.object3D.rotation.y;

		let c = Math.cos(finalTurnAngle);
		let s = Math.sin(finalTurnAngle);

		if (this.data.inputType == "keyboard") {
			// need to reset movePercent values
			//   when querying which keys are currently pressed
			this.movePercent.set(0, 0, 0)

			if (this.isKeyPressed(this.data.panUp) || this.isKeyPressed(this.data.moveForwardKey) || this.isKeyPressed(this.data.moveForwardArrow)){
				if(this.isMobile){
					this.movePercent.z += 0.8;
				} else {
					this.movePercent.z = 0.8;
				}
			}
			if (this.isKeyPressed(this.data.panDown) || this.isKeyPressed(this.data.moveBackwardKey) || this.isKeyPressed(this.data.moveBackwardArrow)){
				if(this.isMobile){
					this.movePercent.z -= 0.8;
				} else {
					this.movePercent.z -= 0.8;
				}
			}

			if (this.isKeyPressed(this.data.moveRightKey) || this.isKeyPressed(this.data.moveRightArrow))
				this.movePercent.x += 0.3;
			if (this.isKeyPressed(this.data.moveLeftKey) || this.isKeyPressed(this.data.moveLeftArrow))
				this.movePercent.x -= 0.3;

			if (this.data.flyEnabled) {
				if (this.isKeyPressed(this.data.moveUpKey))
					this.movePercent.y += 2;
				if (this.isKeyPressed(this.data.moveDownKey))
					this.movePercent.y -= 2;
			}
		}
		else // other, e.g. "joystick"
		{
			// assume this.movePercent values have been set/reset elsewhere (outside of this function)
		}

		// forward(z) direction: [ -s,  0, -c ]
		//   right(x) direction: [  c,  0, -s ]
		//      up(y) direction: [  0,  1,  0 ]
		// multiply each by (maximum) movement amount and percentages (how much to move in that direction)
		function clampMove(curr, last, len) {
			return last + clamp(curr - last, len);
		}
		const x = clampMove(this.movePercent.x, this.lastMovePercent.x, 0.05);
		const y = clampMove(this.movePercent.y, this.lastMovePercent.y, 0.05);
		const z = clampMove(this.movePercent.z, this.lastMovePercent.z, 0.05);
		this.lastMovePercent.set(x, y, z);
		this.moveVector.set(-s * z + c * x, 1 * y, -c * z - s * x).multiplyScalar(moveAmount);

		this.el.object3D.parent.position.add(this.moveVector);
	}
});


