<template>
  <a-entity class="colliders">
    <a-box
      class="wall"
      width="3"
      height="12"
      depth="3"
      visible="false"
      static-body
      rotation="0 0 0"
      position="0 0 4"
    ></a-box>
  </a-entity>
</template>
<script>
/*eslint-disable */

export default {
  name: "scene",
  components: {},
  watch: {},
  data: function() {
    return {

    };
  },
  props: ["name"],
  created: function() {},
  mounted: function() {},
  methods: {},
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
