
<template>
  <div>
    <RegisterationScene
      v-if="!registeredStatus"
      :background_img="!!jsonData ? jsonData.exhibition_background : null"
      :exhibition_status="!!jsonData ? jsonData.exhibition_status : false"
      :play="playGame"
      :isMobile="isMobile"
    />
    <div v-else class="scene">
      <LoadingAnim :validStatus="validStatus" :sceneLoading="sceneLoading" :isMobile="isMobile"
        :instructPressed="instructPressed" :exhibitionOpened="exhibitionOpened" @onInstruction="onInstruction()" />

      <PopupAlert :sceneLoading="sceneLoading" :exhibitionId="exhibitionId" />

      <div class="dialog">
        <Dialog v-if="dialog.open" :dialog="dialog" :accessData="accessData" @closeDialog="dialog.open = false"
          @openSubDialog="openSubDialog($event)" @moreLink="openMoreLink($event)" @openSocial="openSocial($event)"
          @phoneLink="phoneLink($event)" @whatsappLink="whatsappLink($event)" @brochuresLink="brochuresLink($event)"
          @subProductLink="subProductLink($event)" />
        <SubDialog v-if="subDialog.open" :subDialog="subDialog" :exhibitionId="exhibitionId" :accessData="accessData"
          @closeDialog="subDialog.open = false" @onSubDialogStatistic="onScreenStatistic($event)" />
        <Social v-if="social.open" :boothDataForSocial="boothDataForSocial" @closeDialog="social.open = false"
          @linkSocial="linkSocial($event)" />
        <!-- <p style="font-family: 'Assistant'; z-index: 99999; font-size: 2rem">
          You are invited to tour the exhibition with the mouse, to enter the pavilions.
        </p> -->
      </div>
      <a-scene background="color: #adc3e6" physics="debug: false" vr-mode-ui="enabled: false"
        loading-screen="enabled: false" device-orientation-permission-ui="enabled: false" class="scene"
        multitouch-look-controls>
        <a-assets timeout="1000" @loaded="assetsLoaded()">
          <a-asset-item id="booth" src="/dist/render-expo/glb_files/boothbaked.glb"></a-asset-item>
          <a-asset-item id="redGradient" src="/dist/render-expo/glb_files/redGradient.glb"></a-asset-item>
          <a-asset-item id="blueGradient" src="/dist/render-expo/glb_files/blueGradient.glb"></a-asset-item>
          <a-asset-item id="chair" src="/dist/render-expo/glb_files/stool.glb"></a-asset-item>
          <a-asset-item id="tableTop" src="/dist/render-expo/glb_files/table-low-poly-top.glb"></a-asset-item>
          <a-asset-item id="tableBottom" src="/dist/render-expo/glb_files/table-low-poly-bottom.glb"></a-asset-item>
          <a-asset-item id="stage" src="/dist/render-expo/glb_files/stage.glb"></a-asset-item>
          <img id="orange" src="/dist/render-expo/img/pin.png" crossorigin="anonymous" />

          <img id="blue" src="/dist/render-expo/img/pin-blue.png" crossorigin="anonymous" />
          <img id="avatar" src="/dist/render-expo/img/avatar.jpg" crossorigin="anonymous" />

          <canvas id="my_canvas"></canvas>
          <canvas id="canvas" crossorigin="anonymous"></canvas>
          <canvas id="canvas-brochure" crossorigin="anonymous"></canvas>
          <canvas id="brochure-text" crossorigin="anonymous"></canvas>

          <!-- <img
            id="imgGround"
            src="/dist/render-expo/img/parket-floor.jpg"
            crossorigin="anonymous"
          /> -->
          <img id="imgGround" :src="!!jsonData ? jsonData.floor_tile : ''" crossorigin="anonymous" />
          <img id="playBtn" src="/dist/render-expo/icons/play-btn.svg" crossorigin="anonymous" />
          <img id="secretary" src="/dist/render-expo/assets/scene/secretary.png" />
          <img id="parket" src="/dist/render-expo/assets/scene/parket-part.jpg" />
          <img id="curvedImg" src="/dist/render-expo/assets/genimages/booth_logo_test.jpg" crossorigin="anonymous" />
          <img id="wallImg1" src="/dist/render-expo/img/cola_ad.jpg" crossorigin="anonymous" />
          <img id="wallImg2" src="/dist/render-expo/img/vip_ad.jpg" crossorigin="anonymous" />
          <img id="wallImg3" src="/dist/render-expo/img/disc_ad.jpg" crossorigin="anonymous" />

          <span v-for="item in hallWallItems" :key="item.id * 23455.4" log="Hello, spanatios!">
            <canvas crossorigin="anonymous" :id="`canvas_hall_item_${item.id}`"></canvas>
            <img crossorigin="anonymous" width="16" height="9" :id="`hall_item_${item.id}`" :src="item.src" alt />
          </span>
          <span v-for="booth in booths" :key="booth.id">
            <span v-for="item in booth.wallItems" :key="item.id" log="Hello, spanatios!">
              <canvas crossorigin="anonymous" :id="`canvas_wall_item_${item.id}`"></canvas>
              <img crossorigin="anonymous" width="4" height="3" :id="`img_wall_item_${item.id}`"
                :src="item.data ? item.data.image : ''" alt />
            </span>
          </span>
          <img id="booth-logo" src="/dist/render-expo/assets/booth/oricon.png" />
          <img id="booth-secretary" src="/dist/render-expo/assets/booth/secretary.png" />
          <img id="booth-brochure" src="/dist/render-expo/assets/booth/brochure.jpg" />
        </a-assets>

        <a-entity class="collider-walls">
          <a-box rotation="0 0 0" position="-45 0 -149" width="133" height="10" static-body color="yellow" visible="false"
            depth="4"></a-box>
          <a-box rotation="0 180 0" position="-45 0 26" width="133" height="10" depth="4" static-body color="yellow"
            visible="false"></a-box>
          <a-box rotation="0 119 0" position="-121 0 8" width="46" height="10" static-body depth="4" color="yellow"
            visible="false"></a-box>
          <a-box rotation="0 61 0" position="-122 0 -129" width="46" height="10" static-body color="yellow"
            visible="false" depth="4"></a-box>
          <a-box rotation="0 90 0" position="-133 0 -60" width="100" height="10" static-body color="yellow"
            visible="false" depth="4"></a-box>
          <a-box rotation="0 -90 0" position="43 0 -60" width="100" height="10" static-body color="yellow" visible="false"
            depth="4"></a-box>
          <a-box rotation="0 -122 0" position="32.5 0 8" width="41" height="10" static-body color="yellow" visible="false"
            depth="4"></a-box>
          <a-box rotation="0 -62 0" position="33.5 0 -129" width="53" height="10" static-body color="yellow"
            visible="false" depth="4"></a-box>
          <a-box rotation="0 0 0" position="-45.3 0 -137.3" width="26" height="10" static-body color="yellow"
            visible="false" depth="11"></a-box>

          <a-box rotation="0 -50 0" position="-78.15 0 -124.18" width="13" height="10" static-body color="yellow"
            visible="false" depth="1.5"></a-box>
          <a-box rotation="0 -23 0" position="-59.5 0 -109.97" width="18" height="10" static-body color="yellow"
            visible="false" depth="1.5"></a-box>
          <a-box rotation="0 22 0" position="-31.1 0 -110.23" width="18" height="10" static-body color="yellow"
            visible="false" depth="1.5"></a-box>
          <a-box rotation="0 50 0" position="-13 0 -124" width="13" height="10" static-body color="yellow" visible="false"
            depth="1.5"></a-box>

          <a-box rotation="0 -50 0" position="-72 0 -129" width="10.5" height="10" static-body color="yellow"
            visible="false" depth="3"></a-box>
          <a-box rotation="0 -22 0" position="-56.5 0 -117.1" width="15" height="10" static-body color="yellow"
            visible="false" depth="3"></a-box>
          <a-box rotation="0 22 0" position="-33.8 0 -117.4" width="15" height="10" static-body color="yellow"
            visible="false" depth="3"></a-box>
          <a-box rotation="0 50 0" position="-19.18 0 -128.75" width="10.5" height="10" static-body color="yellow"
            visible="false" depth="3"></a-box>
        </a-entity>

        <a-entity v-if="loadingOrder.hall">
          <a-image v-if="conferenceStatus === 'on'" width="1580" height="360" scale="0.054 0.055 0.045"
            src="/dist/render-expo/hall-elements/conference.png" position="-45 15.75 -150">
          </a-image>
          <a-image class="conf rayClick" v-if="conferenceStatus === 'on'" position="-19 22.6 -149.9"
            src="/dist/render-expo/hall-elements/schedule.png" scale="6.3 1.7 1" @mousedown="imageDown()"
            @mouseup="onConfSchedule()">
          </a-image>
          <a-image class="conf rayClick" v-if="conferenceStatus === 'on'" position="-13 22.6 -149.9"
            src="/dist/render-expo/hall-elements/vod.png" scale="3.95 1.7 1" @mousedown="imageDown()"
            @mouseup="onVodClick()">
          </a-image>
          <a-image v-for="item in hallWallItems" :key="item.id"
            :canvas-pic-shadow="`canvasSrc: canvas_hall_item_${item.id}; imgSrc: hall_item_${item.id} ;type: ${item.type}; boothType: allBooth`"
            :material="`shader: flat; src: #canvas_hall_item_${item.id};`" scale="1.45 1.45 1.45" :position="item.pos"
            :rotation="item.rot" width="16" height="9" class="rayClick" @mousedown="imageDown()"
            @mouseup="onWallItems(item)"></a-image>
          <a-entity>
            <a-image v-for="item in hallWallItems" :id="`wall-item-${item.id}`" :key="item.id" :scale="item.imageScale"
              :position="item.imagePos" :rotation="item.rot" :src="item.src">
              <a-image v-if="item.type.includes('video')" :src="item.src" position="0 0 0.005">
                <a-image src="#playBtn" material="alphaTest: 0.48" position="0 0.04 0.01" scale="0.12 0.27 1">
                </a-image>
              </a-image>
            </a-image>
          </a-entity>
        </a-entity>
        
        <a-entity v-if="loadingOrder.hall">
          <a-entity v-for="n in 2" :key="n" gltf-model="/dist/render-expo/glb_files/frontDesk.glb" scale="1 1.2 1.3"
            :position="n === 1 ? '-80.5 0.9 0' : '-15 0.9 0'" :rotation="n === 1 ? '0 62.5 0' : '0 125 0'"
            @model-loaded="loadingOrder.frontDesk = true">
            <a-image v-if="counters[n - 1] && counters[n - 1].presentor" :src="counters[n - 1].presentor"
              class="secretary rayClick" position="1 0.6 1.5" rotation="0 -90 0" scale="1.5 3.3" transparent="true"
              material="alphaTest: 0.5" @mousedown="imageDown()" @mouseup="onAttendant(counters[n - 1].type)"
              look-at="[camera]"></a-image>
            <a-curvedimage v-if="counters[n - 1] && counters[n - 1].logo" :src="counters[n - 1].logo" height="1.4"
              radius="5.02" theta-length="180" theta-start="180" position="-0.21 0.01 1.5" rotation="0 -0 0"
              scale="0.27 1.3 -0.49" class="rayClick" @mousedown="imageDown()"
              @mouseup="onAttendant(counters[n - 1].type)"></a-curvedimage>
          </a-entity>
        </a-entity>

        <a-entity v-if="loadingOrder.floor" @model-loaded="loadingOrder.hall = true"
          class="gradient loadCount hall rayClick" gltf-model="/dist/render-expo/glb_files/ulamTest.glb"
          position="-90 8 -56.8" scale="1 1 1" @mousedown="menuOpen = false"
          :booth-strip-custom="`ceilingSrc: ${jsonData.floor_ceiling}; carpetSrc: ${jsonData.floor_carpets}; panoramaSrc: ${jsonData.floor_panorama}`">
        </a-entity>

        <a-entity v-if="loadingOrder.floor" gltf-model="/dist/render-expo/glb_files/stage.glb"
          position="-45.6 -0.4 -125" scale="4 4 4"></a-entity>

        <a-entity class="player" ref="player" id="rig" position="7.58 0 16.55" rotation="0 0 0" kinematic-body>
          <a-entity position="0 2.6 0" :custom-look-controls="`enabled:${!isLookControls}; reverseTouchDrag: true`"
            :extended-wasd-controls="`flyEnabled: false; turnEnabled: ${isLookControls}; maxLookAngle: 30;`"
            shape__main="shape: cylinder; " class="a-camera" id="camera" ref="camera" :camera="`fov:${fov}; near: 1; far: ${sceneLoading ? 0 : 250
              }; active: ${sceneLoading ? 'true' : 'false'}`" cursor="rayOrigin: mouse" raycaster="objects: .rayClick;">
          </a-entity>
        </a-entity>
        <a-entity v-if="isAssetsLoaded && occupants">
          <a-entity v-for="(user) in occupants" :key="user.id">
            <a-entity :class="user.id" scale="1.5 1.5 1.5" v-if="user.pos &&
              myUser !== user.id &&
              showPins &&
              exhibitionId === parseInt(user.session_id)
              " :animation="'property: position; to: ' +
    user.pos.x +
    ' 0.6 ' +
    user.pos.z +
    '; dur: 1000; easing: linear;'
    " look-at="[camera]">
              <PinName v-if="user.userInfo.full_name" :name="user.userInfo.full_name ? user.userInfo.full_name : ''"
                :index="user.id" />
              <a-image :src="`#${user.pinColor}`" scale="0.7 0.95 0.95" class="rayClick" material="alphaTest: 0.5"
                @mousedown="imageDown()" @mouseup="visitorClick(user)">
                <a-image
                  :src="user.userInfo.avatar_image.endsWith('undefined') || user.userInfo.avatar_image === undefined ? '/dist/render-expo/img/avatar.jpg' : user.userInfo.avatar_image"
                  position="0 0.13 -0.001" scale="0.66 0.528 1">
                </a-image>
              </a-image>
            </a-entity>
          </a-entity>
        </a-entity>
        <a-entity light="type: ambient;"></a-entity>
        <a-plane v-if="jsonData != null" class="floor rayClick" rotation="-90 0 0" position="-20 -0.2 -70" visible="true"
          width="250" height="250" material="src:#imgGround; repeat: 40 40" static-body
          @loaded="loadingOrder.floor = true" @mousedown="menuOpen = false"></a-plane>
        <!-- color="#1F314F" -->
        <a-entity class="booths">
          <a-entity v-for="(booth, index) in booths" :key="booth.id">
            <a-entity :class="`booth-wrap`" v-show="sceneLoading">
              <BoothA class="booth" :id="booth.id" :rotation="booth.rotation" :position="booth.position"
                :wallTemplate="booth.itemAmount" :farFromCam="false" :items="booth.wallItems" :boothType="'allBooth'"
                :gradientColor="booth.gradientColor" :stripColor="booth.stripColor" :jsonData="booth.boothData"
                @itemLoad="itemLoad" @imageDown="imageDown" @imageClick="imageClick"
                @openSubDialog="openSubDialog($event)" @phoneLink="phoneLink($event)" @whatsappLink="whatsappLink($event)"
                @openSocial="openSocial($event)" @brochureClick="brochureClick($event)" @infoCompany="infoCompany($event)"
                v-if="booth.type === 'a' && booth.itemAmount === wallTemplate" :cullBooth="booth.cullBooth"
                :tableScale="tableScale" :chairScale="chairScale" :boothNumber="index" :sceneLoading="sceneLoading" />
            </a-entity>
          </a-entity>
        </a-entity>
      </a-scene>
      <span v-if="myUserDetails">
        <Chat style="z-index: 4" v-if="myUserDetails.chat.requestChat ||
          myUserDetails.chat.confirmChat ||
          myUserDetails.chat.inChat ||
          myUserDetails.chat.canceledChat ||
          myUserDetails.chat.otherCancled ||
          myUserDetails.chat.awaitConfirmChat
          " :myUserDetails="myUserDetails" :selectedUser="!!selectedUser ? selectedUser : null" :socket="socket" />
      </span>
      <!-- <Controls @playerPos="playerPosition($event)" :socket="socket" /> -->
      <OnScreen v-if="loadingOrder.booths" :telePositions="telePositions" :myUserDetails="myUserDetails"
        :occupants="occupants" :myUser="myUser" :menuData="jsonData.menu" :menuOpen="menuOpen"
        :exhibitionId="exhibitionId" :logo="jsonData.exhibition_logo" :exhibitionName="jsonData.exhibition_name"
        :jsonData="jsonData" ref="onScreenRef" @hideusers="showPins = !showPins"
        @togglelc="isLookControls = !isLookControls" @playerPosition="playerPosition($event)"
        @helpInstruction="instructPressed = false" @gotoBooth="gotoBooth($event)"
        @onScreenStatistic="onScreenStatistic($event)" />
    </div>
  </div>
</template>
<script>
/*eslint-disable */

export default {
  name: "scene",
  components: {
    BoothA,
    PinName,
    Chat,
    LoadingAnim,
    PopupAlert,
    Dialog,
    SubDialog,
    Social,
    Colliders,
    OnScreen,
    RegisterationScene,
  },
  methods: {
    playGame: function () {
      this.registeredStatus = true;
    },
    onInstruction: function () {
      this.instructPressed = true;

      this.isMobile = AFRAME.utils.device.isMobile();
      if (
        this.isMobile &&
        window.matchMedia("(orientation: landscape)").matches
      ) {
        this.$refs.onScreenRef.toggleFullScreen();
      }
    },
    openSubDialog: function (event) {
      let dialog = this.subDialog;
      dialog.open = true;
      dialog.type = event.type;
      dialog.data = event.info;
      dialog.from = event.from;
      dialog.booth_id = event.booth_id;
    },
    openSocial: function (event) {
      let dialog = this.social;
      dialog.open = true;
      this.boothDataForSocial = event;
    },
    linkSocial: function (event) {
      let type;
      let booth_id = event.booth_id;
      let description = event.description ? event.description : "";

      if (event.type == "booth") {
        type = "Social booth sharing";
      } else if (event.type.includes("companyInfo")) {
        type = "Social booth sharing - counter + attendant poppup";
      } else if (event.type === "schedule") {
        type = "Conference schedule Social sharing";
      } else if (event.type.includes("right counter")) {
        type = "Social sharing exhibition - right counter + attendant poppup";
      } else if (event.type.includes("left counter")) {
        type = "Social sharing exhibition - left counter + attendant poppup";
      } else {
        type = "Social sharing exhibit";
      }
      this.onStatistic(type, booth_id, description);
    },
    phoneLink: function (event) {
      let type;
      let booth_id = event.booth_id;
      let description = "";

      if (event.type == "booth") {
        type = "Booth phone";
      } else if (event.type.includes("right counter")) {
        type = "phone -exhibition right counter + attendant popup";
      } else if (event.type.includes("left counter")) {
        type = "phone -exhibition left counter + attendant popup";
      } else if (event.type === "schedule") {
        type = "Conference schedule phone";
      } else if (event.type.includes("companyInfo")) {
        type = "Phone booth - counter + attendant popup";
      } else {
        type = "Exhibit phone";
        description = event.description;
      }
      this.onStatistic(type, booth_id, description);
    },
    whatsappLink: function (event) {
      let whatappNumber, text;
      if (event.data.contact) {
        whatappNumber = event.data.contact.whatsapp;
        text = event.data.name;
      } else {
        whatappNumber = event.data.exhibition_contact.whatsapp;
        text = event.data.exhibition_name;
      }
      // window.open(`https://api.whatsapp.com/send?phone=${whatappNumber}&text=${encodeURIComponent(text)}`)
      window.open(`https://wa.me/${whatappNumber}?text=${text}`, "_blank");

      let type;
      let booth_id = event.booth_id;
      let description = "";
      if (event.type == "booth") {
        type = "WhatsApp booth";
      } else if (event.type.includes("right counter")) {
        type = "WhatsApp exhibition - right counter + attendant poppup";
        booth_id = "";
      } else if (event.type.includes("left counter")) {
        type = "WhatsApp exhibition - left counter + attendant poppup";
        booth_id = "";
      } else if (event.type.includes("companyInfo")) {
        type = "WhatsApp booth - counter + attendant poppup";
      } else if (event.type == "schedule") {
        type = "Conference schedule WhatsApp";
      } else {
        type = "WhatsApp exhibit";
        description = event.description;
      }
      this.onStatistic(type, booth_id, description);
    },
    brochuresLink: function (event) {
      this.onStatistic("Brochures window", event.booth_id, event.description);
    },
    subProductLink: function (event) {
      this.onStatistic(
        "Exhibit Sub-product",
        event.booth_id,
        event.description
      );
    },
    assetsLoaded: function (event) {
      this.isAssetsLoaded = true;
    },
    imageDown: function () {
      this.isImageClick = true;
      setTimeout(() => {
        this.isImageClick = false;
      }, 500);
    },
    imageClick: function (res) {
      console.log("image click", res);
      if (this.isImageClick) {
        if (res.data && res.data.type === "external") {
          this.openMoreLink(res.data.url);
        } else {
          let dialog = this.dialog;
          dialog.open = true;
          dialog.brochure = false;
          dialog.data = res;

          this.isImageClick = false;
        }

        if (res.size) {
          // when this is exhibit of booth
          this.onStatistic(
            "Exhibit in the booth",
            res.boothInfo.booth_id,
            res.data.name
          );
        }
      }
    },
    onAttendant: function (type) {
      this.onStatistic(`Exhibition - ${type} counter + attendant`);
      this.infoExhibition({
        type: `${type} counter`,
        data: this.jsonData,
      });
    },
    infoExhibition: function (res) {
      // for exhibition
      let imageSRC = res.data ? res.data.exhibition_logo : "";
      this.imageClick({
        data: {
          type: `exhibitionInfo_${res.type}`,
          image: imageSRC,
          companyInfo: true,
        },
        boothInfo: res.data,
      });
    },
    infoCompany: function (res) {
      // for only booth
      let imageSRC;
      if (res.data) {
        if (res.type == "topLogo") {
          imageSRC = res.data.style.logo;
          this.onStatistic("Top logo booth", res.data.booth_id);
        } else if (res.type == "backLogo") {
          imageSRC = res.data.style.logo;
          this.onStatistic("Side logo booth", res.data.booth_id);
        } else if (res.type == "frontLogo") {
          imageSRC = res.data.style.frontdesk;
          this.onStatistic(
            "Counter in the booth + attendant",
            res.data.booth_id
          );
        } else if (res.type == "booth_attendant") {
          imageSRC = res.data.style.logo;
          this.onStatistic(
            "Counter in the booth + attendant",
            res.data.booth_id
          );
        }
      } else {
        imageSRC = "/dist/render-expo/assets/booth/oricon.png";
      }
      this.imageClick({
        data: {
          type: `companyInfo_${res.type}`,
          image: imageSRC,
          companyInfo: true,
        },
        boothInfo: res.data,
        id: res.booth_id,
      });
    },
    brochureClick: function (res) {
      this.onStatistic(
        "Brochure on the table",
        res.data.booth_id,
        res.data.name
      );
      this.imageClick({
        data: {
          type: "brochure",
          brochure: true,
        },
        boothInfo: res.data,
      });
    },

    openMoreLink: function (res) {
      if (res) window.open(res, "_blank");
    },

    visitorClick: function (user) {
      if (this.isImageClick) {
        this.selectedUser = user;
        // this.socket.emit("requestChat", user);
      }
    },

    onConfSchedule: function () {
      if (this.isImageClick) {
        this.onStatistic("Conference schedule");
        this.imageClick({
          data: {
            type: "schedule",
            description: !!this.jsonData
              ? this.jsonData.conference.schedule.description
              : "",
          },
          boothInfo: this.jsonData,
        });
      }
    },
    onVodClick: function () {
      if (this.isImageClick) {
        alert('coming soon');
      }
    },
    onScreenStatistic: function (res) {
      this.onStatistic(res.type, res.boothNum, res.description);
    },
    onStatistic: function (type, booth_id, description) {
      let data = {
        exhibition_id: this.exhibitionId,
        booth_id: booth_id ? booth_id : "",
        user_id: this.userId,
        type: type ? type : "",
        description: description ? JSON.stringify(description) : "",
      };

      // axios
      //   .post("https://admin.exponeto.com/api/statistics/event", data)
      //   .then((response) => {
      //   })
      //   .catch((error) => {
      //     console.log("statisitic error", error);
      //   });
    },
    teleport: function (res) {
      if (this.isImageClick) {
        let cam = document.querySelector(".a-camera");
        let player = document.querySelector(".player");

        player.setAttribute(
          "animation",
          "property: position; to: -10 0 -30.0" +
          `${Math.random()}`.split(".").join("") +
          "; dur: 1000;"
        );

        this.isLookControls = !this.isLookControls;

        cam.setAttribute(
          "animation",
          `property: rotation; to: 0 -9${Math.random()} 0; easing:linear;`
        );
        cam.components["custom-look-controls"].yawObject.rotation.y = -1.55;

        this.isLookControls = !this.isLookControls;
        this.isImageClick = false;
      }
    },
    onWallItems: function (item) {
      if (this.isImageClick) {
        if (item.type.includes("video")) {
          this.imageClick({
            data: {
              type: "wall_video",
              url: item.url,
              companyInfo: true,
            },
            boothInfo: this.jsonData,
          });
        } else if (item.type.includes("external-link")) {
          this.openMoreLink(item.url);
        } else {
          let boothNum = item.url;
          let booth_id = this.jsonData.booths[boothNum].booth_id;
          this.gotoBooth(boothNum);
          this.onStatistic("Entrance to the booth via billboard", booth_id);
        }
        this.onStatistic("Billboard", "Billboard", item.id);
        this.isImageClick = false;
      }
    },

    gotoBooth: function (boothNum) {
      if (boothNum) {
        let cam = document.querySelector(".a-camera");
        let player = document.querySelector(".player");
        let x, z;
        if (this.telePositions[boothNum - 1].rotation === "0 180 0") {
          x = this.telePositions[boothNum - 1].position.x + 14;
          z = this.telePositions[boothNum - 1].position.z - 2;

          cam.components["custom-look-controls"].yawTarget = 1.5;
          cam.components["custom-look-controls"].yawObject.rotation.y = 1.5;
        } else {
          x = this.telePositions[boothNum - 1].position.x - 14;
          z = this.telePositions[boothNum - 1].position.z + 2;
          cam.components["custom-look-controls"].yawTarget = -1.6;
          cam.components["custom-look-controls"].yawObject.rotation.y = -1.6;
        }
        player.setAttribute("position", `${x} 0 ${z}`);

        this.playerPosition(player.object3D.position);
      }
    },
    imgWallRotation: function (wall) {
      let rotation;
      if (wall === 1) {
      }
      return rotation;
    },
    getPlayerPos: function (res) {
      let player = document.querySelector(".player");
    },
    loaded: function (res) {
      console.log(res);
      this.objectLoading = true;
    },
    itemLoad: function (pos) {
      if (this.objectAmountForLoad > this.objectsLoaded + 2) {
        // if (this.objectsLoaded != 0) {
        this.objectsLoaded++;
      } else {
        // this.objectsLoaded++;
        console.log('========render finish=========' + new Date());

        this.playerPosition({ x: 7.58, y: 0, z: 16.55 });
        document.querySelector(".a-camera").components[
          "custom-look-controls"
        ].yawObject.rotation.y = 1.047;

        if (this.exhibitionOpened === "On") {
          this.sceneLoading = false;

          console.log(localStorage.getItem("scenefirstloading"));
          if (localStorage.getItem("scenefirstloading") === null) {
            // window.location.reload();
            localStorage.setItem("scenefirstloading", true);
          } else {
            // this.$el.querySelector(".loadingBar").style.display = "none";
          }
          try {
            this.$el.querySelector(".loadingBar").style.display = "none";
          } catch (error) {

          }
        }

        this.onStatistic("Entrance to the exhibition");

        // this.socket = io("https://www.play.detherverse.com:3000", {
        //   query: {
        //     session_id: this.exhibitionId,
        //     userInfo: JSON.stringify(this.accessData),
        //   },
        // });

        // this.socket = io("http://localhost:3000", {
        //   query: {
        //       session_id: this.exhibitionId,
        //       userInfo:  JSON.stringify(this.accessData),
        //   },
        // });

        // this.socket.on("myUser", (data) => {
        //   this.myUser = data;
        // });
        // this.socket.on("myUserInChat", (data) => {
        //   // alert("currently in the middle of a chat");
        // });
        // this.socket.on("otherUserInChat", (data) => {
        //   alert("Currently in a middle of a chat. Try later");
        // });
        // this.socket.on("occupants", (data) => {
        //   this.occupants = data;
        //   this.myUserDetails = data.find(({ id }) => id === this.myUser);
        // });
      }
      // determine the territory of the booth
      // let _distanceWidth = document.getElementById('0').object3D.position.distanceTo(document.getElementById('1').object3D.position); // distance(width) between two booths
      // let _heightFloor = document.querySelector('.depth-repeats').components.rounded.data.height; // the height of the one booth.
      // let _distanceHeight = document.getElementById('8').object3D.position.distanceTo(document.getElementById('9').object3D.position); // distance(height) between two booths

      // let _width = _distanceWidth;
      // let _height = (_distanceHeight + _heightFloor) * 0.5;

      // let _territoryBooth = _width * _height;
      // console.log('territroy of the booth is ', _territoryBooth)
    },
    playerPosition: function (pos) {
      let x = pos.x;
      let z = pos.z;
      this.playerPos = `x:${x} z:${z}`;
    },
    orientation: function (index) { },
    getCameraVirtualPoint: function (radius) {
      let player = document.querySelector(".player");
      let playerPos = player.object3D.position;
      let cam = document.querySelector(".a-camera");
      let camPos = cam.object3D.position;

      let cameraLocalPos = cam.object3D.worldToLocal(
        new THREE.Vector3(camPos.x, camPos.y, camPos.z)
      );
      let cameraVirtualWorldPos = cam.object3D.localToWorld(
        new THREE.Vector3(
          cameraLocalPos.x,
          cameraLocalPos.y,
          cameraLocalPos.z - radius
        )
      );
      let virtualPos = new THREE.Vector3(
        cameraVirtualWorldPos.x + playerPos.x,
        0,
        cameraVirtualWorldPos.z + playerPos.z
      ); // virtual point for radius

      return virtualPos;
    },
    showBooth: function (playerPos, virtualPos1, virtualPos2) {
      let allBooths = this.getAllBooths;
      let currentShowBoothGroup = [];

      let _radius1 = this.virtualRadius1;
      let _radius2 = this.virtualRadius2;

      if (playerPos.x > -140 && playerPos.x < -104) {
        boothVisible(
          this.boothShowCase.back_first,
          this.boothShowCase.front_first,
          16,
          32
        );
      } else if (playerPos.x >= -104 && playerPos.x < -66) {
        boothVisible(
          this.boothShowCase.back_second,
          this.boothShowCase.front_second,
          12,
          32
        );
      } else if (playerPos.x >= -66 && playerPos.x < -28) {
        boothVisible(
          this.boothShowCase.back_third,
          this.boothShowCase.front_third,
          4,
          28
        );
      } else if (playerPos.x >= -28 && playerPos.x < 41) {
        boothVisible(
          this.boothShowCase.back_fourth,
          this.boothShowCase.front_fourth,
          0,
          20
        );
      } else if (playerPos.x >= 10 && playerPos.x < 50) {
        boothVisible(
          this.boothShowCase.back_fifth,
          this.boothShowCase.front_fifth,
          0,
          16
        );
      }

      function boothVisible(_backCase, _frontCase, _num1, _num2) {
        if (playerPos.z < 25 && playerPos.z > -5) {
          for (var i = 0; i < _backCase.length; i++) {
            if (
              allBooths[_backCase[i]] &&
              !currentShowBoothGroup.includes(allBooths[_backCase[i]])
            ) {
              if (
                virtualPos1.distanceTo(
                  allBooths[_backCase[i]].getAttribute("position")
                ) < _radius1 ||
                virtualPos2.distanceTo(
                  allBooths[_backCase[i]].getAttribute("position")
                ) < _radius2
              ) {
                currentShowBoothGroup.push(allBooths[_backCase[i]]);
              }
            }
          }
        } else if (playerPos.z < -98 && playerPos.z > -147) {
          for (var i = 0; i < _frontCase.length; i++) {
            if (
              allBooths[_frontCase[i]] &&
              !currentShowBoothGroup.includes(allBooths[_frontCase[i]])
            ) {
              if (
                virtualPos1.distanceTo(
                  allBooths[_frontCase[i]].getAttribute("position")
                ) < _radius1 ||
                virtualPos2.distanceTo(
                  allBooths[_frontCase[i]].getAttribute("position")
                ) < _radius2
              ) {
                currentShowBoothGroup.push(allBooths[_frontCase[i]]);
              }
            }
          }
        } else {
          for (var i = _num1; i < _num2; i++) {
            if (allBooths[i] && !currentShowBoothGroup.includes(allBooths[i])) {
              if (
                virtualPos1.distanceTo(allBooths[i].getAttribute("position")) <
                _radius1 ||
                virtualPos2.distanceTo(allBooths[i].getAttribute("position")) <
                _radius2
              ) {
                currentShowBoothGroup.push(allBooths[i]);
              }
            }
          }
        }
      }

      if (!arrayEquals(this.showBoothGroup, currentShowBoothGroup)) {
        for (var i = 0; i < currentShowBoothGroup.length; i++) {
          if (!currentShowBoothGroup[i].getAttribute("visible")) {
            currentShowBoothGroup[i].setAttribute("visible", true);
          }

          if (i == 2 && !this.loadingOrder.booths) {
            this.loadingOrder.booths = true;
          }
        }

        this.showBoothGroup.forEach((element) => {
          if (!currentShowBoothGroup.includes(element)) {
            element.setAttribute("visible", false);
          }
        });

        this.showBoothGroup = [];
        this.showBoothGroup = currentShowBoothGroup;
      }

      function arrayEquals(a, b) {
        a.sort();
        b.sort();
        return (
          Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index])
        );
      }
    },
    getExhibition: async function (params) {
      return new Promise((resolve, reject) => {
        // axios
        //   .get(`https://admin.exponeto.com/api/exhibition/${params}`)
        //   .then((res) => {
        //     resolve(res.data);
        //   })
        //   .catch((err) => {
        //     console.log("Error", err.response);
        //     this.validStatus = false;
        //     alert("Can not find exhibition");
        //   });
        resolve(require("../assets/demo-online-exhibiion.json"));
      });
    },
    getUserInfo: async function (exhibition_id, params) {
      let data = {
        exhibition_id: exhibition_id,
        email: params.email,
        access_code: params.access,
      };
      let self = this;
      return new Promise((resolve, reject) => {
        axios
          .post(`https://admin.exponeto.com/api/visitor/access`, data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            console.log("hello error 400");
            console.log("Error", err);
            self.$router.push({ path: "/exhibition/technomotor-demo2-13" });
            window.location.reload();
          });
      });
    },
  },
  data: function () {
    return {
      isLookControls: false,
      telePositions: [],
      positionIndex: [],
      showPins: true,
      fov: 30,
      cullRadius: 40,
      virtualRadius1: 50,
      virtualRadius2: 40,
      chairScale: { x: 0.13, y: 0.15, z: 0.13 },
      tableScale: { x: 0.005, y: 0.0065, z: 0.005 },
      isAssetsLoaded: false,
      dialog: { open: false, brochure: false, data: {} },
      subDialog: { open: false, type: "", from: "", booth_id: "", data: {} },
      social: { open: false, type: "", data: {} },
      hallWallItems: [],
      counters: [],
      conferenceStatus: false,
      gObjectsForLoad: [],
      objectsLoaded: 0,
      sceneLoading: true,
      exhibitionOpened: "undefined",
      instructPressed: false,
      loadingOrder: {
        floor: false,
        hall: false,
        frontDesk: false,
        boothImages: false,
        hallImages: false,
        booths: false,
      },
      isMounted: false,
      isMobile: false,
      isRotating: false,
      isImageClick: false,
      eventIteration: 14.8,
      iterCount: 0,
      wallTemplate: 8,
      socket: {},
      occupants: [],
      selectedUser: null,
      messages: [],
      myUser: "",
      myUserDetails: {
        id: "",
        interactions: [],
        pos: "",
        chat: {
          messages: [],
          requestChat: false,
          confirmChat: false,
          inChat: false,
          canceledChat: false,
          otherCancled: false,
          chattingWith: "",
        },
      },
      playerPos: "0 3 0",
      showBoothGroup: [],
      boothShowCase: {
        back_first: [7, 8, 15, 16, 23, 24, 31, 14, 17, 22, 25, 30, 28, 29],
        back_second: [
          7, 8, 15, 16, 23, 24, 31, 14, 17, 22, 25, 30, 20, 21, 26, 27,
        ],
        back_third: [
          7, 8, 15, 16, 23, 24, 6, 9, 14, 17, 22, 25, 12, 13, 18, 19,
        ],
        back_fourth: [0, 7, 8, 15, 16, 23, 24, 1, 6, 9, 14, 17, 4, 5, 10, 11],
        back_fifth: [0, 7, 8, 15, 16, 23, 24, 1, 6, 9, 14, 17, 2, 3, 5, 10],
        front_first: [4, 11, 12, 19, 20, 27, 28, 13, 18, 21, 26, 29, 30, 31],
        front_second: [
          4, 11, 12, 19, 20, 27, 28, 13, 18, 21, 26, 29, 22, 23, 24, 25,
        ],
        front_third: [
          4, 11, 12, 19, 20, 27, 5, 10, 13, 18, 21, 26, 14, 15, 16, 17,
        ],
        front_fourth: [3, 4, 11, 12, 19, 20, 27, 2, 5, 10, 13, 18, 6, 7, 8, 9],
        front_fifth: [3, 4, 11, 12, 19, 20, 27, 2, 5, 10, 0, 1, 8, 9],
      },
      cameraVirtualPosition: { x: 0, y: 0, z: 0 },
      jsonData: null,
      registeredStatus: false,
      validStatus: true,
      menuOpen: false
    };
  },

  mounted: async function () { },
  created: async function () {
    console.log('========loading start=========' + new Date());
    let scene = document.querySelector("a-scene");
    let cam = document.querySelector(".a-camera");
    this.cullingIterval = setInterval(
      function () {
        if (!this.sceneLoading) {
          let player = document.querySelector(".player");
          if (player) {
            let playerPos = player.object3D.position;
            if (playerPos.y < -1) {
              playerPos.y = -0.10886911985882318;
            }
            this.playerPosition(playerPos);
            // if (this.socket.emit) {
            //   this.socket.emit("move", playerPos);
            // }

            // console.log('Now player position is', playerPos)
            // console.log('Now player rotation is',  document.querySelector(".a-camera").components["custom-look-controls"].yawObject.rotation.y)

            let virtualPos1 = this.getCameraVirtualPoint(this.cullRadius);
            let virtualPos2 = this.getCameraVirtualPoint(this.cullRadius * 2);

            if (virtualPos1.distanceTo(this.cameraVirtualPosition) != 0) {
              this.showBooth(playerPos, virtualPos1, virtualPos2);
              this.cameraVirtualPosition = virtualPos1;
            }
          }
        }
      }.bind(this),
      1000
    );

    //setInterval(() => {}, 5000);

    this.isMounted = true;
    this.isMobile = AFRAME.utils.device.isMobile();
    this.exhibitionId = this.$route.params.id;
    this.exhibition_id = this.$route.params.id;
    this.accessQuery = this.$route.query;
    if (this.accessQuery.email && this.accessQuery.access) {
      this.accessData = await this.getUserInfo(
        this.exhibition_id,
        this.accessQuery
      );
      this.registeredStatus = true;
      this.userId = this.accessData.id;
      this.exhibitionId = this.accessData.exhibition_id;
      console.log("userInfo from the access_code directly", this.accessData);
    }

    this.jsonData = await this.getExhibition(this.exhibition_id);

    // this is written by comet
    // this.jsonData.exhibition_status = "Off";

    if (this.jsonData.exhibition_status !== "On") {
      this.exhibitionOpened = "Off";
      // this.sceneLoading = false;
    } else {
      this.exhibitionOpened = "On";
    }
    if (!!this.jsonData) {
      this.hallWallItems = [
        {
          id: 1,
          pos: "-20 15.7 27.5",
          wall: 1,
          rot: "0 180 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[0]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[0]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[0]
          ].url,
          imagePos: "-20.18 15.99 27.49",
          imageScale: "20.3 9.95 1",
        },
        {
          id: 2,
          pos: "44 15.7 -37",
          wall: 1,
          rot: "0 270 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[1]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[1]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[1]
          ].url,
          imagePos: "43.99 15.99 -36.84",
          imageScale: "20.25 10 1",
        },
        {
          id: 3,
          pos: "44 15.7 -62",
          wall: 1,
          rot: "0 270 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[2]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[2]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[2]
          ].url,
          imagePos: "43.99 15.99 -61.85",
          imageScale: "20.25 10 1",
        },
        {
          id: 4,
          pos: "44 15.7 -87",
          wall: 3,
          rot: "0 270 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[3]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[3]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[3]
          ].url,
          imagePos: "43.99 15.99 -86.84",
          imageScale: "20.25 10 1",
        },
        {
          id: 5,
          pos: "-20.5 15.7 -149.9",
          wall: 3,
          rot: "0 0 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[4]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[4]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[4]
          ].url,
          imagePos: "-20.355 15.99 -149.89",
          imageScale: "20.25 9.92 1",
        },
        {
          id: 6,
          pos: "-45.5 15.7 -149.9",
          wall: 3,
          rot: "0 0 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[5]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[5]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[5]
          ].url,
          imagePos: "-45.34 15.99 -149.89",
          imageScale: "20.25 9.92 1",
        },
        {
          id: 7,
          pos: "-70.65 15.7 -149.9",
          wall: 2,
          rot: "0 0 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[6]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[6]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[6]
          ].url,
          imagePos: "-70.5 15.99 -149.89",
          imageScale: "20.25 9.92 1",
        },
        {
          id: 8,
          pos: "-134 15.7 -85",
          wall: 2,
          rot: "0 90 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[7]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[7]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[7]
          ].url,
          imagePos: "-133.97 15.99 -85.15",
          imageScale: "20.25 9.92 1",
        },
        {
          id: 9,
          pos: "-134 15.7 -60",
          wall: 2,
          rot: "0 90 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[8]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[8]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[8]
          ].url,
          imagePos: "-133.97 15.99 -60.17",
          imageScale: "20.25 9.92 1",
        },
        {
          id: 10,
          pos: "-134 15.7 -35",
          wall: 4,
          rot: "0 90 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[9]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[9]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[9]
          ].url,
          imagePos: "-133.97 15.99 -35.16",
          imageScale: "20.25 9.92 1",
        },
        {
          id: 11,
          pos: "-70 15.7 27.5",
          wall: 4,
          rot: "0 180 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[10]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[10]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[10]
          ].url,
          imagePos: "-70.14 15.99 27.49",
          imageScale: "20.3 9.95 1",
        },
        {
          id: 12,
          pos: "-45 15.7 27.5",
          wall: 4,
          rot: "0 180 0",
          src: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[11]
          ].image,
          type: `wall_item_${this.jsonData.wall_items[Object.keys(this.jsonData.wall_items)[11]]
            .type
            }`,
          url: this.jsonData.wall_items[
            Object.keys(this.jsonData.wall_items)[11]
          ].url,
          imagePos: "-45.16 15.99 27.49",
          imageScale: "20.3 9.95 1",
        },
      ];

      this.counters.push(
        this.jsonData.counters.left,
        this.jsonData.counters.right
      );

      this.conferenceStatus = this.jsonData.conference.status;
    }
    console.log('========api call and setup finish=========' + new Date());
  },

  computed: {
    getAllBooths: function () {
      let booths = document.querySelectorAll(".booth");
      return booths;
    },

    booths: function () {
      let booths = [];
      if (this.jsonData) {
        let pos = { x: 14, y: 0, z: 6 };
        let rot = "0 180 0";
        let iter = -1;
        let rotIter = 0;
        const booth_num = window.location.host === "localhost:8080" ? 32 : 32;

        for (let i = 1; i <= booth_num; i++) {
          let A1, A2, A4, A8;
          let stripColor;
          let boothType;
          let boothData;
          if (
            this.jsonData.booths[i] &&
            this.jsonData.booths[i].exhibits.length !== 0
          ) {
            let A8 = [
              {
                id: `10${i}0_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[0]
                  ? this.jsonData.booths[i].exhibits[0]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "s1",
                pos: "1.01 3.15 -2.385",
                rot: "0 -24.75 0",
                imageScale: "3.66 2.69 1",
                imagePos: "1.03 3.21 -2.37",
                videoScale: "0.22 0.27 0.2",
                geo: { w: 4.2, h: 3.5 },
              },

              {
                id: `10${i}1_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[1]
                  ? this.jsonData.booths[i].exhibits[1]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "s",
                pos: "3.6 3.807 0.2",
                rot: "0 -90 0",
                imageScale: "1.66 1.11 1.0",
                imagePos: "3.59 3.96 0.215",
                videoScale: "0.18 0.27 0.2",
                geo: { w: 1.9, h: 1.65 },
              },
              {
                id: `10${i}2_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[2]
                  ? this.jsonData.booths[i].exhibits[2]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "s",
                pos: "3.6 3.807 2.1",
                rot: "0 -90 0",
                imageScale: "1.66 1.11 1.0",
                imagePos: "3.59 3.96 2.115",
                videoScale: "0.18 0.27 0.2",
                geo: { w: 1.9, h: 1.65 },
              },
              {
                id: `10${i}3_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[3]
                  ? this.jsonData.booths[i].exhibits[3]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "s",
                pos: "3.6 3.807 4",
                rot: "0 -90 0",
                imageScale: "1.66 1.11 1.0",
                imagePos: "3.59 3.96 4.015",
                videoScale: "0.18 0.27 0.2",
                geo: { w: 1.9, h: 1.65 },
              },
              {
                id: `10${i}4_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[4]
                  ? this.jsonData.booths[i].exhibits[4]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "s",
                pos: "3.6 2.185 0.2",
                rot: "0 -90 0",
                imageScale: "1.66 1.11 1.0",
                imagePos: "3.59 2.34 0.215",
                videoScale: "0.18 0.27 0.2",
                geo: { w: 1.9, h: 1.65 },
              },
              {
                id: `10${i}5_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[5]
                  ? this.jsonData.booths[i].exhibits[5]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "s",
                pos: "3.6 2.185 2.1",
                rot: "0 -90 0",
                imageScale: "1.66 1.11 1.0",
                imagePos: "3.59 2.34 2.115",
                videoScale: "0.18 0.27 0.2",
                geo: { w: 1.9, h: 1.65 },
              },
              {
                id: `10${i}6_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[6]
                  ? this.jsonData.booths[i].exhibits[6]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "s",
                pos: "3.6 2.185 4",
                rot: "0 -90 0",
                imageScale: "1.66 1.11 1.0",
                imagePos: "3.59 2.34 4.015",
                videoScale: "0.18 0.27 0.2",
                geo: { w: 1.9, h: 1.65 },
              },
              {
                id: `10${i}7_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[7]
                  ? this.jsonData.booths[i].exhibits[7]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "x",
                pos: "3.6 3.07 7",
                rot: "0 -90 0",
                imageScale: "3.65 2.71 1.0",
                imagePos: "3.59 3.145 7.03",
                videoScale: "0.19 0.27 0.2",
                geo: { w: 4.2, h: 3.5 },
              },
            ];
            let A2 = [
              {
                id: `10${i}10_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[0]
                  ? this.jsonData.booths[i].exhibits[0]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "m",
                pos: "1.05 3.18 -2.37",
                rot: "0 -24.75 0",
                imageScale: "3.65, 2.81 1.0",
                imagePos: "1.07 3.266 -2.36",
                videoScale: "0.22 0.27 0.2",
                geo: { w: 4.2, h: 3.7 },
              },
              {
                id: `10${i}9_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[1]
                  ? this.jsonData.booths[i].exhibits[1]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "xl",
                pos: "3.6 3.1 4.1",
                rot: "0 -90 0",
                imageScale: "9.39 2.9 1.0",
                imagePos: "3.59 3.305 4.12",
                videoScale: "0.08 0.27 0.2",
                geo: { w: 9.63, h: 3.5 },
              },
            ];
            let A1 = [
              {
                id: `10${i}11_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[0]
                  ? this.jsonData.booths[i].exhibits[0]
                  : "",
                boothInfo: this.jsonData.booths[i],
                type: "singleImage",
                size: "xl",
                pos: "3.5 3.1 3.5",
                rot: "0 -90 0",
                geo: { w: 11, h: 3.5 },
              },
              {
                id: `10${i}12_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[0]
                  ? this.jsonData.booths[i].exhibits[0]
                  : "",
                boothInfo: this.jsonData.booths[i],
                type: "singleImage",
                size: "xl",
                pos: "1.11 3.1 -2.17",
                rot: "0 -24.75 0",
                geo: { w: 4.8, h: 3.5 },
              },
            ];

            let A4 = [
              {
                id: `10${i}13_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[0]
                  ? this.jsonData.booths[i].exhibits[0]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "sl",
                pos: "1.01 3.15 -2.39",
                rot: "0 -24.75 0",
                imageScale: "3.48 2.42 1",
                imagePos: "1.03 3.2 -2.375",
                videoScale: "0.22 0.27 0.2",
                geo: { w: 4, h: 3.16 },
              },
              {
                id: `10${i}14_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[1]
                  ? this.jsonData.booths[i].exhibits[1]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "sl",
                pos: "3.6 3.15 0.92",
                rot: "0 -90 0",
                imageScale: "2.88 2.405 1.0",
                imagePos: "3.59 3.2 0.95",
                videoScale: "0.22 0.27 0.2",
                geo: { w: 3.3, h: 3.15 },
              },
              {
                id: `10${i}15_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[2]
                  ? this.jsonData.booths[i].exhibits[2]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "sl",
                pos: "3.6 3.15 4",
                rot: "0 -90 0",
                imageScale: "2.88 2.405 1.0",
                imagePos: "3.59 3.2 4.023",
                videoScale: "0.22 0.27 0.2",
                geo: { w: 3.3, h: 3.15 },
              },
              {
                id: `10${i}16_${i}`,
                cullBooth: false,
                data: this.jsonData.booths[i].exhibits[3]
                  ? this.jsonData.booths[i].exhibits[3]
                  : "",
                boothInfo: this.jsonData.booths[i],
                size: "sl",
                pos: "3.6 3.15 7.08",
                rot: "0 -90 0",
                imageScale: "2.88 2.405 1.0",
                imagePos: "3.59 3.2 7.1",
                videoScale: "0.22 0.27 0.2",
                geo: { w: 3.3, h: 3.15 },
              },
            ];
            if (this.jsonData.booths[i]) {
              if (this.jsonData.booths[i].style.type === "booth-a-8") {
                boothType = A8;
              } else if (this.jsonData.booths[i].style.type === "booth-a-2") {
                boothType = A2;
              } else if (this.jsonData.booths[i].style.type === "booth-a-4") {
                boothType = A4;
              } else if (this.jsonData.booths[i].style.type === "booth-a-1") {
                boothType = A1;
              }
              stripColor = this.jsonData.booths[i].style.color;
              boothData = this.jsonData.booths[i];
            }
          }

          iter++;
          if (iter === 4) {
            iter = 0;
            rotIter++;
            pos.z = 6;
            if (rotIter === 1) {
              pos.z = -116;
              rot = "0 0 0";
              pos.x -= 8;
              pos.z += 6;
            }
            if (rotIter === 2) {
              rot = "0 180 0";
              rotIter = 0;
              pos.x -= 30;
              // pos.z -= 6;
            }
          }
          if (rotIter === 1) pos.z += 22;
          else pos.z -= 22;

          this.telePositions.push({
            rotation: rot,
            position: { x: pos.x, y: pos.y, z: pos.z },
          });

          booths.push({
            id: i,
            type: "a",
            gradientColor: Math.random() > 0.5 ? "red" : "blue",
            itemAmount: 8,
            farFromCam: false,
            boothsLoadedAmount: 0,
            wallItems: boothType ? boothType : "",
            position: `${pos.x} ${pos.y} ${pos.z}`,
            rotation: rot,
            stripColor: stripColor ? stripColor : "",
            boothData: boothData ? boothData : "",
          });
        }
        return booths;
      }
    },
    objectAmountForLoad: function () {
      return document.querySelectorAll(".loadCount").length;
    },
    msgIcon: function () {
      return "https://cdn.glitch.com/3b2d1cee-c633-4b05-bbf2-07b88fcbad84%2Fchat-black-18dp.svg?v=14.885500516280";
    },
    picFrame() {
      return require("../assets/booths/pic-frame-sm.png");
    },
    pic() {
      return require("../assets/booths/tv.jpg");
    }
  },
};

import "aframe";
import "three";
import "aframe-extras";
import "networked-aframe";
import "aframe-rounded";
import "aframe-event-set-component";
import "aframe-look-at-component";
import "aframe-lerp-component";
import "../compos-aframe/physics.js";
import "../compos-aframe/auto-letter-direction.js";
import "../compos-aframe/canvas-pic-shadow.component.js";
import "../compos-aframe/custom-look-controls.component.js";
import "../compos-aframe/model-opacity.component.js";
import "../compos-aframe/booth-custom-component.js";
import "../compos-aframe/extended-wasd-controls.js";

import io from "socket.io-client";
import "aframe-aabb-collider-component";

import Dialog from "@/components/Dialog";
import SubDialog from "@/components/SubDialog";
import Social from "@/components/Social";
import LoadingAnim from "@/components/LoadingAnim";
import PopupAlert from "@/components/PopupAlert";
import BoothA from "@/components/booths/booth_a/BoothA";
import Chat from "@/components/Chat";
import PinName from "@/components/PinName";
import Colliders from "@/components/Colliders";
import OnScreen from "@/components/OnScreen";
import RegisterationScene from "@/components/RegisterationScene";

import axios from "axios";
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Assistant&display=swap");

.debug-text {
  position: absolute;
  top: 0;
  right: 0;
  // width: 300px;
  border-radius: 4px;
  padding: 5px;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.863);

  button {
    border: black 1px solid;
  }
}

.a-loader-title {
  color: #adc3e6;
}

.memoryCheck {
  position: absolute;
  width: 150px;
  height: 75px;
  top: 100px;
  right: 30px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  background: aliceblue;
  border-radius: 4px;
  padding: 20px;
}

.over-layer {
  z-index: 50;
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
